import React, { useEffect, useState } from "react";
import { CBreadcrumb, CBreadcrumbItem, CButton, CCol, CForm, CFormInput, CFormLabel, CRow } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { CustomMenuDropdown } from "./components/CustomMenuDropdown";
import SaleOrders from "services/SaleOrders";

const addOrderFields = {
  order_number: "order_number",
  order_value: "order_value",
};

const AddOrdersScreen = () => {
  const { t } = useTranslation();
  const [dataCustomer, setDataCustomer] = useState([]);
  const [dataEmployee, setDataEmployee] = useState([]);
  const [selectCustomer, setSelectCustomer] = useState(null);
  const [selectEmployee, setSelectEmployee] = useState(null);
  const [searchTextCustomers, setSearchTextCustomers] = useState("");
  const [searchTextEmployee, setSearchTextEmployee] = useState("");
  const [validation, setValidation] = useState<any>([]);
  const vendor_id = localStorage.getItem("vendor_id");
  const navigate = useNavigate();
  const [fieldOrders, setFieldOrders] = useState({
    order_number: "",
    order_value: "",
  });
  const onChangeByField = React.useCallback(
    (field: string, value: any) => {
      setFieldOrders((prevState) => {
        return {
          ...prevState,
          [field]: value,
        };
      });
    },
    [setFieldOrders]
  );
  const getListEmployee = async () => {
    await SaleOrders.getListEmployeeOrders(vendor_id).then((res) => {
      if (!res.data.code) {
        setDataEmployee(res.data.data);
      } else {
        setDataEmployee([]);
      }
    });
  };

  const getListCustomers = async () => {
    await SaleOrders.getListCustomerOrders(vendor_id, searchTextCustomers).then((res) => {
      if (!res.data.code) {
        setDataCustomer(res.data.data);
      } else {
        setDataCustomer([]);
      }
    });
  };

  useEffect(() => {
    getListEmployee();
  }, []);

  useEffect(() => {
    getListCustomers();
  }, [searchTextCustomers]);

  function formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const backScreenCustomer = () => {
    navigate("/sale_orders");
  };

  const onCreateOrders = (e) => {
    // e.preventDefault();
    SaleOrders.addOrders({
      vendor_id,
      order_number: fieldOrders.order_number,
      order_value: fieldOrders.order_value.split(".").join(""),
      employee_id: selectEmployee,
      customer_id: selectCustomer,
    }).then((res) => {
      if (!res.data.code) {
        setFieldOrders({ order_number: "", order_value: "" });
        setSelectEmployee(null);
        setSelectCustomer(null);
        setValidation([]);
        backScreenCustomer();
      } else {
        setValidation(res.data.messageObject);
      }
    });
  };

  return (
    <div>
      <div style={{ borderBottom: "1px solid #DBE1E4" }}>
        <CBreadcrumb>
          <CBreadcrumbItem>
            <Link className="breadcrumb-link text-secondary" to="/sale_orders">
              {t("saleOrders.sale_orders")}
            </Link>
          </CBreadcrumbItem>
          <CBreadcrumbItem className="text-primary" active>
            {t("saleOrders.add_order")}
          </CBreadcrumbItem>
        </CBreadcrumb>
        <h2>{t("saleOrders.add_order")}</h2>
      </div>
      <div style={{ borderBottom: "1px solid #DBE1E4" }}>
        <CRow className="py-3">
          <CCol sm={12} md={12} xl={3}>
            <h6>{t("saleOrders.order_information")}</h6>
          </CCol>
          <CCol sm={12} md={12} xl={9}>
            <CForm onSubmit={onCreateOrders}>
              <CCol sm={12} md={12} xl={4}>
                <CFormLabel>
                  {t("saleOrders.order_number")}
                  <span className="text-danger"> *</span>
                </CFormLabel>
                <CFormInput
                  name={addOrderFields.order_number}
                  value={fieldOrders?.order_number || ""}
                  onChange={(e) => onChangeByField(e.target.name, e.target.value)}
                  style={{ width: 254 }}
                />
                <span style={{ color: "red" }}>{validation?.order_number?.length ? validation?.order_number[0] : null}</span>
              </CCol>
              <br />
              <CCol sm={12} md={12} xl={4}>
                <CFormLabel>
                  {t("saleOrders.order_value")}
                  <span className="text-danger"> *</span>
                </CFormLabel>
                <CFormInput
                  name={addOrderFields.order_value}
                  value={formatNumber(fieldOrders.order_value) || ""}
                  onChange={(e) => onChangeByField(e.target.name, e.target.value)}
                  style={{ width: 254 }}
                  placeholder="00.000đ"
                />
                <span style={{ color: "red" }}>{validation?.order_value?.length ? validation?.order_value[0] : null}</span>
              </CCol>
              <br />
              <CCol sm={12} md={12} xl={4}>
                <CFormLabel>
                  {t("saleOrders.select_customer")}
                  <span className="text-danger"> *</span>
                </CFormLabel>
                <CustomMenuDropdown
                  width={255}
                  title="saleOrders.customer_name"
                  placeholder="saleOrders.enter_customer_name"
                  data={dataCustomer}
                  select={selectCustomer}
                  setSelect={setSelectCustomer}
                  setSearchText={setSearchTextCustomers}
                  searchText={searchTextCustomers}
                />
                <span style={{ color: "red" }}>{validation?.customer_id?.length ? validation?.customer_id[0] : null}</span>
              </CCol>
              <br />
              <CCol sm={12} md={12} xl={4}>
                <CFormLabel>{t("saleOrders.select_employee")}</CFormLabel>
                <CustomMenuDropdown
                  width={255}
                  title="saleOrders.employee_name"
                  placeholder="saleOrders.enter_employee_name"
                  data={dataEmployee}
                  select={selectEmployee}
                  setSelect={setSelectEmployee}
                  setSearchText={setSearchTextEmployee}
                  searchText={searchTextEmployee}
                />
              </CCol>
            </CForm>
          </CCol>
        </CRow>
      </div>
      <span style={{ color: "red" }}>{validation?.error_message}</span>
      <div className="my-5">
        <CButton onClick={onCreateOrders} color="primary">
          {t("common.save")}
        </CButton>
        <CButton onClick={backScreenCustomer} color="transparent border ms-3">
          {t("common.cancel")}
        </CButton>
      </div>
    </div>
  );
};

export default AddOrdersScreen;
