/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import { CButton, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CFormCheck, CRow, CCol } from "@coreui/react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../app/hooks";
import { uploadFileEmployee, selectDataDownloadFileEmployee } from "../../../../../features/business/businessSlices";
import { useTranslation } from "react-i18next";
interface ModalUpdateCustomer {
  visible: boolean;
  onCancel: () => void;
  downloadFile: () => void;
}

const ModalUploadEmployees = (props: ModalUpdateCustomer) => {
  const { visible, onCancel, downloadFile } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dataUploadFile = useAppSelector(selectDataDownloadFileEmployee);
  const [headings, setHeadings] = React.useState(true);
  const [errors, setErrors] = React.useState({ error_message: [""], file: [""] });
  const [fileUpLoad, setFileUpLoad] = React.useState<{ name: string } | any>({});
  const onSelectFile = (e: any) => {
    setFileUpLoad(e.target.files[0]);
  };
  const updateFile = async () => {
    const vendor_id = localStorage.getItem("vendor_id");
    const user_id = localStorage.getItem("user_id");

    const formData = new FormData();
    //@ts-ignore
    formData.append("file", fileUpLoad);
    //@ts-ignore
    formData.append("vendor_id", vendor_id);
    //@ts-ignore
    formData.append("user_id", user_id);
    //@ts-ignore
    formData.append("headings", headings ? "1" : "0");
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const res = await dispatch(uploadFileEmployee(formData));
    //@ts-ignore
    if (!res.payload.code) {
      setFileUpLoad({});
      setErrors({ error_message: [""], file: [""] });
    } else {
      setErrors(res.payload.messageObject);
    }
  };

  // const downloadFile = async () =>{
  //     const response =  await Business.downloadFile(headings?"1":"0");
  //     const link = document.createElement('a');
  //     link.href = response.data.data.file_path;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     //@ts-ignore
  //     //    dispatch(downloadFileCustomer(headings?"1":"0"));

  // };
  return (
    <CModal
      className="show d-block position-absolute align-items-center pt-5"
      backdrop={true}
      keyboard={false}
      portal={false}
      visible={visible}
      onClose={() => {
        setErrors({ error_message: [""], file: [""] });
        onCancel();
      }}
    >
      <CModalHeader>
        <CModalTitle>{t("employee.action.upload_employee_list")}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div className="d-flex align-items-center">
          <div>{t("common.choose_file")} (.xls):</div>
          <label htmlFor="input" className="d-flex align-items-center">
            <input className="ms-2 d-none" type="file" id="input" onChange={(e) => onSelectFile(e)} />
            <div
              style={{
                padding: "8px 14px",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                background: "#fff",
                border: "1px solid var(--gray-300, #AEBBC1)",
                margin: "0 20px",
                borderRadius: 8,
              }}
            >
              {t("common.choose_file")}
            </div>
            <div className="font-medium text-sm">{fileUpLoad?.name ? fileUpLoad?.name : t("common.no_file_is_selected")}</div>
          </label>
        </div>
        {/* <CFormCheck
          onChange={(e) => {
            e.stopPropagation();
            setHeadings(!headings);
          }}
          checked={headings}
          id="flexCheckDefault"
          label={t("common.first_row_contains")}
        /> */}
        <p style={{ color: "red" }}>{errors?.file ? errors?.file[0] : ""}</p>

        <a onClick={downloadFile} download className="my-3" style={{ cursor: "pointer", color: "#059ED7", fontWeight: 500 }}>
          {t("common.download_sample_file")}
        </a>
        <p style={{ color: "red" }}>{errors?.error_message?.length ? errors?.error_message[0] : null}</p>
      </CModalBody>
      <CModalFooter>
        <CRow className="w-100">
          <CCol>
            <CButton
              onClick={() => {
                onCancel();
                setFileUpLoad({});
                setErrors({ error_message: [""], file: [""] });
              }}
              className="w-100 border"
              color="transparent"
            >
              {t("common.cancel")}
            </CButton>
          </CCol>
          <CCol>
            <CButton onClick={updateFile} className="w-100" color="primary">
              {t("common.upload")}
            </CButton>
          </CCol>
        </CRow>
      </CModalFooter>
    </CModal>
  );
};

export default ModalUploadEmployees;
