import React from "react";
import { CButton, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow } from "@coreui/react";
import { useTranslation } from "react-i18next";

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  action?: () => void;
  errors?: any;
}

const ModalRequestPayment = ({ errors, visible, setVisible, action }: Props) => {
  const { t } = useTranslation();
  return (
    <CModal
      visible={visible}
      onClose={() => {
        setVisible(false);
      }}
    >
      <CModalHeader>
        <CModalTitle>{t("payment.request_payment")}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <p style={{ color: "#6B818C", fontSize: 14 }}>{t("payment.are_you_sure_you_want_to_request_this_payment")}</p>
        {errors}
      </CModalBody>
      <CModalFooter>
        <CRow className="w-100">
          <CCol>
            <CButton className="w-100 border" onClick={() => setVisible(false)} color="transparent">
              {t("common.cancel")}
            </CButton>
          </CCol>
          <CCol>
            <CButton className="w-100" color="primary" onClick={action}>
              {t("common.confirm")}
            </CButton>
          </CCol>
        </CRow>
      </CModalFooter>
    </CModal>
  );
};

export default ModalRequestPayment;
