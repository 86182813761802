import { CTableHeaderCell, CTableRow } from "@coreui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const approve_col = {
  employee_id: {
    label: "employee.employee_id",
    isShow: true,
  },
  name: {
    label: "employee.table.name",
    isShow: true,
  },
  request_date: {
    label: "employee.table.request_date",
    isShow: true,
  },
  approve_status: {
    label: "",
    isShow: true,
  },
};

const TableApproveHeader = () => {
  const { t } = useTranslation();
  const [col] = React.useState(approve_col);

  return (
    <CTableRow className="py-4">
      <CTableHeaderCell scope="col"></CTableHeaderCell>
      <CTableHeaderCell scope="col" hidden={!col?.employee_id.isShow}>
        {t(col?.employee_id.label)}
      </CTableHeaderCell>
      <CTableHeaderCell scope="col" hidden={!col?.name.isShow}>
        {t(col?.name.label)}
      </CTableHeaderCell>
      <CTableHeaderCell scope="col" hidden={!col?.request_date.isShow}>
        {t(col?.request_date.label)}
      </CTableHeaderCell>
      <CTableHeaderCell scope="col" hidden={!col?.approve_status.isShow}>
        {t(col?.approve_status.label)}
      </CTableHeaderCell>
      <CTableHeaderCell scope="col"></CTableHeaderCell>
    </CTableRow>
  );
};

export default TableApproveHeader;
