import React from "react";
import { CTableRow, CTableDataCell, CFormCheck } from "@coreui/react";
import OrdersObj from "./types/IOrders";
interface props {
  orders: OrdersObj;
  checked: any[];
  setChecked: React.Dispatch<React.SetStateAction<any[]>>;
}
const TableRow = (props: props) => {
  const { orders, checked, setChecked } = props;

  const handleChecked = (id: string) => {
    setChecked((prev) => {
      const isChecked = checked.includes(id);
      if (isChecked) {
        return checked.filter((item) => item !== id);
      }
      return [...prev, id];
    });
  };

  return (
    <>
      <CTableRow style={{ backgroundColor: checked.includes(orders.orders.id.toString()) ? "#FFF6F2" : "transparent", color: "#212223" }}>
        <CTableDataCell>
          <CFormCheck
            type="checkbox"
            checked={checked.toString().includes(orders.orders.id.toString())}
            onChange={() => handleChecked(orders.orders.id.toString())}
          />
        </CTableDataCell>
        <CTableDataCell>{orders.getNumberPO()}</CTableDataCell>
        <CTableDataCell>{orders.getQuantityPO()}</CTableDataCell>
        <CTableDataCell>{orders.getOrderValue()}</CTableDataCell>
        <CTableDataCell>{orders.getAssignEmployee()}</CTableDataCell>
        <CTableDataCell>{orders.getOrderDate()}</CTableDataCell>
      </CTableRow>
    </>
  );
};

export default TableRow;
