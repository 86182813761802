import React, { useState } from "react";
import { CModal, CModalBody, CModalHeader, CModalTitle, CModalFooter, CRow, CCol, CButton } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { CustomMenuDropdown } from "../components/CustomMenuDropdown";
import { IUserEmployee } from "../SalesOrdersScreen";

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  action?: () => void;
  children?: React.ReactNode
}

const ModalAssignEmployee = ({ visible, setVisible, action, children}: Props) => {
  const { t } = useTranslation();
  return (
    <CModal
      visible={visible}
      onClose={() => {
        setVisible(false);
      }}
    >
      <CModalHeader>
        <CModalTitle>{t("saleOrders.assign_employee")}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <p style={{ color: "#3A4348", fontSize: 14, fontWeight: "500" }}>{t("saleOrders.select_employee")}</p>
        {children}
      </CModalBody>
      <CModalFooter>
        <CRow className="w-100">
          <CCol>
            <CButton className="w-100 border" onClick={() => setVisible(false)} color="transparent">
              {t("common.cancel")}
            </CButton>
          </CCol>
          <CCol>
            <CButton className="w-100" color="primary" onClick={action}>
              {t("common.save")}
            </CButton>
          </CCol>
        </CRow>
      </CModalFooter>
    </CModal>
  );
};

export default ModalAssignEmployee;
