import React, { useEffect, useState } from "react";
import { CRow, CCol, CContainer, CButton, CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CFormCheck } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import SearchBar from "./searchBarPayment";
import TableRow from "./TableRow";
import NoDataTable from "components/NoDataTable";
import Pagination from "components/Pagination/Pagination";
import DownloadReportModal from "./DownloadReportModal";
import PaymentService from "services/PaymentService";
import PaymentObj from "./types/IPayment";
import socket from "utils/socket";
import ModalAssignEmployee from "screens/SalesOrders/Modals/ModalAssignEmployee";
import { IUserEmployee } from "screens/SalesOrders/SalesOrdersScreen";
import SaleOrdersService from "services/SaleOrders";
import ModalRequestPayment from "./modals/ModalRequestPayment";
import { CustomMenuDropdown } from "screens/SalesOrders/components/CustomMenuDropdown";

const employeeCol = {
  order_source: {
    label: "payment.order_source",
    isShow: true,
  },
  number: {
    label: "payment.number",
    isShow: true,
  },
  name: {
    label: "payment.customer_name",
    isShow: true,
  },
  employee: {
    label: "payment.assigned_employee",
    isShow: true,
  },
  payment_request: {
    label: "payment.payment_request",
    isShow: true,
  },
  amount_request: {
    label: "payment.amount_request",
    isShow: true,
  },
  amount_receive: {
    label: "payment.amount_receive",
    isShow: true,
  },
  credit_line: {
    label: "payment.credit_line",
    isShow: true,
  },
  bank_payment: {
    label: "payment.bank_payment",
    isShow: true,
  },
  cash_payment: {
    label: "payment.cash_payment",
    isShow: true,
  },
  last_sent_request: {
    label: "payment.last_sent_request",
    isShow: true,
  },
  last_paid_date: {
    label: "payment.last_paid_date",
    isShow: true,
  },
  status: {
    label: "payment.status",
    isShow: true,
  },
};

const PaymentConsolidationScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const vendor_id = localStorage.getItem("vendor_id");
  const user_id = localStorage.getItem("user_id");
  const [searchParams] = useSearchParams();
  const [searchData, setSearchData] = React.useState({ keyword: "" });
  const [col] = React.useState(employeeCol);
  const [isShowModal, setIsShowModal] = React.useState(false);
  const [selectId, setSelectId] = useState(null);
  const [employee, setEmployee] = useState<IUserEmployee[]>([]);
  const [isShowModalAssign, setIsShowModalAssign] = React.useState(false);
  const [checked, setChecked] = React.useState<string[]>([]);
  const [isCheckedAll, setIsCheckedAll] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [searchText, setSeachText] = useState("");
  const [errors, setErrors] = React.useState<any>();
  const [payments, setPayments] = React.useState<any>();
  const [isShowModalReq, setIsShowModalReq] = React.useState(false);
  const tranferPageto = (page: string, limit: string) => {
    navigate({
      pathname: location.pathname,
      search: `?keyword=${searchParams.get("keyword") || ""}&page=${page}&limit=${limit}`,
    });
  };

  const listPayment = async () => {
    vendor_id &&
      PaymentService.getListPayment(searchData.keyword, vendor_id, searchParams.get("page") || "")
        .then((res) => {
          setData(res.data.data.data);
          setPayments(res.data.data);
        })
        .catch((err) => console.log(err));
  };

  useEffect(() => {
    listPayment();
  }, [searchData, searchParams]);

  React.useEffect(() => {
    socket.on("payment_console", (dataSocket: any) => {
      console.log("dataSocket", dataSocket);
      if (vendor_id == dataSocket?.vendor_id) {
        listPayment();
      }
    });
  }, []);
  const handleCheckedAll = async () => {
    if (!isCheckedAll) {
      const id = await data.filter((item) => item.status === 1).map((item) => item.id);
      setChecked(id);
    } else {
      setChecked([]);
    }
    setIsCheckedAll(!isCheckedAll);
  };
  const handleAssignEmployee = async () => {
    const data = {
      payment_request_ids: checked,
      employee_id: selectId,
    };
    PaymentService.assignEmployee(data).then((res) => {
      if (!res.data.code) {
        listPayment();
        setIsShowModalAssign(false);
        setChecked([]);
        setErrors(null);
        setSelectId(null);
        setIsCheckedAll(false);
      } else {
        setErrors(res.data.messageObject.employee_id ? <div className="text-danger">{res.data.messageObject.employee_id}</div> : null);
      }
    });
  };
  useEffect(() => {
    isShowModalAssign &&
      SaleOrdersService.getListEmployeeOrders(vendor_id).then((res) => {
        if (!res.data.code) {
          setEmployee(res.data.data);
        } else {
          setEmployee([]);
        }
      });
  }, [isShowModalAssign]);

  const actionRequestPayment = async () => {
    const data = {
      vendor_id: +vendor_id,
      user_id: +user_id,
      payment_request_ids: checked,
    };
    PaymentService.sendPaymentRequest(data).then((res) => {
      if (!res.data.code) {
        listPayment();
        setIsShowModalReq(false);
        setChecked([]);
        setErrors(null);
        setIsCheckedAll(false);
      } else {
        setErrors(res.data.messageObject.employee_id ? <div className="text-danger">{res.data.messageObject.employee_id}</div> : null);
      }
    });
  };

  return (
    <CContainer fluid className="mt-3 p-0" style={{ padding: "0 13px" }}>
      <DownloadReportModal visible={isShowModal} setVisible={setIsShowModal} />
      <ModalAssignEmployee action={handleAssignEmployee} visible={isShowModalAssign} setVisible={setIsShowModalAssign}>
        <CustomMenuDropdown
          title="saleOrders.employee_name"
          placeholder="saleOrders.enter_employee_name"
          data={employee}
          select={selectId}
          setSelect={setSelectId}
          searchText={searchText}
          setSearchText={setSeachText}
        />
        {errors}
      </ModalAssignEmployee>
      <ModalRequestPayment visible={isShowModalReq} setVisible={setIsShowModalReq} action={actionRequestPayment} />
      <CRow className="mb-4">
        <CCol className="mb-4">
          <h2>{t("payment.payment_consolidation")}</h2>
        </CCol>
        <CCol className="d-flex">
          <div className="ms-auto">
            <CButton onClick={() => setIsShowModal(true)} className="ms-2" variant="outline" color="secondary">
              {t("payment.download_report")}
            </CButton>
          </div>
        </CCol>
      </CRow>
      <div
        style={{
          background: "#F3F5F6",
          borderRadius: "8px",
          padding: 20,
          height: 110,
          marginBottom: 24,
        }}
      >
        <SearchBar searchData={searchData} setSearchData={setSearchData} />
      </div>
      <CRow className="mb-4">
        <CCol className="d-flex">
          <div className="my-auto">
            <CButton
              onClick={() => {
                setIsShowModalAssign(true);
              }}
              disabled={checked.length === 0 ? true : false}
              style={{ background: "#FEF2EC", color: "#C14E0B", border: "1px solid #FFF6F2" }}
              className="ms-2"
              variant="ghost"
              color="primary"
            >
              {t("payment.assign_employee")}
            </CButton>
          </div>
          <div className="my-auto">
            <CButton
              onClick={() => {
                setIsShowModalReq(true);
              }}
              disabled={checked.length === 0 ? true : false}
              style={{ background: "#FEF2EC", color: "#C14E0B", border: "1px solid #FFF6F2" }}
              className="ms-4"
              variant="ghost"
              color="secondary"
            >
              {t("payment.request_payment")}
            </CButton>
          </div>
        </CCol>
      </CRow>
      <div>
        <div className="table-wrapper" style={{ overflow: "auto" }}>
          <CTable responsive="xl" align="middle" style={{ width: 2000 }}>
            <CTableHead>
              <CTableRow className="py-4">
                <CTableHeaderCell scope="col">
                  <CFormCheck style={{ fontSize: 14 }} type="checkbox" checked={isCheckedAll} onChange={handleCheckedAll} />
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">{t(col.order_source.label)}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t(col.number.label)}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t(col.name.label)}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t(col.employee.label)}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t(col.payment_request.label)}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t(col.amount_request.label)}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t(col.amount_receive.label)}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t(col.credit_line.label)}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t(col.bank_payment.label)}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t(col.cash_payment.label)}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t(col.last_sent_request.label)}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t(col.last_paid_date.label)}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t(col.status.label)}</CTableHeaderCell>
                <CTableHeaderCell scope="col"></CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {!!data.length &&
                data.map((dt: any) => <TableRow key={dt.id} listPayment={listPayment} payment={new PaymentObj(dt)} checked={checked} setChecked={setChecked} />)}
            </CTableBody>
          </CTable>
          {!data?.length && <NoDataTable />}
          {!!data?.length && (
            <Pagination limit={payments?.per_page} totalItems={payments?.total} current_page={payments?.current_page} navigate={tranferPageto} />
          )}
        </div>
      </div>
    </CContainer>
  );
};

export default PaymentConsolidationScreen;
