import React from "react";
import { CNav, CNavLink, CNavItem } from "@coreui/react";
import { useTranslation } from "react-i18next";


const StatusBar = (props: any) => {
  const {t} = useTranslation();
  const { searchData, setSearchData, requestCount } = props

  return (
    <CNav variant="tabs">
      <CNavItem>
        <CNavLink onClick={() => setSearchData((prev: any) => ({ ...prev, listType: 'list' }))} active={'list' === searchData.listType}>
          {t('employee.active_employee')}
        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink onClick={() => setSearchData((prev: any) => ({ ...prev, listType: 'list-approve-request' }))} active={'list-approve-request' === searchData.listType} >
        {t('employee.employee_addition_request')} <span className="badge rounded-pill text-muted"
           style={{ background: `${'list-approve-request' === searchData.listType ? '#ebccbc' : 'rgba(225,225,225,0.7)'}`,
           lineHeight: '12px'
           }}> {requestCount}</span>
        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink onClick={() => setSearchData((prev: any) => ({ ...prev, listType: 'list-resigned' }))} active={'list-resigned' === searchData.listType}>
        {t('employee.resigned_employees')}
        </CNavLink>
      </CNavItem>
    </CNav >
  );
};

export default StatusBar;
