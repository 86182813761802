import { CCol, CFormInput, CFormLabel, CFormSelect, CRow } from '@coreui/react'
import React from 'react';
import { useTranslation } from 'react-i18next';
import EmployeesService from '../../../../../services/Employee/EmployeesService';

const FilterBar = (props: any) => {
    const { t } = useTranslation();
    const { setSearchData, searchData } = props
    const [locations, setLocations] = React.useState<any>();
    const [manager, setManager] = React.useState<any>();
    const handleSearch = (name: any, value: any) => {
        console.log(searchData);
        setSearchData &&
            setSearchData((prev: any) => ({ ...prev, [name]: value }))
    }

    const getLocation = () => {
        EmployeesService.getLocationList().then((res) => {
            setLocations(res.data.data)
        });
    }
    const getManager = () => {
        EmployeesService.getManagerList().then((res) => {
            setManager(res.data.data)
        });
    }

    React.useEffect(() => {
        getLocation()
        getManager();
    }, []);
    return (
        <>
            <CRow>
                <CCol sm={12} md={3}>
                    <CFormLabel className='filter-label' htmlFor='search-input'>{t("common.search")}</CFormLabel>
                    <CFormInput name='keyword' value={searchData.keyword} onChange={(e) => handleSearch(e.target.name, e.target.value)} className='filter-form-control' type='text' placeholder={t("employee.filter.seacrh_by_name/phone/email")} id='search-input' aria-describedby='search-help' />
                </CCol>
                <CCol sm={12} md={3}>
                    <CFormLabel className='filter-label' htmlFor='select-location'>{t("employee.filter.by_location")}</CFormLabel>
                    <CFormSelect className='filter-form-select' name='location_id' type='text' placeholder={t("employee.filter.by_location")}
                        value={searchData?.location_id || ''} onChange={(e)=> handleSearch(e.target.name, e.target.value)}
                        id='select-location' aria-describedby='filter location' >
                        <option value="">{t("employee.filter.all_location")}</option>
                        {
                            locations?.map((l:any) => <option key={l.vendor_location_id} value={l.vendor_location_id}>{l.vendor_location_name || ''}</option>)
                        }
                    </CFormSelect>
                </CCol>
                <CCol sm={12} md={3}>
                    <CFormLabel className='filter-label' htmlFor='select-manager'>{t("employee.filter.by_manager")}</CFormLabel>
                    <CFormSelect className='filter-form-select' type='text' placeholder={t("employee.filter.by_manager")}
                    name="manage_by" value={searchData.manage_by}
                    onChange={(e)=> handleSearch(e.target.name, e.target.value)}
                    id='select-manager' aria-describedby='filter manager' >
                        <option value="">{t("employee.filter.all_manager")}</option>
                        {
                            manager?.map((m:any) => <option key={m.user_id} value={m.user_id}>{m.employee_name || ''}</option>)
                        }
                    </CFormSelect>
                </CCol>
            </CRow>
        </>
    )
}

export default FilterBar