import {
  CBreadcrumb,
  CBreadcrumbItem,
  CCol,
  CContainer,
  CRow,
} from "@coreui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import EmployeesService from "services/Employee/EmployeesService";
import documentIcon from 'assets/images/Document.png'
import downloadIcon from 'assets/images/icon-download.png'
const ResignedDetailScreen = () => {
  const { t } = useTranslation();
  const [data, setData] = React.useState<any>();
  const { id: employee_id } = useParams();

  React.useEffect(() => {
    employee_id &&
      EmployeesService.getById(employee_id)
        .then((res) => {
          const resdata = res.data.data;
          console.log(resdata);

          setData((pre: any) => ({
            ...pre,
            mobile_number: resdata.mobile_phone,
            first_name: resdata.first_name,
            last_name: resdata.last_name,
            middle_name: resdata.middle_name,
            role: resdata.role,
            joining_date:
              resdata.join_date &&
              new Date(resdata.join_date).toLocaleDateString("en-US"),
            expiry_date:
              resdata.expiry_date &&
              new Date(resdata.expiry_date).toLocaleDateString("en-US"),
            date_of_issue:
              resdata.date_of_issue &&
              new Date(resdata.date_of_issue).toLocaleDateString("en-US"),
            DOB:
              resdata.date_of_birth &&
              new Date(resdata.date_of_birth).toLocaleDateString("en-US"),
            city: resdata.city,
            district: resdata.district,
            ward: resdata.ward,
            place_of_issue: resdata.place_of_issue,
            employee_NID: resdata.id_number,
            address_line: resdata.address,
            email_address: resdata.email,
            employee_id: resdata.employee_number,
            create_employee_cash_book: resdata.cashbook,
            id_number: resdata.id_number,

            resigned_date: resdata.resigned_date,
            release_request_date: resdata.release_request_date,
            vendor_location: resdata.vendor_location
          }));
        })
        .catch((err: any) => {
          console.log(err);
        });
  }, []);

  return (
    <CContainer className="" fluid>
      <CBreadcrumb>
        <CBreadcrumbItem>
          <Link
            className="breadcrumb-link text-secondary"
            to="/employee_management/employees"
          >
            {t("employee.employees")}
          </Link>
        </CBreadcrumbItem>
        <CBreadcrumbItem className="text-primary" active>
          {t("employee.resigned_detail_employee")}
        </CBreadcrumbItem>
      </CBreadcrumb>
      <h3>{t("employee.resigned_detail_employee")}</h3>
      <CRow>
        <CCol className="mb-2" sm={12}>
          <div className="fs-5" style={{ fontWeight: 600 }}>
            {t("employee.business_information")}
          </div>
          <CRow className="mb-2">
            <CCol className="fw-600" sm={12} md={3}>{t("employee.employee_id")}</CCol>
            <CCol>{data?.employee_id}</CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol className="fw-600" sm={12} md={3}>{t("employee.joining_date")}</CCol>
            <CCol>{data?.joining_date}</CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol className="fw-600" sm={12} md={3}>
              {t("employee.business_location_base")}
            </CCol>
            <CCol>{data?.vendor_location}</CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol className="fw-600" sm={12} md={3}>{t("employee.role")}</CCol>
            <CCol>{data?.role}</CCol>
          </CRow>
        </CCol>
      </CRow>
      <hr />
      <CRow>
        <CCol className="mb-2" sm={12}>
          <div className="fs-5" style={{ fontWeight: 600 }}>
            {t("employee.employee_personal_information")}
          </div>
          <CRow className="mb-2">
            <CCol className="fw-600" sm={12} md={3}>{t("employee.first_name")}</CCol>
            <CCol>{data?.first_name}</CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol className="fw-600" sm={12} md={3}>{t("employee.middle_name")}</CCol>
            <CCol>{data?.middle_name}</CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol className="fw-600" sm={12} md={3}>{t("employee.last_name")}</CCol>
            <CCol>{data?.last_name}</CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol className="fw-600" sm={12} md={3}>{t("employee.mobile_number")}</CCol>
            <CCol>{data?.mobile_number}</CCol>
          </CRow>

          <CRow className="mb-2">
            <CCol className="fw-600" sm={12} md={3}>{t("employee.email_address")}</CCol>
            <CCol>{data?.email_address}</CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol className="fw-600" sm={12} md={3}>{t("employee.city")}</CCol>
            <CCol>{data?.city}</CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol className="fw-600" sm={12} md={3}>{t("employee.district")}</CCol>
            <CCol>{data?.district}</CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol className="fw-600" sm={12} md={3}>{t("employee.ward")}</CCol>
            <CCol>{data?.ward}</CCol>
          </CRow>

          <CRow className="mb-2">
            <CCol className="fw-600" sm={12} md={3}>{t("employee.address_line")}</CCol>
            <CCol>{data?.address_line}</CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol className="fw-600" sm={12} md={3}>{t("employee.DOB")}</CCol>
            <CCol>{data?.DOB}</CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol className="fw-600" sm={12} md={3}>{t("employee.id_number")}</CCol>
            <CCol>{data?.id_number}</CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol className="fw-600" sm={12} md={3}>{t("employee.date_of_issue")}</CCol>
            <CCol>{data?.date_of_issue}</CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol className="fw-600" sm={12} md={3}>{t("employee.expiry_date")}</CCol>
            <CCol>{data?.expiry_date}</CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol className="fw-600" sm={12} md={3}>{t("employee.place_of_issue")}</CCol>
            <CCol>{data?.place_of_issue}</CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol className="fw-600" sm={12} md={3}>{t("employee.contact_person_name")}</CCol>
            <CCol>{}</CCol>
          </CRow>
          <CRow className="mb-2">
            <CCol className="fw-600" sm={12} md={3}>{t("employee.contact_person_mobile_number")}</CCol>
            <CCol>{}</CCol>
          </CRow>

        </CCol>
      </CRow>
      <hr />
      <CRow className="mb-2">
        <CCol className="fw-600" sm={12} md={3}>{t("employee.resignation_letter")}</CCol>
      </CRow>
      <CRow>
        <CCol sm={12} md={3}>
          <div className="d-flex align-items-center justify-content-between py-2">
            <span className="d-flex">
              <img className="float-left" src={documentIcon} height={50} alt="" />
              <span className="ps-2">
                <div className="pb-1">Resiged file.pdf</div>
                <div className="text-muted fw-bold">5MB</div>
              </span>
            </span>
            <span role={'button'}>
              <img src={downloadIcon} alt="" />
            </span>
          </div>
        </CCol>
        <CRow className="mb-2">
          <CCol className="fw-600" sm={12} md={3}>{t("employee.date_of_resignation")}</CCol>
          <CCol>{data?.resigned_date}</CCol>
        </CRow>
        <CRow className="mb-2">
          <CCol className="fw-600" sm={12} md={3}>{t("employee.requested_release_date")}</CCol>
          <CCol>{data?.release_request_date}</CCol>
        </CRow>
      </CRow>
      <hr />
      <Link
        className="btn btn-outline-secondary"
        to={`/employee_management/employees/edit/${employee_id}`}
      >
        Edit
      </Link>
      <div style={{ height: 200 }}></div>
    </CContainer>
  );
};

export default ResignedDetailScreen;
