import React from "react";
import { CTableRow, CTableDataCell, CFormCheck } from "@coreui/react";
import IconEdit from "../../assets/images/icon_edit.png";
import { useNavigate } from "react-router-dom";
import Customer from "./types/Customer";

interface propTypes {
    customer: Customer
}

const TableRow = (props: propTypes) => {
    const { customer } = props;
    const navigate = useNavigate();
    const detailCustomer = () => {
        navigate(`detail-customer?id=${customer.data.id}`);
    };
    const updateCustomer = () => {
        navigate(`update-customer?id=${customer.data.id}`);
    };

    return (
        <CTableRow>
            <CTableDataCell className="p-3"><CFormCheck type="checkbox" /></CTableDataCell>
            <CTableDataCell className="">{customer.data.id}</CTableDataCell>
            <CTableDataCell onClick={detailCustomer} className=""><span style={{ color: "#059ED7", cursor: "pointer" }} >{customer.getName()}</span><p>{customer.data.mobile_phone}</p></CTableDataCell>
            <CTableDataCell className="">{customer.getVendorType()}</CTableDataCell>
            <CTableDataCell className="">{customer.getVendorName()}</CTableDataCell>
            <CTableDataCell className=""><span style={{
                color: customer.data.status_text.font_color,
                backgroundColor: customer.data.status_text.bg_color, padding: "2px 9px",
                border: `1px solid ${customer.data.status_text.bg_color}`, borderRadius: 16
            }}>{customer.data.status_text.text}</span></CTableDataCell>
            <CTableDataCell onClick={updateCustomer} className=""><img style={{ cursor: "pointer" }} src={IconEdit} alt="" /></CTableDataCell>
        </CTableRow>
    );
};

export default TableRow;
