import React, { useEffect, useState } from 'react';
import { CButton, CForm, CFormCheck, CFormSwitch } from '@coreui/react';
import TextInput from 'components/Input/TextInput';
import IconEgePass from 'assets/images/icon-ege.png';
import IconPass from 'assets/images/icon-lock-pass.png';
import IconLineal from 'assets/images/icon-lineal.png';
import IconPer from 'assets/images/item-per.png';
import Footer from 'components/Footer/footer';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'app/hooks';
import { login, selectDataLogin } from "features/login/loginSlices";
import Authentication from 'services/Authentication';
import { useTranslation } from 'react-i18next';
import i18n from 'utils/i18next';
import { UserAgent } from "react-useragent";
import axios from 'axios';

const SignInScreen = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isShowPass, setIsShowPass] = React.useState(true);
    const navigate = useNavigate();
    const dataLogin = useAppSelector(selectDataLogin);
    const lang = localStorage.getItem('lang');
    const [showLang, setShowLang] = React.useState(lang === "true" ? true : lang === "false" ? false : true);
    const LoginEnum = {
        mobile_phone: 'mobile_phone',
        password: 'password',
    };
    const [data, setData] = React.useState({
        mobile_phone: '',
        password: '',
    });

    const acctionRightPassWord = React.useCallback(() => {
        setIsShowPass(!isShowPass);
    }, [isShowPass]);

    const onChangeByField = React.useCallback(
        (field: string, value: any) => {
            setData((prevState) => {
                return {
                    ...prevState,
                    [field]: value,
                };
            });
        },
        [setData]
    );
    

    const onForgotPassword = () => {

        navigate("forgot-password");
    };

    React.useEffect(() => {
        if (showLang === true) {
            i18n.changeLanguage('vi');
        } else {
            i18n.changeLanguage('en')
        }
        localStorage.setItem('lang', showLang.toString())
    }, [showLang])

    React.useEffect(() => {

        if (dataLogin?.user_id) {
            if (dataLogin?.is_verified === false) {
                navigate(`/sign-in/verification?mobile_phone=${dataLogin?.mobile_phone}`);
            } else {
                navigate("/profile-list", { replace: true });
            }
        }
        dataLogin?.user_id && localStorage.setItem('user_id', dataLogin?.user_id);
        dataLogin?.access_token && localStorage.setItem('token', dataLogin?.access_token);
        dataLogin?.mobile_phone && localStorage.setItem('mobile_phone', dataLogin?.mobile_phone);
        dataLogin && localStorage.setItem('user', JSON.stringify(dataLogin));
    }, [dataLogin]);
    const sendOTP = async () => {
        if (dataLogin?.mobile_phone) {
            const dataResendOTP = { mobile_phone: dataLogin?.mobile_phone, type: "register" };
            await Authentication.reSendUserOTP(dataResendOTP);
        }
    };
    React.useEffect(() => {
        if (dataLogin?.is_verified === false) {
            sendOTP();
        }
    }, [dataLogin]);

    const onSignIn = async (e: any) => {
        e.preventDefault();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch(login(data));
    };

    return (
        <div style={{
            height: "100%",
            display: "flex",
            flexDirection: "column"
        }}>
            <div className="d-flex pt-5" style={{ marginLeft: 44 }}>
                <img src={require('../../assets/images/logo.png')} width={40} height={40}/>
                <h6 className="my-1" style={{ marginLeft: 5 }}>MyBiz Business Portal</h6>
            </div>
            <div>
                {/* <h2>{t('register.user_registration')}</h2> */}
                <h2 className="text-center">{t('registration.sign_in')}</h2>
                <CForm onSubmit={onSignIn} className="mx-auto" style={{ maxWidth: 360, marginTop: 32 }}>

                    <TextInput name={LoginEnum.mobile_phone} actionChangeText={onChangeByField} type="text" placeholder={t('common.enter_your_phone')} iconLeft={IconPer} />
                    <span style={{ color: "red" }}>{dataLogin?.mobile_phone && dataLogin?.mobile_phone[0]}</span>
                    <TextInput name={LoginEnum.password} actionChangeText={onChangeByField} type={isShowPass ? ("password") : ("text")} placeholder={t('common.password')} acctionRight={acctionRightPassWord} iconLeft={IconPass} iconRight={isShowPass ? (IconEgePass) : (IconLineal)} />
                    <span style={{ color: "red" }}>{dataLogin?.password && dataLogin?.password[0]}</span>

                    <div className="d-flex" style={{ justifyContent: "space-between" }}>
                        <CFormCheck type="checkbox" id='keep-me-sign-in' label={t('common.keep_me_sign_in')} />
                        <span onClick={onForgotPassword} className="ms-2" style={{ fontWeight: 500, color: "#C14E0B", cursor: "pointer" }}>{t('common.forgot_password')}</span>
                    </div>
                    <div className="d-grid gap-2 mt-3">
                        <CButton color="primary" type="submit">
                            {t('registration.sign_in')}
                        </CButton>
                    </div>
                    <p
                        onClick={() => navigate("/register")}
                        className="text-center"
                        style={{
                            marginTop: 24,
                            color: "#059ED7",
                            cursor: "pointer",
                            fontWeight: 500,
                        }}
                    >
                        {t('registration.register')}
                    </p>
                    <p
                        onClick={() => navigate("/login-qr")}
                        className="text-center"
                        style={{
                            marginTop: 24,
                            color: "#059ED7",
                            cursor: "pointer",
                            fontWeight: 500,
                        }}
                    >
                        {t("registration.login_with_qr_code")}
                    </p>
                </CForm>

            </div>
            <Footer />
            <div style={{ position: 'fixed', bottom: 3, left: 45 }}>
                <CFormSwitch label={showLang ? "VIE" : "EN"} id="formSwitchCheckChecked" defaultChecked={showLang} onChange={() => setShowLang(!showLang)} />
            </div>
        </div>
    );
};

export default SignInScreen;