import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import themeReducer from '../features/theme/themeSlide';
import businessReducer from '../features/business/businessSlices';
import registerReducer from '../features/register/registerSlices';
import loginReducer from '../features/login/loginSlices';
export const store = configureStore({
  reducer: {
    theme: themeReducer,
    business: businessReducer,
    register: registerReducer,
    login: loginReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
