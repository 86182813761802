import { CCol, CRow } from '@coreui/react';
import React from 'react'
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import update_icon from '../../../../../assets/images/upload_icon.png';

const InputDragFile = (props: any) => {
    const { t } = useTranslation();
    const { file, handleDataChange } = props;
    const onDrop = React.useCallback((acceptedFiles: any) => {
        console.log(acceptedFiles[0]);
        handleDataChange && handleDataChange("resign_file", acceptedFiles[0])
    }, [])
    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    return (
        <div className='input-drag-file py-3' {...getRootProps()}>
            <input {...getInputProps()} />
            {
                file ? <>
                    <CRow className='text-center'>
                        <div className="text-muted">
                            {file?.name}
                        </div>
                    </CRow>
                </>
                    : <>
                        <CRow>
                            <CCol className='text-center'>
                                <img className='rounded-pill ' src={update_icon} alt="" />
                            </CCol>

                        </CRow>
                        <CRow className='text-center'>
                            <div className='text-muted'>
                                <span className='text-primary'>{t('employee.click_to_attach')}</span>
                                <span> {t('employee.or_drag_and_drop')}</span>
                            </div>
                            <div className="text-muted">
                                TXT. CSV. EXCEL file (Max 5 MB)
                            </div>
                        </CRow></>
            }

        </div>
    )
}

export default InputDragFile