import moment from "moment";
import { convertUTCToLocalTime } from "utils/timezone";

export interface IPayment {
  id: number;
  vendor_id: number;
  supplier_id: number;
  customer_id: number;
  brand_principal_id: string | null;
  assigned_employee_id: number;
  order_id: number;
  order_amount: number;
  request_amount: number;
  receive_amount: null | number;
  payment_type: null | string;
  cash_out_id: number;
  bank_out_ubank_id: number;
  bank_out_credit_id: number;
  payment_date: null | string;
  status: number;
  description: string;
  last_request_at: string;
  last_paid_at: null | string;
  created_at: string;
  updated_at: string;
  customer: Customer;
  user_employee: {
    id: number;
    user_id: number;
    status: number;
    user: AssignedUser;
  };
  order: {
    id: number;
    order_number: string;
    total: number;
    platform: string;
  };
  cash_out_detail: {
    id: number;
    payment_amount: number;
  };
  ubank_detail: { id: number; payment_amount: number; status: number };
  credit_line_detail: { id: number; payment_amount: number; status: number };
}

export interface AssignedUser {
  id?: any;
  last_name?: any;
  first_name?: any;
  middle_name?: any;
}

export interface CashOutDetail {
  id?: any;
  payment_amount?: any;
  cash_out_id?: any;
  created_at?: any;
  credit_line_detail?: any;
}

export interface Customer {
  first_name?: any;
  id?: any;
  last_name?: any;
}

export interface Order {
  id?: any;
  order_number?: any;
  total?: any;
}

export interface UbankDetail {
  id?: any;
  payment_amount?: any;
  status?: 2;
}

export default class PaymentObj {
  payment: IPayment;
  dateFormat = "DD/MM/YYYY";
  dateTimeFormat = "HH:mm DD/MM/YYYY";
  SERVER_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";
  SERVER_TIME_ZONE = "GMT";
  constructor(data: any) {
    this.payment = { ...data };
  }
  formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  getOrderSource() {
    return this.payment.order.platform ? (this.payment.order.platform === "PORTAL" ? "DMS" : "MyBiz") : "-";
  }
  getPONumber() {
    return this.payment.order.order_number ? this.payment.order.order_number : "";
  }

  getCustomerName() {
    return this.payment.customer ? ` ${this.payment.customer.last_name} ${this.payment.customer.first_name}` : "-";
  }

  getAssignedEmployeeName() {
    return this.payment.user_employee
      ? `${this.payment.user_employee.user.last_name} ${
          this.payment.user_employee.user.middle_name ? this.payment.user_employee.user.middle_name : ""
        } ${this.payment.user_employee.user.first_name}`
      : "-";
  }
  getPaymenRequest() {
    return this.payment.created_at ? `${moment(this.payment.created_at).format(this.dateFormat)}` : "-";
  }
  getAmountRequest() {
    return this.payment.request_amount ? this.formatNumber(this.payment.request_amount.toString()) : "-";
  }

  getAmountReceived() {
    return this.payment.receive_amount ? this.formatNumber(this.payment.receive_amount.toString()) : "-";
  }
  getCreditLine() {
    return this.payment.credit_line_detail ? this.formatNumber(this.payment.credit_line_detail.payment_amount.toString()) : "-";
  }
  getBankPayment() {
    return this.payment.ubank_detail ? this.formatNumber(this.payment.ubank_detail.payment_amount.toString()) : "-";
  }

  getCashPayment() {
    return this.payment.cash_out_detail ? this.formatNumber(`${this.payment.cash_out_detail.payment_amount}`) : "-";
  }

  getLastSentRequest() {
    return this.payment.last_request_at ? `${convertUTCToLocalTime(this.payment.last_request_at)}` : "-";
  }
  getLastPaidDate() {
    return this.payment.last_paid_at ? `${convertUTCToLocalTime(this.payment.last_paid_at)}` : "-";
  }
  getStatus() {
    switch (this.payment.status) {
      case 1:
        return `unpaid`;
      case 2:
        return `pending`;
      case 3:
        return `paid`;
      default:
        return "-";
    }
  }
}
