import React from "react";
import { CButton, CForm, CFormCheck, CFormLabel, CFormSwitch } from "@coreui/react";
import TextInput from "components/Input/TextInput";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import IconEgePass from "assets/images/icon-ege.png";
import IconPhone from "assets/images/icon-phone .png";
import IconPass from "assets/images/icon-lock-pass.png";
import IconEmail from "assets/images/icon-email.png";
import IconPer from "assets/images/item-per.png";
import IconLineal from "assets/images/icon-lineal.png";
import Footer from "components/Footer/footer";
import { useDispatch } from "react-redux";
import { useAppSelector } from "app/hooks";
import { SelectValidate, registerStep, selectDataRegister } from 'features/register/registerSlices';
import { useNavigate } from "react-router-dom";
import i18n from 'utils/i18next';

const ScreenRegister = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isShowPass, setIsShowPass] = React.useState(true);
    const [isShowConfirmPassword, setIsShowConfirmPassword] =
        React.useState(true);
    const dataValidate = useAppSelector(SelectValidate);
    const dataRegister = useAppSelector(selectDataRegister);
    const acctionRightPassWord = React.useCallback(() => {
        setIsShowPass(!isShowPass);
    }, [isShowPass]);
    const acctionRightConfirmPassWord = React.useCallback(() => {
        setIsShowConfirmPassword(!isShowConfirmPassword);
    }, [isShowConfirmPassword]);
    const lang = localStorage.getItem('lang');
    const [showLang, setShowLang] = React.useState(lang === "true" ? true : lang === "false" ? false : true);
    const [checkTerm, setCheckTerm] = React.useState(false);
    const [validation, setValidation] = React.useState<
        | {
            mobile_phone_country: string[] | undefined;
            mobile_phone: string[] | undefined;
            password: string[] | undefined;
            password_confirm: string[] | undefined;
            email: string[] | undefined;
            referral_code: string[] | undefined;
            user_first_name: string[] | undefined;
            user_middle_name: string[] | undefined;
            user_last_name: string[] | undefined;
            is_agree: string[] | undefined;
        }
        | undefined
    >();

    const RegisterEnum = {
        mobile_phone: "mobile_phone",
        password: "password",
        password_confirm: "password_confirm",
        email: "email",
        referral_code: "referral_code",
        user_first_name: "user_first_name",
        user_middle_name: "user_middle_name",
        user_last_name: "user_last_name",
    };

    const [data, setData] = React.useState({
        mobile_phone_country: 'VN',
        mobile_phone: '',
        password: '',
        password_confirm: '',
        email: '',
        referral_code: '',
        user_first_name: '',
        user_middle_name: '',
        user_last_name: '',
    });
    React.useEffect(() => {
        setValidation(dataValidate);
    }, [dataValidate, dataRegister]);
    const actionChangePhoneCode = React.useCallback(
        (value: any, country: any) => {
            setData({ ...data, mobile_phone_country: country.countryCode.toUpperCase() });
        },
        [data],
    );

    const onChangeByField = React.useCallback(
        (field: string, value: any) => {
            setData((prevState) => {
                return {
                    ...prevState,
                    [field]: value,
                };
            });
        },
        [setData]
    );


    React.useEffect(() => {
        if (showLang === true) {
            i18n.changeLanguage('vi');
        } else {
            i18n.changeLanguage('en')
        }
        localStorage.setItem('lang', showLang.toString())
    }, [showLang])

    React.useEffect(() => {
        if (dataRegister?.mobile_phone) {
            navigate(`/register/verification?mobile_phone=${dataRegister?.mobile_phone}`);
            // sendOTP();
            const onwnerName = data?.user_first_name + ' ' + data?.user_middle_name + ' ' + data?.user_last_name;
            localStorage.setItem('onwnerName', onwnerName);
            localStorage.setItem('mobile_phone', dataRegister?.mobile_phone);

        }
    }, [dataRegister]);

    const onRegister = async (e: any) => {
        e.preventDefault();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        setValidation([]);
        const dataRegister = {
            mobile_phone_country: data.mobile_phone_country,
            mobile_phone: data.mobile_phone,
            password: data.password,
            password_confirm: data.password_confirm,
            email: data.email,
            referral_code: data.referral_code,
            user_first_name: data.user_first_name,
            user_middle_name: data.user_middle_name,
            user_last_name: data.user_last_name,
            is_agree: checkTerm,
            is_distributor: true
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch(registerStep(dataRegister));

    };
    // const onRegister = async (e: any) => {
    //   console.log("data", data);
    //   navigate("verification");
    //   try {
    //     const response = await Authentication.businesType();
    //     console.log(response.data);
    //   } catch (err) {
    //     console.log("err", err);
    //   }
    // };

    return (
        <div style={{
            height: "100%",
            display: "flex",
            flexDirection: "column"
        }}>
            <div className="d-flex pt-5" style={{ marginLeft: 44 }}>
                <img src={require("../../assets/images/logo.png")} width={40} height={40} />
                <h6 className="my-1" style={{ marginLeft: 5 }}>
                    MyBiz Business Portal
                </h6>
            </div>
            <div>
                {/* <h2>{t('register.user_registration')}</h2> */}
                <h2 className="text-center">{t('registration.user_registration')}</h2>

                <CForm
                    className="mx-auto"
                    style={{ maxWidth: 360, marginTop: 32 }}
                    onSubmit={onRegister}
                >
                    <div className="d-flex">
                        <PhoneInput
                            containerClass="me-2 my-2"
                            containerStyle={{
                                width: 109,
                                height: 44,
                                backgroundColor: "#F3F5F6",
                                borderRadius: 8,
                            }}
                            inputStyle={{
                                paddingRight: 10,
                                width: 100,
                                backgroundColor: "#F3F5F6",
                                height: 44,
                                borderRadius: 8,
                                cursor: "pointer",
                            }}
                            buttonStyle={{
                                height: 44,
                                borderRadius: " 8px 0px 0px 8px",
                                backgroundColor: "#F3F5F6",
                            }}
                            buttonClass="py-1 px-1"
                            country={data?.mobile_phone_country.toLowerCase()}
                            value={data?.mobile_phone_country}
                            onChange={actionChangePhoneCode}
                        />
                        <TextInput
                            value={data.mobile_phone}
                            className="border-0"
                            style={{ flex: 1 }}
                            type="text"
                            iconLeft={IconPhone}
                            name={RegisterEnum.mobile_phone}
                            placeholder={t('common.phone')}
                            actionChangeText={onChangeByField}
                        />

                    </div>
                    <span style={{ color: "red" }}>{validation?.mobile_phone?.length ? (validation?.mobile_phone[0]) : null}</span>
                    <TextInput
                        name={RegisterEnum.user_first_name}
                        value={data.user_first_name}
                        actionChangeText={onChangeByField}
                        type="name"
                        placeholder={`${t('common.first_name')}`}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        validation={validation?.user_first_name?.length ? (validation?.user_first_name[0]) : null}
                    />
                    <TextInput
                        name={RegisterEnum.user_middle_name}
                        value={data.user_middle_name}
                        actionChangeText={onChangeByField}
                        type="name"
                        placeholder={`${t('common.middle_name')} (${t('common.optional')})`}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        validation={validation?.user_middle_name?.length ? (validation?.user_middle_name[0]) : null}
                    />
                    <TextInput
                        name={RegisterEnum.user_last_name}
                        value={data.user_last_name}
                        actionChangeText={onChangeByField}
                        type="name"
                        placeholder={`${t('common.last_name')}`}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        validation={validation?.user_last_name?.length ? (validation?.user_last_name[0]) : null}
                    />
                    <TextInput
                        name={RegisterEnum.password}
                        value={data.password}
                        actionChangeText={onChangeByField}
                        type={isShowPass ? "password" : "text"}
                        placeholder={t('common.password')}
                        acctionRight={acctionRightPassWord}
                        iconLeft={IconPass}
                        iconRight={isShowPass ? IconEgePass : IconLineal}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        validation={validation?.password?.length ? (t('common.password_rule')) : null}
                    />
                    {validation?.password?.length ? null : <CFormLabel>{t('common.password_rule')}</CFormLabel>}
                    <TextInput
                        name={RegisterEnum.password_confirm}
                        value={data.password_confirm}
                        actionChangeText={onChangeByField}
                        type={isShowConfirmPassword ? "password" : "text"}
                        placeholder={t('common.confirm_password')}
                        acctionRight={acctionRightConfirmPassWord}
                        iconLeft={IconPass}
                        iconRight={isShowConfirmPassword ? IconEgePass : IconLineal}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        validation={validation?.password_confirm?.length ? (validation?.password_confirm[0]) : null}
                    />
                    <TextInput
                        name={RegisterEnum.email}
                        value={data.email}
                        actionChangeText={onChangeByField}
                        type="text"
                        placeholder={t('common.personal_email_optional')}
                        iconLeft={IconEmail}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        validation={validation?.email?.length ? (validation?.email[0]) : null}
                    />
                    <TextInput
                        name={RegisterEnum.referral_code}
                        value={data.referral_code}
                        actionChangeText={onChangeByField}
                        type="text"
                        placeholder={t('common.referral_optional')}
                        iconLeft={IconPer}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        validation={validation?.referral_code?.length ? (validation?.referral_code[0]) : null}
                    />
                    <div className="d-flex">
                        <CFormCheck defaultChecked={checkTerm} onChange={() => { setCheckTerm(!checkTerm); }} type="checkbox" id="agree_mybiz" label={t('registration.agree_mybiz')} />
                        <span className="ms-2" style={{ fontWeight: 500 }}>
                            <a href="" style={{ textDecoration: "none" }}>
                                {t('registration.tern_conditions')}
                            </a>
                        </span>
                    </div>
                    <span style={{ color: "red" }}>{validation?.is_agree?.length ? (validation?.is_agree[0]) : null}</span>

                    <div className="d-grid gap-2 mt-3">
                        <CButton color="primary" type="submit">
                            {t('registration.next')} (1/4)
                        </CButton>
                    </div>

                    <p
                        onClick={() => navigate("/sign-in")}
                        className="text-center"
                        style={{
                            marginTop: 24,
                            color: "#059ED7",
                            cursor: "pointer",
                            fontWeight: 500,
                        }}
                    >
                        {t('registration.sign_in')}
                    </p>
                </CForm>
            </div>
            <Footer />
            <div style={{ position: 'fixed', bottom: 3, left: 45 }}>
                <CFormSwitch label={showLang ? "VIE" : "EN"} id="formSwitchCheckChecked" defaultChecked={showLang} onChange={() => setShowLang(!showLang)} />
            </div>
        </div>
    );
};

export default ScreenRegister;
