/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { CButton, CForm, CFormCheck, CFormInput, CFormLabel, CBreadcrumb, CBreadcrumbItem, CRow, CCol } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { getBusinessProfileType, selectListProfileBusinessType, selectListBusinessType, getListType } from "../../features/business/businessSlices";
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'app/hooks';
import { useNavigate } from 'react-router-dom';
import Business from 'services/Business/Business';
import { Link } from 'react-router-dom';
const AddCustomerScreen = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const vendor_id = localStorage.getItem('vendor_id');
    const user_id = localStorage.getItem('user_id');
    const mobile_phone = localStorage.getItem('mobile_phone');
    const listBusinessProfileType = useAppSelector(selectListProfileBusinessType);
    const listBusinessTypeAdd = useAppSelector(selectListBusinessType);
    const [customerType, setCustomerType] = React.useState();
    const [businessType, setBusinessType] = React.useState([]);
    const [dataGetUser, setDataGetUser] = React.useState({
        id: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        mobile_phone: ""
    });
    const [validation, setValidation] = React.useState<
        | {
            mobile_phone: string[] | undefined;
            customer_type: string[] | undefined;
            vendor_name: string[] | undefined;
            email: string[] | undefined;
            referral_code: string[] | undefined;
            first_name: string[] | undefined;
            middle_name: string[] | undefined;
            last_name: string[] | undefined;
            error_message: "";
        }
        | undefined
    >();

    const navigate = useNavigate();
    const RegisterInfoCustomer = {
        mobile_phone: "mobile_phone",
        vendor_name: "vendor_name",
        first_name: "first_name",
        middle_name: "middle_name",
        last_name: "last_name",
    };

    const [infoCustomer, setInfoCustomer] = React.useState({
        mobile_phone: "",
        vendor_name: "",
        first_name: "",
        middle_name: "",
        last_name: "",
    });

    React.useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch(getBusinessProfileType());
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch(getListType());
    }, []);
    const onChangeCustomerType = (event: any) => {
        setCustomerType(event.target.value);
    };

    const onChangeBusinessType = (event: any) => {
        const number = parseInt(event.target.value);
        if (businessType.includes(number)) {
            setBusinessType((prev: any) => prev.filter(el => el !== number));
        }
        else {
            setBusinessType((prev: any) => [...prev, number]);
        }
    };

    const onChangeByField = React.useCallback(
        (field: string, value: any) => {
            setInfoCustomer((prevState) => {
                return {
                    ...prevState,
                    [field]: value,
                };
            });
        },
        [setInfoCustomer]
    );
    const backScreenCustomer = () => {
        navigate("/customers");
    };

    const getUserByPhone = async () => {
        const my_mobile_phone = mobile_phone;
        const mobile_phonee = infoCustomer?.mobile_phone;
        try {
            const response = await Business.getUserByPhone(mobile_phonee, my_mobile_phone);
            setDataGetUser(response.data.data);
            const data = response.data?.data;
            setInfoCustomer({
                mobile_phone: data?.mobile_phone || infoCustomer?.mobile_phone,
                vendor_name: infoCustomer?.vendor_name,
                first_name: data?.first_name || infoCustomer?.first_name,
                middle_name: data?.middle_name || infoCustomer?.middle_name,
                last_name: data?.last_name || infoCustomer?.last_name,
            })
            return response;
        } catch (err) {
            console.log(err);
        }
    };
    const onCreateCustomer = async (e: any) => {
        e.preventDefault();
        const data = {
            "vendor_id": vendor_id,
            "user_id": user_id,
            "customer_type": customerType,
            "my_mobile_phone": mobile_phone,
            "mobile_phone": infoCustomer?.mobile_phone,
            "vendor_name": infoCustomer?.vendor_name,
            "first_name": infoCustomer?.first_name,
            "middle_name": infoCustomer?.middle_name,
            "last_name": infoCustomer?.last_name,
            "business_type_id": businessType,
        };

        const response = await Business.customerAdd(data);
        if (response.data.code === 2) {
            setValidation(response.data.messageObject);
        }
        else if (response.data.code === 0) {
            navigate("/customers");
        }
        return response.data;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        // dispatch(addCustomer(data));
    };
    return (
        <div>

            <CBreadcrumb>
                <CBreadcrumbItem>
                    <Link className="breadcrumb-link text-secondary" to="/customers">
                        {t("customer.customers_title")}
                    </Link>
                </CBreadcrumbItem>
                <CBreadcrumbItem className="text-primary" active>
                    {t("customer.add_customer")}
                </CBreadcrumbItem>
            </CBreadcrumb>
            <h2>{t('customer.add_customer')}</h2>

            <CRow className="py-3" style={{ borderBottom: '1px solid #DBE1E4' }}>

                <CCol sm={12} md={12} xl={3}>
                    <h6>{t('customer.business_info')}</h6>
                </CCol>

                <CCol sm={12} md={12} xl={9}>
                    <CForm onSubmit={onCreateCustomer} >
                        <CCol sm={12} md={12} xl={4}>
                            <CFormLabel>{t("customer.select_type")}<span className="text-danger"> *</span></CFormLabel>
                            <CRow>
                                {listBusinessProfileType.map((item: any) => (
                                    <CCol sm={12} md={12} xl={6} key={item?.id}>

                                        <CFormCheck value={item?.id} checked={item?.id === parseInt(customerType || "")} onChange={onChangeCustomerType} type="radio" name="flexRadioDefault" color="primary" id={"flexRadioDefault" + item.id} />
                                        <CFormLabel className='mb-0 ms-1' htmlFor={"flexRadioDefault" + item.id}>{item?.name}</CFormLabel>


                                    </CCol>
                                ))}
                            </CRow>
                        </CCol>
                        <span style={{ color: "red" }}>{validation?.customer_type?.length ? (validation?.customer_type[0]) : null}</span>

                        <CCol className="my-2">
                            <CFormLabel>{t("customer.mobile_number_add_cus")}<span className="text-danger"> *</span></CFormLabel>
                            <CFormInput onBlur={getUserByPhone} name={RegisterInfoCustomer.mobile_phone} value={infoCustomer?.mobile_phone || ""} onChange={(e) => onChangeByField(e.target.name, e.target.value)} style={{ width: 254 }} placeholder={t('common.enter_your_phone')} />
                            <span style={{ color: "red" }}>{validation?.mobile_phone?.length ? (validation?.mobile_phone[0]) : null}</span>

                        </CCol>

                        <CCol className="my-2">
                            <CFormLabel>{t("customer.business_name")}<span className="text-danger"> *</span></CFormLabel>
                            <CFormInput name={RegisterInfoCustomer.vendor_name} value={infoCustomer?.vendor_name || ""} onChange={(e) => onChangeByField(e.target.name, e.target.value)} style={{ width: 254 }} placeholder={t('common.enter_business_name')} />
                            <span style={{ color: "red" }}>{validation?.vendor_name?.length ? (validation?.vendor_name[0]) : null}</span>

                        </CCol>

                        <CCol className="my-2">
                            <CRow>
                                <CCol sm={12} md={12} xl={4}>
                                    <CFormLabel>{t("customer.business_first_name")}<span className="text-danger"> *</span></CFormLabel>
                                    <CFormInput name={RegisterInfoCustomer.first_name} value={infoCustomer?.first_name || ""} disabled={dataGetUser?.first_name?.length ? true : false} onChange={(e) => onChangeByField(e.target.name, e.target.value)} style={{ width: 254 }} placeholder={t('common.first_name')} />
                                    <span style={{ color: "red" }}>{validation?.first_name?.length ? (validation?.first_name[0]) : null}</span>
                                </CCol>

                                <CCol sm={12} md={12} xl={4} >
                                    <CFormLabel>{t("customer.business_middle_name")}</CFormLabel>
                                    <CFormInput name={RegisterInfoCustomer.middle_name} value={infoCustomer?.middle_name || ""} disabled={dataGetUser?.middle_name?.length ? true : false} onChange={(e) => onChangeByField(e.target.name, e.target.value)} style={{ width: 254 }} placeholder={t('common.middle_name')} />
                                    <span style={{ color: "red" }}>{validation?.middle_name?.length ? (validation?.middle_name[0]) : null}</span>
                                </CCol>

                                <CCol sm={12} md={12} xl={4}>
                                    <CFormLabel>{t("customer.business_last_name")}<span className="text-danger"> *</span></CFormLabel>
                                    <CFormInput name={RegisterInfoCustomer.last_name} value={infoCustomer?.last_name} disabled={dataGetUser?.last_name?.length ? true : false} onChange={(e) => onChangeByField(e.target.name, e.target.value)} style={{ width: 254 }} placeholder={t('common.last_name')} />
                                    <span style={{ color: "red" }}>{validation?.last_name?.length ? (validation?.last_name[0]) : null}</span>
                                </CCol>
                            </CRow>
                        </CCol>
                        <div className="my-2">
                            <CFormLabel>{t("customer.business_type")}</CFormLabel>
                            <CRow>
                                {listBusinessTypeAdd.map((item: any) => (
                                    <CCol key={item.id} sm={12} md={12} xl={4}>

                                        <div className="d-flex">
                                            <CFormCheck checked={businessType.includes(
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                //@ts-ignore
                                                item.id
                                            )}  onChange={onChangeBusinessType} value={item.id} id={"checkbox" + item.id} />
                                            <CFormLabel className='mb-0 ms-1' htmlFor={"checkbox" + item.id}>{item.translation.business_type_name}</CFormLabel>
                                        </div>
                                    </CCol>

                                ))}
                            </CRow>
                        </div>
                        <div>
                        </div>
                    </CForm>
                </CCol>
            </CRow>
            <span style={{ color: "red" }}>{validation?.error_message}</span>
            <div className="my-5">
                <CButton onClick={onCreateCustomer} color="primary">{t('common.save')}</CButton>
                <CButton onClick={backScreenCustomer} color="transparent border ms-3">{t('common.cancel')}</CButton>
            </div>
        </div>
    );
};

export default AddCustomerScreen;
