import React from "react";
import { CTableRow, CTableDataCell, CFormCheck } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { formatYYYYMMDD } from "utils/function";
import { Link } from "react-router-dom";
import IconEdit from "assets/images/icon_edit.png";
import Employee from "../interfaces/EmployeeDto";
import { STATUS } from "../constant";

interface propTypes {
  handleCheckbox: (id: any) => void;
  checkedIDs: Array<any>;
  employee: Employee;
}

const ReignedTableRow = (props: propTypes) => {
  const { employee_number, mobile_phone, role_name, manager, cashbook, join_date, vendor_location, id } = props.employee.dto;
  const { t } = useTranslation();

  return (
    <CTableRow>
      <CTableDataCell className="p-3">
        <CFormCheck type="checkbox" checked={props.checkedIDs.includes(id)} onChange={() => props.handleCheckbox(id)} />
      </CTableDataCell>
      <CTableDataCell className="py-3">{employee_number}</CTableDataCell>
      <CTableDataCell className="py-3">
        <Link to={props.employee.getResignedDetailLink()}>{props.employee.getName()}</Link>
        <br />
        {mobile_phone}
      </CTableDataCell>
      <CTableDataCell className="py-3">{role_name}</CTableDataCell>
      <CTableDataCell className="py-3">{manager}</CTableDataCell>
      <CTableDataCell className="py-3">{t(cashbook ? "common.yes" : "common.no")}</CTableDataCell>
      <CTableDataCell className="py-3">{formatYYYYMMDD(String(join_date))}</CTableDataCell>
      <CTableDataCell className="py-3">{vendor_location}</CTableDataCell>
      <CTableDataCell className="py-3">
        <Link to={props.employee.getEditLink()} state={{ beforeTab: STATUS.RESIGN }}>
          <img style={{ cursor: "pointer" }} src={IconEdit} alt="" />
        </Link>
      </CTableDataCell>
    </CTableRow>
  );
};

export default ReignedTableRow;
