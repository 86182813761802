import React from 'react';

interface ButtonSelect{ 
    style?:any,
    name:string,
    icon?:string,
    backgroundColor?:string,
    onSelected?:any,
}

const ButtonSelect = (props:ButtonSelect) => {
    const {name,icon,backgroundColor,style,onSelected} = props;
    return (
        <div onClick={onSelected} className="d-flex align-items-center me-2 my-1" style={{backgroundColor:backgroundColor,border:"1px solid #E8EBED",borderRadius:20,cursor:"pointer",...style}}>
            <div className="mx-2">
            {icon?(<img style={{width:20,height:20}} src={icon} alt="" />):(null)}
            <span className="mx-2">{name}</span>
            </div>
        </div>
    );
};

export default ButtonSelect;