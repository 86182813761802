import React from "react";
import { CButton, CForm, CFormLabel } from "@coreui/react";
import TextInput from "components/Input/TextInput";
import ButtonSelect from "components/Buttons/ButtonSelect";
import IconCoin from "assets/images/icon-coins.png";
import IconCard from "assets/images/icon-card.png";
import Footer from "components/Footer/footer";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useAppSelector } from "app/hooks";
import { useNavigate } from "react-router-dom";
import { getListType, selectListBusinessType, createBusinessProfile, selectBusinessProfile } from "features/business/businessSlices";
import CommonService from "services/CommonService";

const BusinessProfileScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSelectedPayment, setIsSlectedPayment] = React.useState<any>({ values: [] });
  const [isSelectedType, setIsSlectedType] = React.useState<any>({ values: [] });
  const [listType, setListType] = React.useState<any>([]);
  const listBusinessType = useAppSelector(selectListBusinessType);
  const [resetListType, setResetListType] = React.useState<number>(1);
  const businessProfile = useAppSelector(selectBusinessProfile);
  const user = CommonService.user().user;
  const [dataRegister, setDataRegister] = React.useState();
  const user_id = localStorage.getItem("user_id");
  const { t } = useTranslation();
  const RegisterEnum = {
    business_name: "business_name",
    business_email: "business_email",
  };
  const [data, setData] = React.useState({
    business_name: "",
    business_email: "",
  });

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    dispatch(getListType());
  }, [resetListType]);

  React.useEffect(() => {
    const dataRegisterProfile = {
      vendor_id: null,
      user_id: user_id,
      vendor_name: data?.business_name,
      vendor_email: data?.business_email,
      payment_method: isSelectedPayment.values,
      business_type_id: isSelectedType.values,
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    setDataRegister(dataRegisterProfile);
  }, [data, isSelectedPayment, isSelectedType]);

  const onSelectedPayment = React.useCallback(
    (index: number) => {
      const newSelectedValues = isSelectedPayment.values;
      if (isSelectedPayment.values.includes(index)) {
        setIsSlectedPayment({
          values: isSelectedPayment.values.filter((el: number) => el !== index),
        });
      } else {
        newSelectedValues.push(index);
        setIsSlectedPayment({
          values: newSelectedValues,
        });
      }
    },
    [isSelectedPayment]
  );

  React.useEffect(() => {
    const tempr = [];
    let list = {};
    for (let i = 0; i < listBusinessType?.length; i++) {
      list = {
        id: listBusinessType[i].translation.business_type_id,
        name: listBusinessType[i].translation.business_type_name,
      };
      list && tempr.push(list);
    }
    setListType(tempr);
  }, [listBusinessType]);

  const onChangeByField = React.useCallback(
    (field: string, value: any) => {
      setData((prevState) => {
        return {
          ...prevState,
          [field]: value,
        };
      });
    },
    [setData]
  );

  React.useEffect(() => {
    if (businessProfile?.vendor_id) {
      navigate("/register/register-business-list");
    }
    businessProfile?.vendor_id && localStorage.setItem("vendor_id", businessProfile?.vendor_id);
  }, [businessProfile]);
  const onAcctionNext = async (e: any) => {
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    dispatch(createBusinessProfile(dataRegister));
    setResetListType(resetListType + 1);
  };
  // const data = [
  //     { id: 0, name: 'Books' },
  //     { id: 1, name: 'Groceries' },
  //     { id: 2, name: 'Type1' },
  //     { id: 3, name: 'Type2' },
  //     { id: 4, name: 'Type3' },
  //     { id: 5, name: 'Type4' },
  //     { id: 6, name: 'Type5' },
  // ];
  const dataPayment = [
    { id: 1, name: "Cash", icon: IconCoin },
    { id: 2, name: "Bank Transfer", icon: IconCard },
  ];
  const onSelectedType = React.useCallback(
    (index: number) => {
      const newSelectedValues = isSelectedType.values;
      if (isSelectedType.values.includes(index)) {
        setIsSlectedType({
          values: isSelectedType.values.filter((el: number) => el !== index),
        });
      } else {
        newSelectedValues.push(index);
        setIsSlectedType({
          values: newSelectedValues,
        });
      }
    },
    [isSelectedType]
  );
  console.log(dataPayment);
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div className="d-flex pt-5" style={{ marginLeft: 44 }}>
        <img src={require("../../assets/images/logo.png")} width={40} height={40} />
        <h6 className="my-1" style={{ marginLeft: 5 }}>
          MyBiz Business Portal
        </h6>
      </div>
      <div>
        <h2 className="text-center">{t("registration.business_profile")}</h2>
        <CForm onSubmit={onAcctionNext} className="mx-auto" style={{ maxWidth: 360, marginTop: 32 }}>
          <TextInput
            name={RegisterEnum.business_name}
            actionChangeText={onChangeByField}
            type="name"
            placeholder={t("customer.table.business_name")}
          />
          <span style={{ color: "red" }}>{businessProfile?.vendor_name?.length ? businessProfile?.vendor_name : null}</span>
          <TextInput name={RegisterEnum.business_email} actionChangeText={onChangeByField} type="text" placeholder={t("customer.business_email")} />
          <span style={{ color: "red" }}>{businessProfile?.vendor_email?.length ? businessProfile?.vendor_email : null}</span>

          <div className="d-flex row">
            <CFormLabel className="fw-bold" style={{ color: "#6B818C" }}>
              {t("registration.owner_name")}
            </CFormLabel>
            <CFormLabel>{`${user.first_name} ${user.middle_name || ""} ${user.last_name}`}</CFormLabel>
          </div>
          <div>
            <CFormLabel className="fw-bold" style={{ color: "#6B818C" }}>
              {t("registration.payment_methods")}
            </CFormLabel>
            <div className="d-flex">
              {dataPayment.map((type: any) => (
                <ButtonSelect
                  onSelected={() => onSelectedPayment(type.id)}
                  key={type.id}
                  backgroundColor={isSelectedPayment.values.includes(type.id) ? "#DBF2FB" : ""}
                  style={{ width: "100%", height: 42 }}
                  name={type.name}
                  icon={type.icon}
                />
              ))}
            </div>
            <span style={{ color: "red" }}>{businessProfile?.payment_method?.length ? businessProfile?.payment_method : null}</span>
          </div>
          <div>
            <CFormLabel className="fw-bold" style={{ color: "#6B818C" }}>
              {t("registration.business_type")}
            </CFormLabel>
            <div className="d-flex flex-wrap">
              {listType.map((type: any) => (
                <ButtonSelect
                  onSelected={() => onSelectedType(type.id)}
                  key={type.id}
                  backgroundColor={isSelectedType.values.includes(type.id) ? "#DBF2FB" : ""}
                  style={{ height: 32 }}
                  name={type.name}
                />
              ))}
            </div>
            <span style={{ color: "red" }}>{businessProfile?.business_type_id?.length ? businessProfile?.business_type_id : null}</span>
          </div>
          <div className="d-grid gap-2 mt-3">
            <CButton color="primary" type="submit">
              {t("registration.next")} (3/4)
            </CButton>
          </div>
        </CForm>
      </div>
      <Footer />
    </div>
  );
};
export default BusinessProfileScreen;
