import React from 'react';
import { Outlet } from 'react-router-dom';
import { CContainer } from '@coreui/react';
import Sidebar from '../components/Sidebar/Sidebar';
import hamgburgurBtn from '../assets/images/icon-hamburger.svg';
import {
  changeSidebarState,
  selectSidebar,
} from '../features/theme/themeSlide';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import CommonService from 'services/CommonService';

const ProtectedLayout = () => {
  const { isCollapse } = useAppSelector(selectSidebar);
  const dispatch = useAppDispatch();
  const { user } = CommonService.user();

  function initChat() {
    const wd = (window as any);
    function initFreshChat() {
      const userInfor = {
        firstName: user.first_name || '',
        lastName: user.last_name || '',
        email: user.email || '',
        externalId: `${`${user.first_name}.${user.user_id}`.normalize('NFD').replace(/[\u0300-\u036f]/g, '')}`,
        locale: 'vi'
      }
      wd.fcWidget.init({
        token: process.env.REACT_APP_FRESHCHAT_TOKEN,
        host: process.env.REACT_APP_FRESHCHAT_HOST,
      });
      // wd.fcWidget.setLocale(userInfor.locale);
      wd.fcWidget.setExternalId(userInfor.externalId);
      // To set user name
      wd.fcWidget.user.setFirstName(userInfor.firstName);
      wd.fcWidget.user.setLastName(userInfor.lastName);
      // To set user email
      wd.fcWidget.user.setEmail(userInfor.email);
      wd.fcWidget.user.setLocale(userInfor.locale)
      // To set user properties
      wd.fcWidget.user.setProperties({
        plan: "Pro",                 // meta property 1
        status: "Active"                // meta property 2
      });
    }
    function initialize(i, t) {
      let e; i.getElementById(t) ?
        initFreshChat() : ((e = i.createElement("script")).id = t, e.async = !0,
          e.src = "https://mybiztechnologiesvietnamllccompanylimite.freshchat.com/js/widget.js", 
          e.onload = initFreshChat, i.head.appendChild(e))
    }
    function initiateCall() { initialize(document, "Freshchat-js-sdk") }
    wd.addEventListener ? window.addEventListener("load", initiateCall, !1) :
      wd.attachEvent("load", initiateCall, !1);

      console.log(wd.fcWidget.user);
      
  }

  React.useEffect(() => {
    initChat();
  }, []);

  return (
    <>
      <CContainer fluid className='p-0 h-100 d-flex'>
        <Sidebar />
        <main className='w-100 px-4 overflow-auto'>
          <div className='app-head'>
            <button
              className='btn my-3'
              role='button'
              style={{ padding: 10 }}
              onClick={() =>
                dispatch(changeSidebarState({ isCollapse: !isCollapse }))
              }
            >
              <img src={hamgburgurBtn} />
            </button>
          </div>
          <Outlet />
        </main>
      </CContainer>
    </>
  );
};

export default ProtectedLayout;