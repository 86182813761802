import React from 'react';
import IconEdit from "../../assets/images/icon-edit.png";
import IconTrash from "../../assets/images/icon-trash.png";
import { useTranslation } from "react-i18next";
import { deleteLocation, detailLocation } from "../../features/business/businessSlices";
import { useDispatch } from 'react-redux';
import { IModalEditLocation } from 'screens/Register/types/IModalEditLocation';
interface ModalBusiness {
    locationName: string,
    district: string,
    address: string,
    ward: string,
    city: string,
    locationNumber: number,
    isDefault: number,
    vendor_location_id: number,
    onShowModal?: () => void | undefined,
    setModalEdit: React.Dispatch<React.SetStateAction<IModalEditLocation>>
}


const ModalBusiness = (props: ModalBusiness) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { locationName, isDefault, district, locationNumber, vendor_location_id, address, ward, city, setModalEdit } = props;
    const vendor_id = localStorage.getItem('vendor_id');

    const onDeleteLocation = React.useCallback(() => {
        const user_id = localStorage.getItem('user_id');
        const data = {
            vendor_id: vendor_id,
            user_id: user_id,
            vendor_location_id: vendor_location_id,
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch(deleteLocation(data));
    }, []);

    const onDetailLocation = () => {
        setModalEdit((prev) => ({ ...prev, show: true, setModalEdit, vendor_location_id }));
    };



    return (
        <div className="my-2">
            <b >{t("registration.location")} {locationNumber}</b>
            <div
                style={{
                    boxShadow: "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
                    border: "1px solid #F3F5F6",
                    borderRadius: 8,
                }}
                className="show d-block position-static py-2 px-3 mt-3"
            >
                <div className="d-flex">
                    <div className="row d-flex">
                        <span style={{ color: "#F26F21" }}>{isDefault ? ("Default") : (null)}</span>
                        {/* <span>{t('registration.location_name')}</span> */}
                        {/*  location, address, dictrict, city */}
                        <span>{locationName}</span>
                        <span>{address}</span>
                        <span>{ward}</span>
                        <span>{district}</span>
                        <span>{city}</span>

                    </div>
                    <div className="d-flex my-auto">
                        <img onClick={onDetailLocation} src={IconEdit} style={{ width: 20, height: 20, cursor: "pointer" }} alt="" />
                        <img onClick={onDeleteLocation} src={IconTrash} style={{ cursor: "pointer", width: 20, height: 20, marginLeft: 24 }} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalBusiness;