import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Business from "../../services/Business/Business";
import Employee from "../../services/Employee/EmployeesService";
import Orders from "../../services/SaleOrders";
const sliceName = "business";
const sliceState = (state) => state.business;
const initialState = {
  listBusinessType: [],
  businessProfile: [],
  dataBusinessProfileStep2: [],
  dataLocation: [],
  listLocation: [],
  deleteLocation: [],
  dataDetailLocation: [],
  updateLocation: [],
  listCustomer: [],
  dataAddCustomer: [],
  listTypeBusinessProfile: [],
  dataEditCustomer: [],
  dataDetailCustomer: [],
  dataUploadFile: [],
  dataDownloadFile: [],
  dataDownloadFileEmployee: [],
  dataDownloadFileOrders: [],
};

//Post register
export const getListType = createAsyncThunk(
  sliceName + "/getListType",
  // eslint-disable-next-line consistent-return
  async () => {
    try {
      const response = await Business.businesType();
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);
export const createBusinessProfile = createAsyncThunk(
  sliceName + "createBusinessProfile",
  // eslint-disable-next-line consistent-return
  async (data) => {
    try {
      const response = await Business.createBusinessProfile(data);
      if (response.data.code === 2) {
        return response.data.messageObject;
      }
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);
export const completeLocation = createAsyncThunk(
  sliceName + "createBusinessProfile2",
  // eslint-disable-next-line consistent-return
  async (data) => {
    try {
      const response = await Business.createBusinessStep2(data);
      if (response.data.code === 2) {
        return response.data.messageObject;
      }
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);
export const createLocation = createAsyncThunk(
  sliceName + "createLocation",
  // eslint-disable-next-line consistent-return
  async (data) => {
    try {
      const response = await Business.createLocation(data);
      if (response.data.code === 2) {
        return response.data.messageObject;
      }
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);
export const getListLocation = createAsyncThunk(
  sliceName + "getListLocation",
  // eslint-disable-next-line consistent-return
  async (vendor_id) => {
    try {
      const response = await Business.listLocation(vendor_id);
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);
export const deleteLocation = createAsyncThunk(
  sliceName + "deleteLocation",
  // eslint-disable-next-line consistent-return
  async (data) => {
    try {
      const response = await Business.deleteLocation(data?.vendor_id, data?.user_id, data?.vendor_location_id);
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);
export const detailLocation = createAsyncThunk(
  sliceName + "detailLocation",
  // eslint-disable-next-line consistent-return
  async (data) => {
    try {
      const response = await Business.detailLocation(data?.vendor_id, data?.vendor_location_id);
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);
export const editLocation = createAsyncThunk(
  sliceName + "editLocation",
  // eslint-disable-next-line consistent-return
  async (data) => {
    try {
      const response = await Business.editLocation(data);
      if (response.data.code === 2) {
        return response.data.messageObject;
      }
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);
export const getListCustomer = createAsyncThunk(
  sliceName + "getListCustomer",
  // eslint-disable-next-line consistent-return
  async (data) => {
    try {
      const response = await Business.customerList(data.vendor_id, data.page, data.keyword);
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);
export const addCustomer = createAsyncThunk(
  sliceName + "addCustomer",
  // eslint-disable-next-line consistent-return
  async (data) => {
    try {
      const response = await Business.customerAdd(data);
      if (response.data.code === 2) {
        return response.data.messageObject;
      }
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);
export const getBusinessProfileType = createAsyncThunk(
  sliceName + "getBusinessProfileType",
  // eslint-disable-next-line consistent-return
  async () => {
    try {
      const response = await Business.businessProfileType();
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);
export const editCustomer = createAsyncThunk(
  sliceName + "editCustomer",
  // eslint-disable-next-line consistent-return
  async (data) => {
    try {
      const response = await Business.customerUpdate(data);
      if (response.data.code === 2) {
        return response.data.messageObject;
      }
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);
export const getDetailCustomer = createAsyncThunk(
  sliceName + "getDetailCustomer",
  // eslint-disable-next-line consistent-return
  async (data) => {
    try {
      const response = await Business.customerDetail(data.vendor_id, data.customer_id);
      if (response.data.code === 2) {
        return response.data.messageObject;
      }
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);
export const uploadFileCustomer = createAsyncThunk(
  sliceName + "uploadFileCustomer",
  // eslint-disable-next-line consistent-return
  async (data) => {
    try {
      const response = await Business.uploadFileCustomer(data);
      if (response.data.code === 2) {
        return response.data;
      }
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);
export const downloadFileCustomer = createAsyncThunk(
  sliceName + "downloadFileCustomer",
  // eslint-disable-next-line consistent-return
  async (headings) => {
    try {
      const response = await Business.downloadFile(headings);
      if (response.data.code === 2) {
        return response.data.messageObject;
      }
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);
export const downloadErrFileCustomer = createAsyncThunk(
  sliceName + "downloadErrFileCustomer",
  // eslint-disable-next-line consistent-return
  async (data) => {
    try {
      console.log("data", data);
      const response = await Business.downloadErrFile(data);
      if (response.data.code === 2) {
        return response.data.messageObject;
      }
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);
export const uploadFileEmployee = createAsyncThunk(
  sliceName + "uploadFileEmployee",
  // eslint-disable-next-line consistent-return
  async (data) => {
    try {
      console.log("data", data);
      const response = await Employee.uploadFile(data);
      if (response.data.code === 2) {
        return response.data;
      }
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);
export const uploadFileOrders = createAsyncThunk(
  sliceName + "uploadFileOrders",
  // eslint-disable-next-line consistent-return
  async (data) => {
    try {
      console.log("data", data);
      const response = await Orders.uploadFileOrders(data);
      if (response.data.code === 2) {
        return response.data;
      }
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);
//CreateSlice
export const businessSlice = createSlice({
  name: sliceName,
  initialState,
  //ExtraReducer
  extraReducers: (builder) => {
    builder.addCase(getListType.pending, (state) => {
      // state.isLoadingResult = true;
    });
    builder.addCase(getListType.fulfilled, (state, action) => {
      state.listBusinessType = action.payload.data;
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(getListType.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
    builder.addCase(createBusinessProfile.pending, (state) => {
      // state.isLoadingResult = true;
    });
    builder.addCase(createBusinessProfile.fulfilled, (state, action) => {
      {
        action.payload.data ? (state.businessProfile = action.payload.data) : (state.businessProfile = action.payload);
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(createBusinessProfile.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
    builder.addCase(completeLocation.pending, (state) => {
      // state.isLoadingResult = true;
    });
    builder.addCase(completeLocation.fulfilled, (state, action) => {
      state.dataBusinessProfileStep2 = action.payload.data;
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(completeLocation.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
    builder.addCase(createLocation.pending, (state) => {
      // state.isLoadingResult = true;
    });
    builder.addCase(createLocation.fulfilled, (state, action) => {
      action.payload.data ? (state.dataLocation = action.payload.data) : (state.dataLocation = action.payload);
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(createLocation.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
    builder.addCase(getListLocation.pending, (state) => {
      // state.isLoadingResult = true;
    });
    builder.addCase(getListLocation.fulfilled, (state, action) => {
      state.listLocation = action.payload.data;
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(getListLocation.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
    builder.addCase(deleteLocation.pending, (state) => {
      // state.isLoadingResult = true;
    });
    builder.addCase(deleteLocation.fulfilled, (state, action) => {
      state.deleteLocation = action.payload;
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(deleteLocation.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
    builder.addCase(detailLocation.pending, (state) => {
      // state.isLoadingResult = true;
    });
    builder.addCase(detailLocation.fulfilled, (state, action) => {
      state.dataDetailLocation = action.payload.data;
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(detailLocation.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
    builder.addCase(editLocation.pending, (state) => {
      // state.isLoadingResult = true;
    });
    builder.addCase(editLocation.fulfilled, (state, action) => {
      state.updateLocation = action.payload;
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(editLocation.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
    builder.addCase(getListCustomer.pending, (state) => {
      // state.isLoadingResult = true;
    });
    builder.addCase(getListCustomer.fulfilled, (state, action) => {
      state.listCustomer = action.payload.data;
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(getListCustomer.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
    builder.addCase(addCustomer.pending, (state) => {
      // state.isLoadingResult = true;
    });
    builder.addCase(addCustomer.fulfilled, (state, action) => {
      action.payload.data ? (state.dataAddCustomer = action.payload.data) : (state.dataAddCustomer = action.payload);
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(addCustomer.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
    builder.addCase(getBusinessProfileType.pending, (state) => {
      // state.isLoadingResult = true;
    });
    builder.addCase(getBusinessProfileType.fulfilled, (state, action) => {
      state.listTypeBusinessProfile = action.payload.data;
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(getBusinessProfileType.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
    builder.addCase(getDetailCustomer.pending, (state) => {
      // state.isLoadingResult = true;
    });
    builder.addCase(getDetailCustomer.fulfilled, (state, action) => {
      state.dataDetailCustomer = action.payload.data;
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(getDetailCustomer.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
    builder.addCase(uploadFileCustomer.pending, (state) => {
      // state.isLoadingResult = true;
    });
    builder.addCase(uploadFileCustomer.fulfilled, (state, action) => {
      {
        action.payload.data ? (state.dataUploadFile = action.payload) : (state.dataUploadFile = action.payload);
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(uploadFileCustomer.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
    builder.addCase(downloadFileCustomer.pending, (state) => {
      // state.isLoadingResult = true;
    });
    builder.addCase(downloadFileCustomer.fulfilled, (state, action) => {
      state.dataDownloadFile = action.payload.data;
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(downloadFileCustomer.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
    builder.addCase(editCustomer.pending, (state) => {
      // state.isLoadingResult = true;
    });
    builder.addCase(editCustomer.fulfilled, (state, action) => {
      {
        action.payload.data ? (state.dataEditCustomer = action.payload) : (state.dataEditCustomer = action.payload);
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(editCustomer.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
    builder.addCase(uploadFileEmployee.pending, (state) => {
      // state.isLoadingResult = true;
    });
    builder.addCase(uploadFileEmployee.fulfilled, (state, action) => {
      {
        action.payload.data ? (state.dataDownloadFileEmployee = action.payload) : (state.dataDownloadFileEmployee = action.payload);
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(uploadFileEmployee.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });

    builder.addCase(uploadFileOrders.pending, (state) => {
      // state.isLoadingResult = true;
    });

    builder.addCase(uploadFileOrders.fulfilled, (state, action) => {
      {
        action.payload.data ? (state.dataDownloadFileOrders = action.payload) : (state.dataDownloadFileOrders = action.payload);
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(uploadFileOrders.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
  },
});

// //action
// export const {

//   } = appSlice.actions;

//slices
export const selectListBusinessType = (state) => sliceState(state).listBusinessType;
export const selectBusinessProfile = (state) => sliceState(state).businessProfile;
export const selectBusinessProfileStep2 = (state) => sliceState(state).dataBusinessProfileStep2;
export const selectLocation = (state) => sliceState(state).dataLocation;
export const selectListLocation = (state) => sliceState(state).listLocation;
export const selectDeleteLocation = (state) => sliceState(state).deleteLocation;
export const selectUpdateLocation = (state) => sliceState(state).updateLocation;
export const selectDataDetailLocation = (state) => sliceState(state).dataDetailLocation;
export const selectListCustomer = (state) => sliceState(state).listCustomer;
export const selectDataAddLocation = (state) => sliceState(state).dataAddLocation;
export const selectListProfileBusinessType = (state) => sliceState(state).listTypeBusinessProfile;
export const selectDataCustomer = (state) => sliceState(state).dataAddCustomer;
export const selectDataDetailCustomer = (state) => sliceState(state).dataDetailCustomer;
export const selectDataUploadFile = (state) => sliceState(state).dataUploadFile;
export const selectDataDownloadFile = (state) => sliceState(state).dataDownloadFile;
export const selectDataEditCusTomer = (state) => sliceState(state).dataEditCustomer;
export const selectDataDownloadFileEmployee = (state) => sliceState(state).dataDownloadFileEmployee;
export const selectDataDownloadFileOrders = (state) => sliceState(state).dataDownloadFileOrders;

export default businessSlice.reducer;
