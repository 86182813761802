import { CSSProperties } from 'react';

const collapseStyle = (isShow: boolean): CSSProperties => {
  return {
    visibility: !isShow ? 'visible' : 'hidden',
    opacity: !isShow ? 1 : 0,
    transition: 'visibility 0.25s, opacity 0.25s linear',
  };
};

const formatYYYYMMDD = (d: any | Date) => {
  const type = Object.prototype.toString.call(d);
  if (type === '[object String]') {
    d = new Date(d);
  }
  try {
    let month = '' + (d.getMonth() + 1),
      day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  } catch (error) {
    return ''
  }
}

const CheckLocalProfile = () => {
  return !!localStorage.getItem('vendor_location_name')
    && !!localStorage.getItem('vendor_id')
    && !!localStorage.getItem('vendor_location_id');
}

const CheckLocalAuth = () => {
  return !!localStorage.getItem('token');
}
function DownloadFile(url) {
  const a = document.createElement('a')
  a.href = url
  a.download = url.split('/').pop()
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

function cleanLocalStorage() {
  for(const key in localStorage) {
      delete localStorage[key];
  }
}
export {
  cleanLocalStorage,
  formatYYYYMMDD,
  CheckLocalAuth,
  collapseStyle,
  CheckLocalProfile,
  DownloadFile
}