import React from "react";
import {
    CButton,
    CForm,
    CFormCheck,
    CFormInput,
    CFormLabel,
    CBreadcrumb,
    CBreadcrumbItem,
    CRow,
    CCol,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import {
    getBusinessProfileType,
    selectListProfileBusinessType,
    selectListBusinessType,
    getListType,
    selectDataDetailCustomer,
    getDetailCustomer
} from "features/business/businessSlices";
import { useDispatch } from "react-redux";
import { useAppSelector } from "app/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import Business from "services/Business/Business";
import { Link } from "react-router-dom";
import CustomerDetail from "./types/CustomerDetail";

const AddCustomerScreen = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const vendor_id = localStorage.getItem("vendor_id");
    const mobile_phone = localStorage.getItem("mobile_phone");
    const [params] = useSearchParams();
    const customer_id = params.get("id");
    const listBusinessProfileType = useAppSelector(selectListProfileBusinessType);
    const listBusinessTypeAdd = useAppSelector(selectListBusinessType);
    const [customerType, setCustomerType] = React.useState<string | undefined>();
    const [businessType, setBusinessType] = React.useState([]);
    const dataDetailCustomer = useAppSelector(selectDataDetailCustomer);
    const [validation, setValidation] = React.useState<
        | {
            vendor_id: string[] | undefined;
            customer_id: string[] | undefined;
            customer_type: string[] | undefined;
            my_mobile_phone: string[] | undefined;
            mobile_phone: string[] | undefined;
            vendor_name: string[] | undefined;
            first_name: string[] | undefined;
            user_first_name: string[] | undefined;
            middle_name: string[] | undefined;
            last_name: string[] | undefined;
            business_type_id: string[] | undefined;
            error_message: "";
        }
        | undefined
    >();

    const navigate = useNavigate();
    const RegisterInfoCustomer = {
        mobile_phone: "mobile_phone",
        vendor_name: "vendor_name",
        first_name: "first_name",
        middle_name: "middle_name",
        last_name: "last_name",
    };

    const [infoCustomer, setInfoCustomer] = React.useState({
        mobile_phone: "",
        vendor_name: "",
        first_name: "",
        middle_name: "",
        last_name: "",
    });

    React.useEffect(() => {
        const data = {
            vendor_id: vendor_id,
            customer_id: customer_id,
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch(getDetailCustomer(data));
    }, []);

    React.useEffect(() => {
        const info = {
            mobile_phone: dataDetailCustomer?.mobile_phone,
            vendor_name: dataDetailCustomer?.vendor_customer?.vendor_name,
            first_name: dataDetailCustomer?.user_customer?.first_name,
            middle_name:
                dataDetailCustomer?.user_customer?.middle_name,
            last_name: dataDetailCustomer?.user_customer?.last_name,
        };
        setInfoCustomer(info);
        setCustomerType(dataDetailCustomer?.vendor_customer?.vendor_type);
        const type_business = [];
        for (
            let i = 0;
            i < dataDetailCustomer?.vendor_customer?.vendor_business_type?.length;
            i++
        ) {
            type_business.push(
                dataDetailCustomer?.vendor_customer?.vendor_business_type[i]
                    ?.business_type_id
            );
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        setBusinessType(type_business);
    }, [dataDetailCustomer]);
    React.useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch(getBusinessProfileType());
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch(getListType());

    }, []);

    const onChangeCustomerType = (event: any) => {

        setCustomerType(event.target.value);
    };

    const onChangeBusinessType = (event: any) => {
        const number = parseInt(event.target.value);
        if (businessType.includes(number)) {
            setBusinessType((prev: any) => prev.filter(el => el !== number));
        }
        else {
            setBusinessType((prev: any) => [...prev, number]);
        }
    };

    const onChangeByField = React.useCallback(
        (field: string, value: any) => {
            setInfoCustomer((prevState) => {
                return {
                    ...prevState,
                    [field]: value,
                };
            });
        },
        [setInfoCustomer]
    );
    const backScreenCustomer = () => {
        navigate("/customers");
    };

    const onEditCustomer = async (e: any) => {
        e.preventDefault();
        const data = {
            vendor_id: vendor_id,
            customer_id: customer_id,
            customer_type: customerType,
            my_mobile_phone: mobile_phone,
            mobile_phone: infoCustomer?.mobile_phone,
            vendor_name: infoCustomer?.vendor_name,
            first_name: infoCustomer?.first_name,
            middle_name: infoCustomer?.middle_name,
            last_name: infoCustomer?.last_name,
            business_type_id: businessType,
        };
        console.log("data", data)
        const response = await Business.customerUpdate(data);
        if (response.data.code === 0) {
            navigate("/customers");
        }
        if (response.data.code === 2) {
            setValidation(response.data.messageObject);
        }
        return response.data;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        // dispatch(editCustomer(data));
    };

    return (
        <div>
            <CBreadcrumb>
                <CBreadcrumbItem>
                    <Link className="breadcrumb-link text-secondary" to="/customers">
                        {t("customer.customers_title")}
                    </Link>
                </CBreadcrumbItem>
                <CBreadcrumbItem className="text-primary" active>
                    {t("customer.edit_customer")}
                </CBreadcrumbItem>
            </CBreadcrumb>
            <h2>{t("customer.edit_customer")}</h2>

            <CRow className="py-3" style={{ borderBottom: "1px solid #DBE1E4" }}>
                <CCol sm={12} md={12} xl={3}>
                    <h6>{t("customer.business_info")}</h6>
                </CCol>

                <CCol sm={12} md={12} xl={9}>
                    <CForm onSubmit={onEditCustomer}>
                        <CCol sm={12} md={12} xl={4}>
                            <CFormLabel>
                                {t("customer.select_type")}
                                <span className="text-danger"> *</span>
                            </CFormLabel>
                            <CRow>
                                {listBusinessProfileType.map((item: any) => (
                                    <CCol sm={12} md={12} xl={6} key={item?.id}>
                                        <CFormCheck checked={item?.id === parseInt(customerType || "")} value={item?.id} onChange={onChangeCustomerType} type="radio" name="flexRadioDefault" color="primary" id={"flexRadioDefault" + item.id} />
                                        <CFormLabel className='mb-0 ms-1' htmlFor={"flexRadioDefault" + item.id}>{item?.name}</CFormLabel>
                                    </CCol>
                                ))}
                            </CRow>
                        </CCol>
                        <span style={{ color: "red" }}>
                            {validation?.customer_type?.length
                                ? validation?.customer_type[0]
                                : null}
                        </span>

                        <CCol className="my-2">
                            <CFormLabel>
                                {t("customer.mobile_number_add_cus")}
                                <span className="text-danger"> *</span>
                            </CFormLabel>
                            <CFormInput
                                disabled={true}
                                value={infoCustomer?.mobile_phone || ""}
                                name={RegisterInfoCustomer.mobile_phone}
                                onChange={(e) => onChangeByField(e.target.name, e.target.value)}
                                style={{ width: 254 }}
                                type="number"
                                placeholder="Enter your phone"
                            />
                            <span style={{ color: "red" }}>
                                {validation?.mobile_phone?.length
                                    ? validation?.mobile_phone[0]
                                    : null}
                            </span>
                        </CCol>

                        <CCol className="my-2">
                            <CFormLabel>
                                {t("customer.business_name")}
                                <span className="text-danger"> *</span>
                            </CFormLabel>
                            <CFormInput
                                value={infoCustomer?.vendor_name || ""}
                                name={RegisterInfoCustomer.vendor_name}
                                onChange={(e) => onChangeByField(e.target.name, e.target.value)}
                                style={{ width: 254 }}
                                placeholder="Enter business name"
                            />
                            <span style={{ color: "red" }}>
                                {validation?.vendor_name?.length
                                    ? validation?.vendor_name[0]
                                    : null}
                            </span>
                        </CCol>

                        <CCol className="my-2">
                            <CRow>
                                <CCol sm={12} md={12} xl={4}>
                                    <CFormLabel>
                                        {t("customer.business_first_name")}
                                        <span className="text-danger"> *</span>
                                    </CFormLabel>
                                    <CFormInput
                                        value={infoCustomer?.first_name || ""}
                                        name={RegisterInfoCustomer.first_name}
                                        onChange={(e) =>
                                            onChangeByField(e.target.name, e.target.value)
                                        }
                                        style={{ width: 254 }}
                                        placeholder="First Name"
                                    />
                                    <span style={{ color: "red" }}>
                                        {validation?.first_name?.length
                                            ? validation?.first_name[0]
                                            : null}
                                    </span>
                                </CCol>

                                <CCol sm={12} md={12} xl={4}>
                                    <CFormLabel>{t("customer.business_middle_name")}</CFormLabel>
                                    <CFormInput
                                        value={infoCustomer?.middle_name || ""}
                                        name={RegisterInfoCustomer.middle_name}
                                        onChange={(e) =>
                                            onChangeByField(e.target.name, e.target.value)
                                        }
                                        style={{ width: 254 }}
                                        placeholder="Middle Name"
                                    />
                                    <span style={{ color: "red" }}>
                                        {validation?.middle_name?.length
                                            ? validation?.middle_name[0]
                                            : null}
                                    </span>
                                </CCol>

                                <CCol sm={12} md={12} xl={4}>
                                    <CFormLabel>
                                        {t("customer.business_last_name")}
                                        <span className="text-danger"> *</span>
                                    </CFormLabel>
                                    <CFormInput
                                        value={infoCustomer?.last_name || ""}
                                        name={RegisterInfoCustomer.last_name}
                                        onChange={(e) =>
                                            onChangeByField(e.target.name, e.target.value)
                                        }
                                        style={{ width: 254 }}
                                        placeholder="Last Name"
                                    />
                                    <span style={{ color: "red" }}>
                                        {validation?.last_name?.length
                                            ? validation?.last_name[0]
                                            : null}
                                    </span>
                                </CCol>
                            </CRow>
                        </CCol>
                        <div className="my-2">
                            <CFormLabel>{t("customer.business_type")}</CFormLabel>
                            <CRow>
                                {listBusinessTypeAdd.map((item: any) => (
                                    <CCol key={item.id} sm={12} md={12} xl={4}>

                                        <div className="d-flex">
                                            <CFormCheck checked={businessType.includes(
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                //@ts-ignore
                                                item.id
                                            )} onChange={onChangeBusinessType} value={item.id} id={"checkbox" + item.id} />
                                            <CFormLabel className='mb-0 ms-1' htmlFor={"checkbox" + item.id}>{item.translation.business_type_name}</CFormLabel>
                                        </div>
                                    </CCol>

                                ))}
                            </CRow>
                        </div>
                        <span style={{ color: "red" }}>
                            {validation?.error_message?.length
                                ? validation?.error_message
                                : null}
                        </span>
                    </CForm>
                </CCol>
            </CRow>
            <div className="my-5">
                <CButton onClick={onEditCustomer} color="primary">
                    {t("common.save")}
                </CButton>
                <CButton onClick={backScreenCustomer} color="transparent border ms-3">
                    {t("common.cancel")}
                </CButton>
            </div>
        </div>
    );
};

export default AddCustomerScreen;
