import http from "utils/http";

class Common {
    
  listProvince = [];

  constructor() {
    this.provinces().then((res) => {
      this.listProvince = res.data.data;
    });
  }

  provinces() {
    return http.get(`/get-city-list`);
  }
  districts(city_id: number) {
    return http.get(`/get-district-list?city_id=${city_id}`);
  }
  wards(district_id: number) {
    return http.get(`/get-ward-list?district_id=${district_id}`);
  }

  user() {
    const user = JSON.parse(localStorage.getItem('user'));
    const user_id = user.user_id;
    const vendor_id = localStorage.getItem('vendor_id');
    return { user_id, vendor_id, user };
  }
}
export default new Common();
