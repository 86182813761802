import React from "react";
import { CButton, CCol, CFormLabel, CFormSelect, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow } from "@coreui/react";
import { AssignManagerModalState, ModalState } from "./modalData";
import { useTranslation } from "react-i18next";

interface propTypes {
  modals: ModalState;
  state: AssignManagerModalState;
  setModals: any;
}

const AssignManagerModal = (props: propTypes) => {
  const { state, modals, setModals } = props;
  const { t } = useTranslation();
  const handleAssignManagerClose = () => {
    const assignModalManager: AssignManagerModalState = {
      ...state,
      visible: false,
    };
    setModals((prev: ModalState) => ({ ...prev, assignModalManager }));
  };

  return (
    <>
      <CModal alignment="center" onClose={handleAssignManagerClose} visible={state.visible} unmountOnClose={false}>
        <CModalHeader className="border-0">
          <CModalTitle>
            <div className="fs-5">Assign to Manager</div>
            <span className="modal-header-description text-muted">
              {t("employee.modal.select_manager_header_description")}
              <span className="text-dark">
                {" "}
                {modals.employeeSelected?.length || 0} {t("employee.employees")}
              </span>
            </span>
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CCol>
            <CFormLabel className="filter-label" htmlFor="modal-select-manager">
              {t("employee.modal.select_manager")} *
            </CFormLabel>
            <CFormSelect className="filter-form-select" type="text" id="modal-select-manager" aria-describedby="filter manager">
              <option value="" disabled selected>
                {t("employee.modal.manager_name")}
              </option>
            </CFormSelect>
          </CCol>
        </CModalBody>
        <CModalFooter>
          <CRow className="w-100">
            <CCol>
              <CButton variant="outline" className="w-100" color="secondary" onClick={handleAssignManagerClose}>
                {t("common.close")}
              </CButton>
            </CCol>
            <CCol>
              <CButton className="w-100" color="primary">
                {t("common.save")}
              </CButton>
            </CCol>
          </CRow>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default AssignManagerModal;
