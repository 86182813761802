import React, { CSSProperties, useState } from 'react';
import { CNavItem, CNavGroup, CCollapse } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useMatch } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectSidebar } from '../../features/theme/themeSlide';
import { collapseStyle } from '../../utils/function';
import { Link } from 'react-router-dom';

interface collapseName {
  employees: boolean;
}

const SidebarLink = (props: any) => {
  const { t } = useTranslation();
  const { icon, label, link, subLinks } = props;
  const hasSubLinks = subLinks && subLinks.length;
  const match = useMatch(link);
  const {pathname} = useLocation();
  const { isCollapse } = useAppSelector(selectSidebar);
  const [collapse, setCollapse] = useState<collapseName>({
    employees: true,
  });

  const handleCollapse = (
    name: keyof collapseName,
    value: boolean,
    event: any
  ) => {
    event.preventDefault();
    console.log(value);
    setCollapse((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <CNavItem className='mb-3'>
        <Link
          className='d-flex align-items-center rounded mx-3 p-0 text-muted text-nowrap'
          style={{ height: 38.75 }}
          to={link}
          onClick={
            hasSubLinks
              ? (event) =>
                  handleCollapse('employees', !collapse.employees, event)
              : undefined
          }
        >
          <span
            className={match ? `bg-primary` : 'bg-secondary'}
            style={iconHandleStyle(icon)}
          ></span>
          <div className='w-100' style={collapseStyle(isCollapse)}>
            {t(label)}
          </div>
        </Link>
        {!!hasSubLinks && (
          <CCollapse visible={collapse.employees}>
           {subLinks.map((subLink: any) => (
              <Link
                key={subLink.link}
                className={`d-flex align-items-center rounded mx-3 p-0 text-muted text-nowrap ${pathname === `/${link}/${subLink.link}` && 'active'}`}
                
                style={{ height: 38.75 }}
                to={`${link}${subLink.link}`}
                
              >
                <span
                  className={pathname === `${link}${subLink.link}` ? `bg-primary` : 'bg-secondary'}
                  style={{
                    ...iconHandleStyle(subLink.icon),
                    height: 10,
                    width: 10,
                  }}
                ></span>
                <div
                  className='w-100'
                  style={{
                    ...collapseStyle(isCollapse),
                  }}
                >
                  {t(subLink.label)}
                </div>
              </Link>
            ))}
          </CCollapse>
        )}
      </CNavItem>
    </>
  );
};

export default SidebarLink;

const iconHandleStyle = (icon: any) => {
  return {
    ...style.iconSize,
    mask: `url(${icon}) no-repeat center / contain`,
    WebkitMask: `url(${icon}) no-repeat center / contain`,
    margin: '0 15px',
  };
};

const style = {
  iconSize: {
    height: 20,
    minWidth: 18,
  },
};
