import { CFormText, CFormInput } from "@coreui/react";

import React from "react";
import { useTranslation } from "react-i18next";

const SearchBar = (props: any) => {
  const { t } = useTranslation();
  const { searchData, setSearchData, title = "employee.filter.seacrh_by_name/phone/email" } = props;
  const handleChangeSearch = (name: any, value: any) => {
    setSearchData((prev: any) => ({ ...prev, [name]: value }));
  };
  return (
    <div style={{ width: 320 }}>
      <CFormText className="my-2" style={{ color: "#3A4348", fontWeight: 500 }}>
        {t("common.search")}
      </CFormText>
      <CFormInput
        name="keyword"
        placeholder={t(title)}
        value={searchData.keyword}
        onChange={(e) => handleChangeSearch(e.target.name, e.target.value)}
      />
    </div>
  );
};
export default SearchBar;
