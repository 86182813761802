import React from "react";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";

interface PaginationProps {
  limit: any
  totalItems: any,
  current_page: any,
  navigate?: any
}

const Pagination = (props: PaginationProps) => {

  const { t } = useTranslation();
  const { limit, totalItems, current_page, navigate } = props

  const totalPages = React.useMemo(() => {
    return Math.ceil(totalItems / limit);
  }, [limit, totalItems]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    navigate(event.selected + 1, limit);
  };

  return (
    <>
      <ReactPaginate
        nextLabel={<span>{t("common.next")}</span>}
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        marginPagesDisplayed={3}
        pageCount={+totalPages}
        initialPage={+current_page - 1}
        previousLabel={<span>{t("common.previous")}</span>}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        disabledClassName="disabled"
      />
    </>
  );
};

export default Pagination;
