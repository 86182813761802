import React, { useCallback, useState } from "react";
import { CTableRow, CTableDataCell, CFormCheck } from "@coreui/react";
import PaymentObj from "./types/IPayment";
import ModalDetailOrder from "./modals/ModalDetailOrder";
import { useTranslation } from "react-i18next";
import ModalRequestPayment from "./modals/ModalRequestPayment";
import PaymentService from "services/PaymentService";
interface props {
  payment: PaymentObj;
  checked: any[];
  setChecked: React.Dispatch<React.SetStateAction<any[]>>;
  listPayment: () => void;
}
const TableRow = (props: props) => {
  const { t } = useTranslation();
  const vendor_id = localStorage.getItem("vendor_id");
  const user_id = localStorage.getItem("user_id");
  const { payment, checked, setChecked, listPayment } = props;
  const [visible, setVisible] = React.useState(false);
  const [errors, setErrors] = useState(null);

  const actionRequestPayment = async () => {
    const data = {
      vendor_id: +vendor_id,
      user_id: +user_id,
      payment_request_ids: [`${payment.payment.id}`],
    };
    PaymentService.sendPaymentRequest(data).then((res) => {
      if (!res.data.code) {
        setVisible(false);
        setErrors([]);
        listPayment();
      } else {
        setErrors(
          res.data.messageObject.payment_request_ids ? <div className="text-danger">{res.data.messageObject.payment_request_ids}</div> : null
        );
      }
    });
  };

  const handleChecked = (id: number) => {
    setChecked((prev) => {
      const isChecked = checked.includes(id);
      if (isChecked) {
        return checked.filter((item) => item !== id);
      }
      return [...prev, id];
    });
  };

  return (
    <>
      {/* <ModalDetailOrder visible={visible} setVisible={setVisible} order_list={payment.payment.order_list} /> */}
      <ModalRequestPayment errors={errors} visible={visible} setVisible={setVisible} action={actionRequestPayment} />
      <CTableRow
        style={{ backgroundColor: checked.includes(payment.payment.id) ? "#FFF6F2" : "transparent", opacity: payment.payment.status === 3 ? 0.5 : 1 }}
      >
        <CTableDataCell className="">
          <CFormCheck
            disabled={payment.payment.status === 3 ? true : false}
            type="checkbox"
            checked={checked.includes(payment.payment.id)}
            onChange={() => payment.payment.status === 3 ? undefined : handleChecked(payment.payment.id)}
          />
        </CTableDataCell>
        <CTableDataCell>{payment.getOrderSource()}</CTableDataCell>
        <CTableDataCell>{payment.getPONumber()}</CTableDataCell>
        <CTableDataCell>{payment.getCustomerName()}</CTableDataCell>
        <CTableDataCell>{payment.getAssignedEmployeeName()}</CTableDataCell>
        <CTableDataCell>{payment.getPaymenRequest()}</CTableDataCell>
        <CTableDataCell>{payment.getAmountRequest()}</CTableDataCell>
        <CTableDataCell>{payment.getAmountReceived()}</CTableDataCell>
        <CTableDataCell>{payment.getCreditLine()}</CTableDataCell>
        <CTableDataCell>{payment.getBankPayment()}</CTableDataCell>
        <CTableDataCell>{payment.getCashPayment()}</CTableDataCell>
        <CTableDataCell>{payment.getLastSentRequest()}</CTableDataCell>
        <CTableDataCell>{payment.getLastPaidDate()}</CTableDataCell>
        <CTableDataCell>
          <span
            style={{
              backgroundColor: payment.payment.status === 3 ? "#EEFFF8" : payment.payment.status === 1 ? "#FDE8E8" : "#FDF8E8",
              color: payment.payment.status === 3 ? "#019083" : payment.payment.status === 1 ? "#B42318" : "#B54708",
              padding: "2px 8px",
              borderRadius: 16,
              fontWeight: 500
            }}
          >
            {t(`payment.${payment.getStatus()}`)}
          </span>
        </CTableDataCell>
        <CTableDataCell onClick={() => setVisible(payment.payment.status === 3 ? false : true)}>
          <div style={{ fontSize: 12, color: "#059ED7", cursor: "pointer", fontWeight: "500" }}>{t("payment.request_payment")}</div>
        </CTableDataCell>
      </CTableRow>
    </>
  );
};

export default TableRow;
