import React from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CFormLabel,
  CRow,
  CCol,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from "@coreui/react";
import IconUpdate from "assets/images/icon-update-customer.png";

import SaleOrdersService from "services/SaleOrders";
import { useTranslation } from "react-i18next";

interface ModalUpdateCompelete {
  visible: boolean;
  data: any;
  onCancel: () => void;
}

const ModalUploadCompelete = (props: ModalUpdateCompelete) => {
  const { visible, data, onCancel } = props;
  const { t } = useTranslation();

  const downloadErrFile = async (e: any) => {
    e.preventDefault();
    const dataUpload = data?.error_data;
    const response = await SaleOrdersService.downloadFileErr({ data: dataUpload });

    const link = document.createElement("a");
    link.href = response.data.data.file_path;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    if (response?.data?.code == 0) {
      onCancel();
    }
  };

  console.log(data?.error_data);

  return (
    <CModal
      size="xl"
      className="show d-block position-absolute align-items-center pt-5"
      backdrop={true}
      keyboard={false}
      portal={false}
      visible={visible}
      onClose={onCancel}
    >
      <CModalHeader>
        <CModalTitle>{t("saleOrders.upload_xlsx_completed")}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div className="text-center">
          <img src={IconUpdate} />
        </div>
        <div className="text-center">
          <CFormLabel>
            <span>
              {data?.total_push} {t("saleOrders.orders")}{" "}
            </span>
            {t("customer.are_uploaded")}{" "}
            <span className="text-danger">
              {data?.total_error} {t("customer.record_errors")}{" "}
            </span>{" "}
            {t("customer.download_the_error")}
          </CFormLabel>
        </div>
        <div style={{ overflowY: "scroll", height: 150 }}>
          <CTable>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col"></CTableHeaderCell>
                <CTableHeaderCell scope="col">{t("saleOrders.number")}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t("saleOrders.order_value")}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t("saleOrders.customer_bussiness")}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t("saleOrders.assigned_employee")}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t("saleOrders.order_date")}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t("common.error")}</CTableHeaderCell>
              </CTableRow>
            </CTableHead>

            <CTableBody>
              {data?.error_data?.map((item: any, key: any) => (
                <CTableRow key={key}>
                  <CTableHeaderCell scope="row">{key + 1}</CTableHeaderCell>
                  <CTableDataCell>{item.order_number}</CTableDataCell>
                  <CTableDataCell>{item.order_value}</CTableDataCell>
                  <CTableDataCell>{item.customer_name}</CTableDataCell>
                  <CTableDataCell>{item.assigned_employee_name}</CTableDataCell>
                  <CTableDataCell>{item.order_date}</CTableDataCell>
                  <CTableDataCell>{item.error_message}</CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
        </div>
      </CModalBody>
      <CModalFooter>
        {data?.total_error > 0 ? (
          <CRow className="w-100">
            <CCol>
              <CButton onClick={onCancel} className="w-100 border" color="transparent">
                {t("common.cancel")}
              </CButton>
            </CCol>
            <CCol>
              <a onClick={downloadErrFile} href="">
                <CButton className="w-100" color="primary">
                  {t("common.download_file")}
                </CButton>
              </a>
            </CCol>
          </CRow>
        ) : null}
      </CModalFooter>
    </CModal>
  );
};
export default ModalUploadCompelete;
