import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";


const initialState = () => {
  return {
    sidebar: {
      isCollapse: false,
    },
  };
};

export const themeSlice = createSlice({
  name: "theme",
  initialState: initialState(),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    changeSidebarState: (state, actions) => {
      state.sidebar = { ...state.sidebar, ...actions.payload };
      return state;
    },
  },
});

export const { changeSidebarState } = themeSlice.actions;

export const selectTheme = (state: RootState) => state.theme;
export const selectSidebar = (state: RootState) => state.theme.sidebar;

export default themeSlice.reducer;
