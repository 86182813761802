/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios, { AxiosInstance } from 'axios';

const http: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,

    headers:
    {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        // 'Accept-Language': 'en'
    }
});

// Add a request interceptor
http.interceptors.request.use(function (config) {
    
    const token = localStorage.getItem('token');
    const lang = localStorage.getItem('lang');
    // Do something before request is sent
    //@ts-ignore
    config.headers.common['Authorization'] = token || '';
    config.headers.common['Device-Type'] = process.env.REACT_APP_DEVICE_TYPE + '';
    config.headers.common['X-Authorization'] = process.env.REACT_APP_X_AUTH + '';
    config.headers.common['Version'] = process.env.REACT_APP_API_VERSION + '';
    config.headers.common['Accept-Language'] = lang === "true" ? 'vi' : 'en';
    
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Ư



export default http;