import { CCol, CContainer, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from "@coreui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import OnBoardingService from "services/OnBoardingService";
import NoDataTable from "components/NoDataTable";
import IconCheckWhite from "../../assets/images/ic_checked_white.png";
import IconOneBizDone from "../../assets/images/logo_1biz_done.png";
import IconOneBiz from "../../assets/images/logo_1biz.png";

const employeeCol = {
  steps: {
    label: "onboarding.steps",
    isShow: true,
  },
  status: {
    label: "onboarding.status",
    isShow: true,
  },
  total_1biz_points: {
    label: "onboarding.total_1biz_points",
    isShow: true,
  },
};

const OnBoardingProcessScreen = () => {
  const { t } = useTranslation();
  const vendor_id = localStorage.getItem("vendor_id");
  const [col] = React.useState(employeeCol);
  const [data, setData] = React.useState<{ id: number; feature_name: string; is_done: number; point: number }[]>([]);

  const totalDone = data.filter((item) => item.is_done).length;
  const totalCount = data.reduce((acc, item) => (acc += item.point), 0);
  const point = (totalDone / data.length) * 100;

  React.useEffect(() => {
    OnBoardingService.getListOnboarding(+vendor_id).then((res) => {
      if (!res.data.code) {
        setData(res.data.data);
      } else {
        setData([]);
      }
    });
  }, []);

  return (
    <CContainer fluid className="mt-3 p-0" style={{ padding: "0 13px" }}>
      <CRow>
        <CCol className="mb-4">
          <h2>{t("sidebar.onboarding")}</h2>
        </CCol>
      </CRow>
      {data.length > 0 ? (
        <div
          style={{
            padding: 20,
            height: 110,
            marginBottom: 24,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ fontSize: 24, display: "flex" }}>
            <div style={{ marginRight: 4, color: "#0CB6A5" }}>{totalDone}</div>
            <div>/{data.length}</div>
          </div>
          <div style={{ marginLeft: 16, width: "100%", height: 8, background: "#CFFFEB", borderRadius: 4, position: "relative" }}>
            <div style={{ position: "absolute", width: `${point}%`, height: 8, background: "#0CB6A5", borderRadius: 4 }} />
            <div
              style={{
                position: "absolute",
                top: -14,
                right: 0,
                width: 32,
                height: 32,
                border: totalDone === data.length ? "1px solid #fff" : "1px solid #04AC9C",
                borderRadius: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: totalDone === data.length ? "#0CB6A5" : "#EEFFF8",
              }}
            >
              <img src={IconOneBiz} width={17} height={17} alt="" />
            </div>
          </div>
          <div style={{ marginLeft: 16, marginBottom: 5, fontSize: 24, color: "#F26F21", fontWeight: 600 }}>{totalCount}</div>
        </div>
      ) : null}
      <div>
        <div className="table-wrapper">
          <CTable responsive="xl" align="middle">
            <CTableHead>
              <CTableRow className="py-4">
                <CTableHeaderCell scope="col"></CTableHeaderCell>
                <CTableHeaderCell scope="col-1">{t(col.steps.label)}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t(col.status.label)}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t(col.total_1biz_points.label)}</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {data.length > 0 &&
                data.map((item, index) => {
                  return (
                    <CTableRow key={item.id}>
                      <CTableDataCell style={{ padding: 14, textAlign: "center" }}>{index + 1}</CTableDataCell>
                      <CTableDataCell style={{ padding: 14 }}>
                        <div style={{ display: "flex", alignItems: "center", color: item.is_done ? "#6B818C" : "#000" }}>
                          {item.is_done ? (
                            <div
                              style={{
                                width: 17,
                                height: 17,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#04AC9C",
                                borderRadius: 100,
                                marginRight: 10,
                              }}
                            >
                              <img src={IconCheckWhite} width={10} height={10} alt="" />
                            </div>
                          ) : (
                            <div
                              style={{
                                width: 17,
                                height: 17,
                                borderRadius: 100,
                                marginRight: 10,
                                border: "1px solid #AEBBC1",
                              }}
                            ></div>
                          )}

                          {item.feature_name}
                        </div>
                      </CTableDataCell>
                      <CTableDataCell style={{ padding: 14 }}>
                        {item.is_done ? (
                          <span style={{ backgroundColor: "#EEFFF8", padding: "4px 8px", color: "#019083", fontWeight: 500, borderRadius: 16 }}>
                            {t("onboarding.done")}
                          </span>
                        ) : (
                          <span style={{ backgroundColor: "#FDF8E8", padding: "4px 8px", color: "#B54708", fontWeight: 500, borderRadius: 16 }}>
                            {t("onboarding.not_done")}
                          </span>
                        )}
                      </CTableDataCell>
                      <CTableDataCell style={{ padding: 14 }}>
                        {item.is_done ? (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <span style={{ marginRight: 9 }}>{item.point ? `+${item.point}` : "-"}</span>
                            {item.point ? <img src={IconOneBizDone} width={16} height={16} alt="" /> : ""}
                          </div>
                        ) : (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <span style={{ marginRight: 9, color: "#F26F21" }}>{item.point ? `+${item.point}` : "-"}</span>
                            {item.point ? <img src={IconOneBiz} alt="" width={16} height={16} /> : ""}
                          </div>
                        )}
                      </CTableDataCell>
                    </CTableRow>
                  );
                })}
            </CTableBody>
          </CTable>
          {!data?.length && <NoDataTable />}
        </div>
      </div>
    </CContainer>
  );
};

export default OnBoardingProcessScreen;
