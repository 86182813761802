import http from "utils/http";

class PaymentService {
  getListPayment(keyword, vendor_id, page) {
    return http.get(`portal/payment-console/list?keyword=${keyword}&vendor_id=${vendor_id}&page=${page}`);
  }
  downloadExport(vendor_id, start_date, end_date, export_type) {
    return http.get(
      `portal/payment-console/export-list?vendor_id=${vendor_id}&start_date=${start_date}&end_date=${end_date}&export_type=${export_type}`
    );
  }
  assignEmployee(data: { payment_request_ids: string[]; employee_id: number }) {
    return http.post(`portal/payment-console/assign-employee`, data);
  }
  sendPaymentRequest(data: { vendor_id: number; user_id: number; payment_request_ids: string[] }) {
    return http.post(`portal/payment-console/send-payment-request`, data);
  }
}
export default new PaymentService();
