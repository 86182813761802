import http from "../../utils/http";

class EmployeesService {
  user_id = localStorage.getItem("user_id");
  vendor_id = localStorage.getItem("vendor_id");
  vendor_location_id = localStorage.getItem("vendor_location_id");
  vendor_location_name = localStorage.getItem("vendor_location_name");

  get(listType: string, keyword: string, page: string, limit: string, location_id: string, manager_by: string) {
    this.user_id = localStorage.getItem("user_id");
    this.vendor_id = localStorage.getItem("vendor_id");
    return http.get(
      `/portal/employee/${listType}?keyword=${keyword}&location_id=${location_id}&page=${page}&limit=${limit}&user_id=${this.user_id}&vendor_id=${this.vendor_id}&manager_by=${manager_by}`
    );
  }

  add(data: any) {
    return http.post(`/portal/employee/create`, data);
  }

  edit(data: any) {
    return http.post(`/portal/employee/update`, data);
  }

  getById(id: string) {
    return http.get(`/portal/employee/detail?employee_id=${id}`);
  }

  getManagerList() {
    return http.get(`/portal/employee/manager-list?user_id=${this.user_id}&vendor_id=${this.vendor_id}`);
  }
  getLocationList() {
    return http.get(`/portal/employee/location-list?user_id=${this.user_id}&vendor_id=${this.vendor_id}`);
  }

  dowloadSample(headings: any) {
    return http.get(`/portal/employee/download-sample-file?headings=${headings}`);
  }

  uploadFile(data: any) {
    return http.post("/portal/employee/upload-employee-list", data);
  }

  resignedList(keyword: string, page: string, limit: string, location_id: string) {
    return http.get(
      `/portal/employee/list-resigned?keyword=${keyword}&location_id=${location_id}&page=${page}&limit=${limit}&user_id=${this.user_id}&vendor_id=${this.vendor_id}`
    );
  }

  approveList(keyword: string, page: string, limit: string, location_id: string) {
    return http.get(
      `/portal/employee/list-approve-request?keyword=${keyword}&location_id=${location_id}&page=${page}&limit=${limit}&user_id=${this.user_id}&vendor_id=${this.vendor_id}`
    );
  }

  magagerList(keyword: string, page: string, limit: string) {
    return http.get(
      `/api/portal/employee/manager-list?keyword=${keyword}&page=${page}&limit=${limit}&user_id=${this.user_id}&vendor_id=${this.vendor_id}`
    );
  }
  downloadFileErr(data: any) {
    return http.post(`/portal/employee/download-error-file?user_id=${this.user_id}`, {
      data: data,
    });
  }
  approve(data) {
    return http.put("/portal/employee/approve", data);
  }
  getRole(vendor_id: string) {
    return http.get(`/role/list?vendor_id=${vendor_id}&status=2`);
  }
  assignEmployee(data) {
    return http.post(`/portal/employee/assign-role`, data);
  }
}
export default new EmployeesService();
