import React from 'react';
import { CButton, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow } from '@coreui/react'
import { useTranslation } from 'react-i18next'

const ApproveRequestModal = (props: any) => {
    const { show, onConfirm, onCancel } = props
    const { t } = useTranslation();

    return (
        <CModal alignment="center" visible={show} onClose={onCancel} unmountOnClose={false}>
            <CModalHeader className='border-0'>
                <CModalTitle>
                    <div className='fs-5'>{t('employee.modal.approve_request')}</div>
                </CModalTitle>
            </CModalHeader>
            <CModalBody>
                <p className='text-center text-muted'>{t('employee.modal.are_you_sure_you_want_to_approve_this_request')}</p>
            </CModalBody>
            <CModalFooter>
                <CRow className='w-100'>
                    <CCol>
                        <CButton variant='outline' className='w-100' color="secondary" onClick={onCancel}>{t('common.cancel')}</CButton>
                    </CCol>
                    <CCol>
                        <CButton className='w-100' color="primary" onClick={onConfirm}>{t('common.confirm')}</CButton>
                    </CCol>
                </CRow>
            </CModalFooter>
        </CModal>
    )
}

export default ApproveRequestModal