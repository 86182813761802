import moment from "moment";

export interface IOrders {
  id: number;
  order_number: string;
  total: number;
  type: number;
  status: number;
  assigned_employee_id: null;
  time_order: string;
  quantity: number;
  user_employee: UserEmployee;
  order_detail: OrderDetail;
}

export interface OrderDetail {
  order_id: number;
  quantity: number;
  item_quantity: number;
}

export interface UserEmployee {
  id: number;
  user_id: number;
  status: number;
  user: {
    id: number;
    last_name: string;
    middle_name: string;
    first_name: string;
  };
}

export default class OrdersObj {
  orders: IOrders;
  dateFormat = "DD/MM/YYYY";
  constructor(data: any) {
    this.orders = { ...data };
  }
  getNumberPO() {
    return this.orders.order_number ? `${this.orders.order_number}` : "";
  }
  getQuantityPO() {
    return `${this.orders.quantity ? this.orders.quantity : 0} items`;
  }
  getOrderValue() {
    return this.orders.total ? new Intl.NumberFormat("vi-VN", { style: "currency", currency: "VND" }).format(this.orders.total) : "";
  }
  getAssignEmployee() {
    return this.orders.user_employee
      ? `${this.orders.user_employee.user.last_name} ${
          this.orders.user_employee.user.middle_name ? this.orders.user_employee.user.middle_name : ""
        } ${this.orders.user_employee.user.first_name}`
      : "";
  }
  getOrderDate() {
    return this.orders.time_order ? `${moment(this.orders.time_order).format(this.dateFormat)}` : "";
  }
}
