import React from 'react';

const Footer = () => {
    const [year,setYear] = React.useState(0);
    React.useEffect(()=>{
        const newDate = new Date();
        const year = newDate.getFullYear();
        setYear(year);
      },[]);
    return(
 <div className="w-100" style={{color:"#475467",marginTop: "auto"}}><p style={{textAlign:"center"}}>&#169; {year} All rights reserved.</p></div> 

    );
};
export default Footer;