import { CTableHeaderCell, CTableRow } from "@coreui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const employeeCol = {
  id: {
    label: "employee.table.id",
    isShow: true,
  },
  name: {
    label: "employee.table.name",
    isShow: true,
  },
  role: {
    label: "employee.table.role",
    isShow: true,
  },
  manager: {
    label: "employee.table.manager",
    isShow: true,
  },
  cashbook: {
    label: "employee.table.cashbook",
    isShow: true,
  },
  joining_date: {
    label: "employee.table.joining_date",
    isShow: true,
  },
  base_loca_name: {
    label: "employee.table.base_location_name",
    isShow: true,
  },
};

const TableHeader = () => {
  const { t } = useTranslation();
  const [col] = React.useState(employeeCol);

  return (
    <CTableRow className="py-4">
      <CTableHeaderCell scope="col"></CTableHeaderCell>
      <CTableHeaderCell scope="col" hidden={!col?.id.isShow}>
        {t(col?.id.label)}
      </CTableHeaderCell>
      <CTableHeaderCell scope="col" hidden={!col?.name.isShow}>
        {t(col?.name.label)}
      </CTableHeaderCell>
      <CTableHeaderCell scope="col" hidden={!col?.role.isShow}>
        {t(col?.role.label)}
      </CTableHeaderCell>
      <CTableHeaderCell scope="col" hidden={!col?.manager.isShow}>
        {t(col?.manager.label)}
      </CTableHeaderCell>
      <CTableHeaderCell scope="col" hidden={!col?.cashbook.isShow}>
        {t(col?.cashbook.label)}
      </CTableHeaderCell>
      <CTableHeaderCell scope="col" hidden={!col.joining_date.isShow}>
        {t(col.joining_date.label)}
      </CTableHeaderCell>
      <CTableHeaderCell scope="col" hidden={!col.base_loca_name.isShow}>
        {t(col.base_loca_name.label)}
      </CTableHeaderCell>
      <CTableHeaderCell scope="col"></CTableHeaderCell>
    </CTableRow>
  );
};

export default TableHeader;
