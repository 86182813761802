import React, { useState } from "react";
import { CFormSwitch, CSpinner } from "@coreui/react";
import Footer from "components/Footer/footer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "utils/i18next";
import Authentication from "services/Authentication";
import "./ScreenLoginQR.css";
import socket from "utils/socket";
import { loginByToken, selectDataLogin } from "features/login/loginSlices";
import { useDispatch } from "react-redux";
import { useAppSelector } from "app/hooks";
import { internalIpV4 } from "internal-ip";

type IData = {
  ip_address: string;
  mac_address: string;
  user_agent: string;
  web_token: string;
  qr_code: string;
  expired_at: string;
  id: number;
};

const ScreenLoginQR = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = localStorage.getItem("lang");
  const [data, setData] = useState<IData | null>(null);
  const [showLang, setShowLang] = React.useState(lang === "true" ? true : lang === "false" ? false : true);
  const dataLogin = useAppSelector(selectDataLogin);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    if (showLang === true) {
      i18n.changeLanguage("vi");
    } else {
      i18n.changeLanguage("en");
    }
    localStorage.setItem("lang", showLang.toString());
  }, [showLang]);

  const fetchData = async () => {
    setLoading(true);
    const res = await internalIpV4();
    Authentication.loginByQr({
      ip_address: res,
      // mac_address: "2C-33-58-E5-B1-C178900",
      user_agent: window.navigator.userAgent,
    })
      .then((res) => {
        if (!res.data.code) {
          setData(res.data.data);
          setLoading(false);
        } else {
          setData(null);
          setLoading(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (dataLogin?.user_id) {
      if (dataLogin?.is_verified === false) {
        navigate(`/sign-in/verification?mobile_phone=${dataLogin?.mobile_phone}`);
      } else {
        navigate("/profile-list", { replace: true });
      }
    }
    dataLogin?.user_id && localStorage.setItem("user_id", dataLogin?.user_id);
    dataLogin?.access_token && localStorage.setItem("token", dataLogin?.access_token);
    dataLogin?.mobile_phone && localStorage.setItem("mobile_phone", dataLogin?.mobile_phone);
    dataLogin && localStorage.setItem("user", JSON.stringify(dataLogin));
  }, [dataLogin]);

  React.useEffect(() => {
    socket.on("portal_login", (dataSocket: any) => {
      console.log("dataSocket", dataSocket.web_token);
      if (dataSocket.web_token === data?.web_token) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch(loginByToken(dataSocket?.data?.user_token));
        localStorage.setItem("web_token", dataSocket?.web_token);
      }
    });
  }, [data]);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="d-flex pt-5" style={{ marginLeft: 44 }}>
        <img src={require("../../assets/images/logo.png")} width={40} height={40} />
        <h6 className="my-1" style={{ marginLeft: 5 }}>
          MyBiz Business Portal
        </h6>
      </div>
      <div>
        {/* <h2>{t('register.user_registration')}</h2> */}
        <h2 className="text-center">{t("registration.login_with_qr_code")}</h2>
        <div style={{ position: "relative", display: "flex", justifyContent: "center", marginTop: 32 }}>
          {loading ? (
            <div style={{ width: 200, height: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
              <CSpinner style={{ color: "#f26f21" }} />
            </div>
          ) : (
            <img src={data?.qr_code} style={{ opacity: !data?.qr_code ? 0.4 : 1 }} />
          )}
          {/* {!data?.qr_code ? (
            <div className="centered">
              <div>{t("registration.the_qr_code_has_expired")}</div>
              <button onClick={() => fetchData()} className="button">
              {t("registration.get_new_code")}
              </button>
            </div>
          ) : null} */}
        </div>
        <div className="mx-auto" style={{ maxWidth: 360, marginTop: 32 }}>
          <p
            onClick={() => navigate("/register")}
            className="text-center"
            style={{
              marginTop: 24,
              color: "#059ED7",
              cursor: "pointer",
              fontWeight: 500,
            }}
          >
            {t("registration.register")}
          </p>
          <p
            onClick={() => navigate("/sign-in")}
            className="text-center"
            style={{
              marginTop: 24,
              color: "#059ED7",
              cursor: "pointer",
              fontWeight: 500,
            }}
          >
            {t("registration.sign_in")}
          </p>
        </div>
      </div>
      <Footer />
      <div style={{ position: "fixed", bottom: 3, left: 45 }}>
        <CFormSwitch label={showLang ? "VIE" : "EN"} id="formSwitchCheckChecked" defaultChecked={showLang} onChange={() => setShowLang(!showLang)} />
      </div>
    </div>
  );
};

export default ScreenLoginQR;
