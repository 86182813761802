import React from "react";
import imageNodata from "../assets/images/nodata.png";

interface NoDataTableProp {
  title?: string;
  message?: string;
}

const NoDataTable = (props: NoDataTableProp) => {
  const { message, title} = props
  return (
    <div className="py-5 d-flex w-100 flex-column">
      
      <div className="mx-auto rounded-pill d-flex" style={{width: 350, height: 350, background: "#E8EBED"}}>
        <img className="d-block m-auto" src={imageNodata} alt="" />
      </div>
      <div className="fs-4 text-center pt-3" style={{fontWeight: "600"}}>{title || "No Data Display"}</div>
      <div className="fs-5 text-center text-muted">{message || "Please use Add New button to create new data"}</div>
    </div>
  );
};

export default NoDataTable;
