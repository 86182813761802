import { CBreadcrumb, CBreadcrumbItem, CContainer, CRow, CCol } from '@coreui/react';
import React from 'react';
import { getDetailCustomer, selectDataDetailCustomer } from "features/business/businessSlices";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from 'app/hooks';
import { useDispatch } from 'react-redux';
import Business from 'services/Business/Business';
import CustomerDetail from './types/CustomerDetail';
const CustomerDetailScreen = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const [customer, setCustomer] = React.useState<CustomerDetail>();

    const dispatch = useDispatch();
    const customer_id = params.get("id");
    const vendor_id = localStorage.getItem('vendor_id');
    const dataDetailCustomer = useAppSelector(selectDataDetailCustomer);
    console.log("dataDetailCustomer", dataDetailCustomer);
    React.useEffect(() => {
        const data = {
            vendor_id: vendor_id,
            customer_id: customer_id
        };
        Business.customerDetail(data.vendor_id, data.customer_id).then(res => {
            if (res.data.code === 0) {
                setCustomer(new CustomerDetail(res.data.data));
            }
        })
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        // dispatch(getDetailCustomer(data));
    }, []);
    return (
        <CContainer style={{ borderBottom: '1px solid #DBE1E4' }}>
            <CBreadcrumb>
                <CBreadcrumbItem onClick={() => { navigate("/customers"); }} style={{ color: "#6B818C", cursor: "pointer" }}>{t('customer.customers_title')}</CBreadcrumbItem>
                <CBreadcrumbItem className="text-primary" active>{t('customer.detail_customer')}</CBreadcrumbItem>
            </CBreadcrumb>
            <h3>{t("customer.customers_details")}</h3>
            <CRow style={{ borderBottom: '1px solid #DBE1E4' }}>
                <CCol sm={12} md={12} xl={4}>
                    <div className="fs-5" style={{ fontWeight: 600 }}>{t("customer.business_info")}</div>
                </CCol>
                <CCol sm={12} md={8}>
                    <CRow className='mb-2'>
                        <CCol>{t('customer.type_of_customer')}</CCol>
                        <CCol>{customer && t(customer.getVendorType())}</CCol>
                    </CRow >
                    <CRow className='mb-2'>
                        <CCol>{t('customer.business_name')}</CCol>
                        <CCol>{customer && customer.getVendorName()}</CCol>
                    </CRow>
                    <CRow className='mb-2'>
                        <CCol>{t('customer.business_first_name')}</CCol>
                        <CCol>{customer && customer.data.user_customer.first_name}</CCol>
                    </CRow>
                    <CRow className='mb-2'>
                        <CCol>{t('customer.business_middle_name')}</CCol>
                        <CCol>{customer && customer.data.user_customer.middle_name}</CCol>
                    </CRow>
                    <CRow className='mb-2'>
                        <CCol>{t('customer.business_last_name')}</CCol>
                        <CCol>{customer && customer.data.user_customer.last_name}</CCol>
                    </CRow>
                    <CRow className='mb-2'>
                        <CCol>{t('customer.business_type')}</CCol>
                        <CCol>
                            {customer && <span>{customer.data.vendor_customer.vendor_business_type.map((el) => el.translation.business_type_name).join(', ')}</span>}
                        </CCol>
                    </CRow>

                </CCol>
            </CRow>
        </CContainer>
    );
};
export default CustomerDetailScreen;