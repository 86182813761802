import React, { useState } from "react";
import IconArrowDown from "assets/images/icon-arrow-down.png";
import IconSearch from "assets/images/icon_search.png";
import IconCheck from "assets/images/icon_check.png";
import IconClose from "assets/images/icon_close.png";
import { CFormInput } from "@coreui/react";
import "./CustomMenuDropdown.css";
import { IUserEmployee } from "../SalesOrdersScreen";
import { useTranslation } from "react-i18next";

interface Props {
  title?: string;
  placeholder?: string;
  select: number;
  setSelect: React.Dispatch<React.SetStateAction<number>>;
  width?: number | string;
  data?: IUserEmployee[];
  searchText?: string;
  setSearchText?: React.Dispatch<React.SetStateAction<string>>;
}

export const CustomMenuDropdown = ({ title, placeholder, searchText, setSearchText, data, select, setSelect, width = "100%" }: Props) => {
  const filterName = data.find((item) => item.id === select);
  const { t } = useTranslation();
  const [isShowSelect, setIsShowSelect] = useState(false);

  const handleMenuClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsShowSelect(!isShowSelect);
  };

  const handleSelectItem = (id: number) => {
    if (select === id) {
      setSelect(null);
      setSearchText("");
    } else {
      setSelect(id);
      setSearchText("");
      setIsShowSelect(false);
    }
  };
  const renderName = (item) => {
    if (item.user) {
      return `${item.user.last_name} ${item.user.middle_name ? item.user.middle_name : ""} ${item.user.first_name}`;
    }
    return `${item.vendor_customer?.vendor_name ? item.vendor_customer?.vendor_name : ""}`;
  };
  return (
    <div>
      <div onClick={handleMenuClick} style={{ width: width, position: "relative", border: "1px solid #AEBBC1", borderRadius: 8, cursor: "pointer" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ padding: "10px 14px", color: select ? "#000" : "#6B818C" }}>{filterName ? renderName(filterName) : t(title)}</div>
          <div style={{ padding: "10px 14px" }}>
            <img src={IconArrowDown} alt="ic_arrow_down" />
          </div>
        </div>
      </div>
      {isShowSelect ? (
        <div
          className="children"
          style={{
            position: "absolute",
            marginTop: 5,
            background: "#fff",
            width: width === "100%" ? "94%" : width,
            borderRadius: 8,
            zIndex: 1000,
          }}
        >
          <div>
            <CFormInput
              placeholder={t(placeholder)}
              value={searchText}
              onChange={(e) => (setSearchText ? setSearchText(e.target.value) : null)}
              style={{ fontSize: 16, paddingRight: 40, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
            />
            <img
              style={{ position: "absolute", alignItems: "center", justifyContent: "center", right: 15, top: searchText ? 15 : 10 }}
              onClick={() => (searchText ? setSearchText("") : null)}
              src={searchText ? IconClose : IconSearch}
              alt="icons"
            />
          </div>
          <div
            className="scrollView"
            style={{ maxHeight: 255, overflow: "auto", border: "1px solid #AEBBC1", borderBottomRightRadius: 8, borderBottomLeftRadius: 8 }}
          >
            {data.map((item) => (
              <div
                key={item.id}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelectItem(item.id);
                }}
                style={{
                  padding: 10,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: select === item.id ? "#FEF2EC" : "transparent",
                }}
              >
                <div>{renderName(item)}</div>
                {select === item.id ? <img src={IconCheck} alt="ic_arrow_down" /> : null}
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};
