import React, { useEffect, useState } from "react";
import { CRow, CCol, CContainer, CButton, CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import NoDataTable from "components/NoDataTable";
import Pagination from "components/Pagination/Pagination";
import SearchBar from "screens/Customers/searchBarCustomer";
import TableRow from "./TableRow";
import ModalAssignEmployee from "./Modals/ModalAssignEmployee";
import SaleOrdersService from "services/SaleOrders";
import OrdersObj from "./types/IOrders";
import ModalUploadOrders from "./Modals/ModalUploadOrders";
import ModalUploadCompelete from "./Modals/ModalUploadCompelete";
import { useAppSelector } from "app/hooks";
import { selectDataDownloadFileOrders } from "features/business/businessSlices";
import { CustomMenuDropdown } from "./components/CustomMenuDropdown";

const employeeCol = {
  number: {
    label: "saleOrders.number",
    isShow: true,
  },
  quantity: {
    label: "saleOrders.quantity",
    isShow: true,
  },
  order_value: {
    label: "saleOrders.order_value",
    isShow: true,
  },
  assigned_employee: {
    label: "saleOrders.assigned_employee",
    isShow: true,
  },
  order_date: {
    label: "saleOrders.order_date",
    isShow: true,
  },
};

export interface IUserEmployee {
  id: number;
  user_id: number;
  vendor_id: number;
  status: number;
  user: { id: number; last_name: string; middle_name: string; first_name: string };
  first_name?: string;
  last_name?: string;
}

const SalesOrdersScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const vendor_id = localStorage.getItem("vendor_id");
  const [searchParams] = useSearchParams();
  const [searchData, setSearchData] = React.useState({ keyword: "" });
  const [col] = React.useState(employeeCol);
  const [isShowModalAssign, setIsShowModalAssign] = React.useState(false);
  const [checked, setChecked] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [orders, setOrders] = React.useState<any>();
  const [employee, setEmployee] = useState<IUserEmployee[]>([]);
  const [selectId, setSelectId] = useState(null);
  const [searchText, setSeachText] = useState("");
  const [errors, setErrors] = React.useState<any>();
  const [modalUpload, setModalUpload] = useState(false);
  const [modalUploadCompelete, setModalUploadCompelete] = useState(false);
  const dataUploadFile = useAppSelector(selectDataDownloadFileOrders);

  const tranferPageto = (page: string, limit: string) => {
    navigate({
      pathname: location.pathname,
      search: `?keyword=${searchParams.get("keyword") || ""}&page=${page}&limit=${limit}`,
    });
  };

  const listOrders = async () => {
    vendor_id &&
      SaleOrdersService.getListOrders(searchData.keyword, vendor_id, searchParams.get("page") || "")
        .then((res) => {
          setData(res.data.data.data);
          setOrders(res.data.data);
        })
        .catch((err) => console.log(err));
  };

  const handleAssignEmployee = async () => {
    const data = {
      order_ids: checked,
      employee_id: selectId,
    };
    await SaleOrdersService.updateAssignEmployee(data).then((res) => {
      if (!res.data.code) {
        listOrders();
        setIsShowModalAssign(false);
        setChecked([]);
        setErrors(null);
        setSelectId(null);
      } else {
        setErrors(res.data.messageObject.employee_id ? <div className="text-danger">{res.data.messageObject.employee_id}</div> : null);
      }
    });
  };

  useEffect(() => {
    listOrders();
  }, [searchData, searchParams]);

  useEffect(() => {
    isShowModalAssign &&
      SaleOrdersService.getListEmployeeOrders(vendor_id).then((res) => {
        if (!res.data.code) {
          setEmployee(res.data.data);
        } else {
          setEmployee([]);
        }
      });
  }, [isShowModalAssign]);

  // React.useEffect(() => {
  //   socket.on("payment_console", (dataSocket: any) => {
  //     console.log("dataSocket", dataSocket);
  //     if (vendor_id == dataSocket?.vendor_id) {
  //       listOrders();
  //     }
  //   });
  // }, []);

  React.useEffect(() => {
    if (dataUploadFile?.code === 0) {
      if (modalUpload === true) {
        setModalUpload(false);
        setModalUploadCompelete(true);
      } else {
        setModalUploadCompelete(false);
      }
    }
  }, [dataUploadFile]);

  useEffect(() => {
    dataUploadFile?.data?.total_push > 0 && listOrders();
  }, [dataUploadFile]);

  return (
    <CContainer fluid className="mt-3 p-0" style={{ padding: "0 13px" }}>
      <ModalUploadOrders onCancel={() => setModalUpload(false)} visible={modalUpload} />
      <ModalUploadCompelete
        onCancel={() => {
          setModalUploadCompelete(false);
        }}
        data={dataUploadFile?.data}
        visible={modalUploadCompelete}
      />
      <ModalAssignEmployee action={handleAssignEmployee} visible={isShowModalAssign} setVisible={setIsShowModalAssign}>
        <CustomMenuDropdown
          title="saleOrders.employee_name"
          placeholder="saleOrders.enter_employee_name"
          data={employee}
          select={selectId}
          setSelect={setSelectId}
          searchText={searchText}
          setSearchText={setSeachText}
        />
        {errors}
      </ModalAssignEmployee>
      <CRow>
        <CCol className="mb-4">
          <h2>{t("sidebar.sale")}</h2>
        </CCol>
        <CCol className="d-flex">
          <div className="ms-auto">
            <CButton onClick={() => setModalUpload(true)} className="ms-2" variant="outline" color="secondary">
              {t("saleOrders.upload_xlsx")}
            </CButton>
          </div>
          <div>
            <CButton
              onClick={() => navigate("/sale_orders/add-order")}
              className="ms-2"
              variant="outline"
              style={{ background: "#F26F21", color: "#fff", border: "1px solid #F26F21" }}
              color="secondary"
            >
              {t("saleOrders.add_order")}
            </CButton>
          </div>
        </CCol>
      </CRow>
      <div
        style={{
          background: "#F3F5F6",
          borderRadius: "8px",
          padding: 20,
          height: 110,
          marginBottom: 24,
        }}
      >
        <SearchBar title={"saleOrders.search_by_first_name_PO_number"} searchData={searchData} setSearchData={setSearchData} />
      </div>
      <CRow className="mb-4">
        <CCol className="d-flex">
          <div className="my-auto">
            <CButton
              disabled={checked.length === 0 ? true : false}
              onClick={() => setIsShowModalAssign(true)}
              className="ms-2"
              variant="outline"
              style={{ background: "#F26F21", color: "#fff", border: "1px solid #F26F21" }}
              color="secondary"
            >
              {t("saleOrders.assign_employee")}
            </CButton>
          </div>
        </CCol>
      </CRow>
      <div>
        <div className="table-wrapper">
          <CTable responsive="xl" align="middle">
            <CTableHead>
              <CTableRow className="py-4">
                <CTableHeaderCell scope="col"></CTableHeaderCell>
                <CTableHeaderCell scope="col">{t(col.number.label)}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t(col.quantity.label)}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t(col.order_value.label)}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t(col.assigned_employee.label)}</CTableHeaderCell>
                <CTableHeaderCell scope="col">{t(col.order_date.label)}</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {!!data.length && data.map((dt: any) => <TableRow key={dt.id} orders={new OrdersObj(dt)} checked={checked} setChecked={setChecked} />)}
            </CTableBody>
          </CTable>
          {!data?.length && <NoDataTable />}
          {!!data?.length && (
            <Pagination limit={orders?.per_page} totalItems={orders?.total} current_page={orders?.current_page} navigate={tranferPageto} />
          )}
        </div>
      </div>
    </CContainer>
  );
};

export default SalesOrdersScreen;
