import http from "utils/http";

class Business {
  businesType() {
    return http.get(`/portal/business-type-list`);
  }
  createBusinessProfile(data: any) {
    return http.post(`/portal/create-profile/step-1`, data);
  }
  createBusinessLocation(data: any) {
    return http.post(`/portal/create-profile/step-1`, data);
  }
  createBusinessStep2(data: any) {
    return http.post(`/portal/create-profile/step-2`, data);
  }
  createLocation(data: any) {
    return http.post(`/portal/location-detail`, data);
  }
  listLocation(vendor_id: any) {
    return http.get(`get-location-list?vendor_id=${vendor_id}`);
  }
  deleteLocation(vendor_id: any, user_id: any, vendor_location_id: any) {
    return http.delete(`/location-detail?vendor_id=${vendor_id}&user_id=${user_id}&vendor_location_id=${vendor_location_id}`);
  }
  detailLocation(vendor_id: any, vendor_location_id: any) {
    return http.get(`/location-detail?vendor_id=${vendor_id}&vendor_location_id=${vendor_location_id}`);
  }
  editLocation(data: any) {
    return http.put(`/portal/location-detail`, data);
  }
  customerList(vendor_id: any, page: number, key: string) {
    return http.get(`/portal/customer/list?vendor_id=${vendor_id}&page=${page}&keyword=${key}`);
  }
  customerAdd(data: any) {
    return http.post(`/portal/customer/create`, data);
  }
  customerUpdate(data: any) {
    return http.put(`/portal/customer/update`, data);
  }
  businessProfileType() {
    return http.get(`/business-profile-type`);
  }
  customerDetail(vendor_id: any, customer_id: any) {
    return http.get(`/portal/customer/detail?vendor_id=${vendor_id}&customer_id=${customer_id}`);
  }
  uploadFileCustomer(data: any) {
    return http.post(`/portal/customer/upload-customer-list`, data);
  }
  downloadFile(headings: any) {
    return http.get(`/portal/customer/download-sample-file?headings=${headings}`);
  }
  downloadErrFile(data: any) {
    return http.post(`/portal/customer/download-error-list`, data);
  }
  getUserByPhone(mobile_phone: any, my_mobile_phone: any) {
    return http.get(`/portal/customer/get-user-by-phone?my_mobile_phone=${my_mobile_phone}&mobile_phone=${mobile_phone}`);
  }
}

export default new Business();
