import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Authentication from "../../services/Authentication";
const sliceName = "register";
const sliceState = (state) => state.register;
const initialState = {
  dataResendOTP: [],
  dataValidate: [],
  dataRegister: [],
  listProvinces: [],
  listDistricts: [],
  listWards: [],
};

//Post register
export const SendOTPRegister = createAsyncThunk(
  sliceName + "/SendOTP",
  // eslint-disable-next-line consistent-return
  async (data) => {
    try {
      const response = await Authentication.sendCodeOTP(data);
      if (response.data.code === 2) {
        return response.data.messageObject;
      }

      localStorage.setItem("user", JSON.stringify(response.data.data));
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);
export const registerStep = createAsyncThunk(
  sliceName + "registerStep",
  // eslint-disable-next-line consistent-return
  async (data) => {
    try {
      const response = await Authentication.registerStep1(data);
      if (response.data.code === 2) {
        return response.data.messageObject;
      } else {
        return response.data;
      }
    } catch (err) {
      console.log("err", err);
    }
  }
);
export const getProvinces = createAsyncThunk(
  sliceName + "getProvinces",
  // eslint-disable-next-line consistent-return
  async () => {
    try {
      const response = await Authentication.provinces();
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);
export const getDistricts = createAsyncThunk(
  sliceName + "getDistricts",
  // eslint-disable-next-line consistent-return
  async (city_id) => {
    try {
      const response = await Authentication.district(city_id);
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);

export const getWards = createAsyncThunk(
  sliceName + "getWards",
  // eslint-disable-next-line consistent-return
  async (district_id) => {
    try {
      const response = await Authentication.wards(district_id);
      return response.data;
    } catch (err) {
      console.log("err", err);
    }
  }
);

//CreateSlice
export const rigisterSlice = createSlice({
  name: sliceName,
  initialState,
  //ExtraReducer
  extraReducers: (builder) => {
    builder.addCase(SendOTPRegister.pending, (state) => {
      // state.isLoadingResult = true;
    });
    builder.addCase(SendOTPRegister.fulfilled, (state, action) => {
      localStorage.setItem("token", action.payload.data?.access_token);
      action.payload.data ? (state.dataResendOTP = action.payload.data) : (state.dataResendOTP = action.payload);
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(SendOTPRegister.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
    builder.addCase(registerStep.pending, (state) => {
      // state.isLoadingResult = true;
      console.log("💀💀pending!");
    });
    builder.addCase(registerStep.fulfilled, (state, action) => {
      action.payload.data ? (state.dataRegister = action.payload.data) : (state.dataValidate = action.payload);
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(registerStep.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
    builder.addCase(getProvinces.pending, (state) => {
      // state.isLoadingResult = true;
      console.log("💀💀pending!");
    });
    builder.addCase(getProvinces.fulfilled, (state, action) => {
      state.listProvinces = action.payload.data;
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(getProvinces.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
    builder.addCase(getDistricts.pending, (state) => {
      // state.isLoadingResult = true;
      console.log("💀💀pending!");
    });
    builder.addCase(getDistricts.fulfilled, (state, action) => {
      state.listDistricts = action.payload.data;
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(getDistricts.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
    builder.addCase(getWards.pending, (state) => {
      // state.isLoadingResult = true;
      console.log("💀💀pending!");
    });
    builder.addCase(getWards.fulfilled, (state, action) => {
      state.listWards = action.payload.data;
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    builder.addCase(getWards.rejected, (state) => {
      console.log("💀💀Submit result to server fail!");
    });
  },
});

// //action
// export const {

//   } = appSlice.actions;

//slices
export const selectDataRegister = (state) => sliceState(state).dataRegister;
export const selectDataSendOTP = (state) => sliceState(state).dataResendOTP;
export const SelectValidate = (state) => sliceState(state).dataValidate;
export const SelectProvinces = (state) => sliceState(state).listProvinces;
export const SelectDistricts = (state) => sliceState(state).listDistricts;
export const SelectWards = (state) => sliceState(state).listWards;

export default rigisterSlice.reducer;
