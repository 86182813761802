import React, { useEffect, useState } from "react";
import { CButton, CCol, CFormInput, CFormLabel, CFormSelect, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow } from "@coreui/react";
import { AssignRoleModalState, ModalState } from "./modalData";
import { useTranslation } from "react-i18next";
import EmployeesService from "services/Employee/EmployeesService";

interface propTypes {
  modals: ModalState;
  state: AssignRoleModalState;
  setModals: any;
  setCheckedIDs: React.Dispatch<React.SetStateAction<string[]>>;
  getData: () => void;
}

const AssignRoleModal = (props: propTypes) => {
  const [roleList, setRoleList] = React.useState([]);
  const vendor_id = localStorage.getItem("vendor_id");
  const { state, modals, setModals, setCheckedIDs, getData } = props;
  const { t } = useTranslation();
  const [data, setData] = useState({ role_id: null, effective_date: null });
  const [errors, setErrors] = useState({ effective_date: [""], role_id: [""] });

  const handleChange = (key: string, name: string) => {
    setData((prev: any) => {
      return { ...prev, [key]: name === "" ? null : name };
    });
  };

  const handleAssignRoleClose = () => {
    const assignModalRole: AssignRoleModalState = {
      ...state,
      visible: false,
    };
    setModals((prev: ModalState) => ({ ...prev, assignModalRole }));
  };

  useEffect(() => {
    EmployeesService.getRole(vendor_id).then((res) => setRoleList(res.data.data));
  }, []);

  const handleAssignEmployee = () => {
    EmployeesService.assignEmployee({
      vendor_id: vendor_id,
      employee_ids: modals.employeeSelected ?? null,
      role_id: data.role_id ?? null,
      effective_date: data.effective_date ?? null,
    }).then((res) => {
      if (!res.data.code) {
        handleAssignRoleClose();
        setCheckedIDs([]);
        getData();
        setData({ effective_date: null, role_id: null });
      } else {
        setErrors(res.data.messageObject);
      }
    });
  };

  return (
    <>
      <CModal alignment="center" onClose={handleAssignRoleClose} visible={state.visible}>
        <CModalHeader className="border-0">
          <CModalTitle>
            <div className="fs-5">{t("employee.action.assign_role")}</div>
            <span className="modal-header-description text-muted">
              {t("employee.modal.select_role_header_description")}
              <span className="text-dark">
                {" "}
                {modals.employeeSelected?.length || 0} {t("employee.employees")}
              </span>
            </span>
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CCol>
            <CFormLabel className="filter-label" htmlFor="modal-select-role">
              {t("employee.modal.select_role")} *
            </CFormLabel>
            <CFormSelect
              name="role_id"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              className="filter-form-select"
              type="text"
              id="modal-select-role"
              aria-describedby="filter role"
              value={data.role_id}
            >
              <option value="" disabled={false} selected={true}>
                {t("employee.modal.role")}
              </option>
              {roleList?.map((item: any, idx) => (
                <option key={idx} value={item.id}>
                  {item.role_name}
                </option>
              ))}
            </CFormSelect>
            <div className="text-danger">{errors.role_id ? errors.role_id[0] : ""}</div>
          </CCol>
          <CCol className="mt-4">
            <CFormLabel className="filter-label" htmlFor="modal-effective-date">
              {t("employee.modal.effective_date")}
            </CFormLabel>
            <CFormInput
              name="effective_date"
              value={data.effective_date}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              className="filter-form-select"
              type="date"
              id="modal-effective-date"
              aria-describedby="filter role"
            ></CFormInput>
            <div className="text-danger">{errors.effective_date ? errors.effective_date[0] : ""}</div>
          </CCol>
        </CModalBody>
        <CModalFooter>
          <CRow className="w-100">
            <CCol>
              <CButton variant="outline" className="w-100" color="secondary" onClick={handleAssignRoleClose}>
                {t("common.close")}
              </CButton>
            </CCol>
            <CCol>
              <CButton onClick={handleAssignEmployee} className="w-100" color="primary">
                {t("common.save")}
              </CButton>
            </CCol>
          </CRow>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default AssignRoleModal;
