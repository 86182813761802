import React from "react";
import { CButton, CForm } from "@coreui/react";
import OtpInput from "react-otp-input";
import Footer from "components/Footer/footer";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useAppSelector } from "app/hooks";
import { selectDataSendOTP, SendOTPRegister, selectDataRegister } from "features/register/registerSlices";
import { onForgotPasswordStep1, selectForgotPassword1, selectDataLogin } from "features/login/loginSlices";

const VerificationScreen = () => {
  const location = useLocation();
  const dataSendOTP = useAppSelector(selectDataSendOTP);
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const dataForgotpassword = useAppSelector(selectForgotPassword1);
  const dataRegister = useAppSelector(selectDataRegister);
  const dataLogin = useAppSelector(selectDataLogin);
  const mobile_phone = params.get("mobile_phone");
  const type = params.get("type");
  const navigate = useNavigate();
  const [time, setTime] = React.useState(60);
  const [reSendOTP, setResendOTP] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const { t } = useTranslation();

  React.useEffect(() => {
    let timeleft = 60;
    if (reSendOTP === false) {
      const downloadTimer = setInterval(function () {
        timeleft--;
        setTime(timeleft);
        if (timeleft <= 0) clearInterval(downloadTimer);
      }, 1000);
    }
  }, [reSendOTP]);
  React.useEffect(() => {
    if (time === 0) {
      setResendOTP(true);
    }
  }, [time]);

  React.useEffect(() => {
    if (!mobile_phone) {
      navigate("/sign-in");
    }
  }, [dataRegister, dataLogin]);
  React.useEffect(() => {
    if (dataForgotpassword?.code === 0) {
      if (type === "changepass") {
        navigate(`/sign-in/change-password?mobile_phone=${mobile_phone}`);
        localStorage.setItem("otp_code", JSON.stringify(otp));
      }
    }
    if (dataSendOTP?.user_id) {
      navigate("/profile-list");
    }
    localStorage.setItem("user_id", dataSendOTP?.user_id);
  }, [dataSendOTP, dataForgotpassword]);
  const onReSendOTP = async () => {
    setResendOTP(false);
    setTime(60);
  };

  const sendOTP = async (e: any) => {
    e.preventDefault();
    const data = { otp_code: otp, mobile_phone };
    if (type === "changepass") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      dispatch(onForgotPasswordStep1(data));
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      dispatch(SendOTPRegister(data));
    }
  };
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div className="d-flex pt-5" style={{ marginLeft: 44 }}>
        <img src={require("../../assets/images/logo.png")} width={40} height={40} />
        <h6 className="my-1" style={{ marginLeft: 5 }}>
          MyBiz Business Portal
        </h6>
      </div>
      <div>
        <div className="d-flex row">
          <h2 className="text-center">{t("registration.otp")}</h2>
          <p className="text-center my-3">{t("registration.otp_send-phone")}</p>
          <b className="text-center">{mobile_phone}</b>
        </div>
        <CForm onSubmit={sendOTP} className="mx-auto" style={{ maxWidth: 360, marginTop: 32 }}>
          <OtpInput
            containerStyle={{ width: 290 }}
            inputStyle={{ width: 78, height: 64, border: "1px solid #AEBBC1", borderRadius: 8, marginRight: 16 }}
            value={otp}
            onChange={(otp: string) => {
              setOtp(otp);
            }}
            numInputs={4}
            shouldAutoFocus={true}
            isInputNum={true}
          />
          <div style={{ color: "red", textAlign: "center", width: "100%" }}>{dataSendOTP?.otp_code || dataForgotpassword?.otp_code}</div>
          {reSendOTP ? (
            <p className="mt-2" style={{ textAlign: "center", cursor: "pointer" }}>
              {t("registration.not_received_an_otp")}{" "}
              <span onClick={onReSendOTP} className="fw-bold">
                {" "}
                {t("registration.resend_new_otp")}{" "}
              </span>
            </p>
          ) : (
            <p className="mt-2" style={{ textAlign: "center" }}>
              {t("registration.please_wait_for")} <span className="fw-bold"> {time}s </span>
              {t("registration.before_requesting_another_otp")}
            </p>
          )}

          <div className="d-grid gap-2 mt-3">
            <CButton color="primary" type="submit">
              {t("registration.next")}
              {location.pathname === "/sign-in/verification" ? null : " 2/4"}
            </CButton>
          </div>
          <p
            onClick={() => navigate("/sign-in")}
            className="text-center"
            style={{ marginTop: 24, color: "#059ED7", cursor: "pointer", fontWeight: 500 }}
          >
            {t("registration.sign_in")}
          </p>
        </CForm>
      </div>
      <Footer />
    </div>
  );
};
export default VerificationScreen;
