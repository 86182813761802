import React from 'react';
import { CAlert, CButton } from '@coreui/react';
import { useTranslation } from "react-i18next";
import ModalBusiness from 'components/Modals/ModalBusiness';
import Footer from 'components/Footer/footer';
import { useNavigate } from "react-router-dom";
import { selectListLocation, getListLocation, selectDeleteLocation } from 'features/business/businessSlices';
import { useAppSelector } from 'app/hooks';
import { useDispatch } from 'react-redux';
import { selectLocation, completeLocation, selectUpdateLocation } from 'features/business/businessSlices';
import { IModalAddLocation } from './types/IModalAddLocation';
import ModalAddLocation from './modals/ModalAddLocation';
import { IModalEditLocation } from './types/IModalEditLocation';
import ModalEditLocation from './modals/ModalEditLocation';
import Business from 'services/Business/Business';

const ListBusinessLocationScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const listLocation = useAppSelector(selectListLocation);
    const dataLocation = useAppSelector(selectLocation);
    const deletelocat = useAppSelector(selectDeleteLocation);
    const dataUpdate = useAppSelector(selectUpdateLocation);
    const vendor_id = localStorage.getItem('vendor_id');
    const user_id = localStorage.getItem('user_id');
    const [modalEdit, setModalEdit] = React.useState<IModalEditLocation>()
    const [errs, setErrs] = React.useState<any>();
    const [modalAdd, setModalAdd] = React.useState<IModalAddLocation>({});

    const handleAddModal = () => {
        setModalAdd({
            show: true,
            address: '',
            city_id: '',
            district_id: '',
            is_default: false,
            is_warehouse: false,
            vendor_location_name: '',
            ward_id: '',
            setModalAdd
        })
    }

    React.useEffect(() => {

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch(getListLocation(vendor_id));
    }, [deletelocat, dataLocation, dataUpdate, modalAdd?.show, modalEdit?.show]);

    const onCompelete = () => {
        const data = {
            vendor_id: vendor_id,
            user_id: user_id,
        };

        Business.createBusinessStep2(data).then(res => {
            if (!res.data.code) {
                console.log(1);
                navigate('/')
            }
            else {
                setErrs(res.data.messageObject.error_message[0])
                setTimeout(()=> setErrs(""), 5000)
            }
        })

    };

    return (
        <>
            <div style={{
                height: "100%",
                display: "flex",
                flexDirection: "column"
            }}>
                <div className="d-flex pt-5" style={{ marginLeft: 44 }}>
                    <img src={require('../../assets/images/logo.png')} width={40} height={40}/>
                    <h6 className="my-1" style={{ marginLeft: 5 }}>MyBiz Business Portal</h6>
                </div>

                <div>
                    <h2 className="text-center">{t('registration.business_locations')}</h2>

                    <div className="mx-auto" style={{ maxWidth: 360, marginTop: 32 }}>

                        {listLocation?.map((item: any, key: number) => (
                            <ModalBusiness setModalEdit={setModalEdit} vendor_location_id={item?.id} isDefault={item?.is_default} locationNumber={key + 1} key={key} address={item?.address} ward={item?.ward?.name} locationName={item?.vendor_location_name} district={item?.district?.name} city={item?.city?.name} />
                        ))}
                        <button onClick={handleAddModal} className="btn text-info w-100 outline-none">{t("registration.add_new_location")}</button>
                        <div className="d-grid gap-2 mt-3">
                            <CButton onClick={onCompelete} color="primary" type="submit">
                                {t('registration.complete_registration')} (4/4)
                            </CButton>
                        </div>
                        <CAlert className='mt-2' color="danger" visible={!!errs} onClose={() => setErrs("")} dismissible>{errs}</CAlert>
                    </div>
                </div>
                <Footer />
            </div>
            <ModalAddLocation {...modalAdd} setModalAdd={setModalAdd} />
            <ModalEditLocation {...modalEdit} setModalEdit={setModalEdit} />
        </>
    );
};
export default ListBusinessLocationScreen;