import React, { useEffect } from "react";
import {
  CBreadcrumb,
  CBreadcrumbItem,
  CButton,
  CCol,
  CContainer,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CRow,
} from "@coreui/react";
import { useTranslation } from "react-i18next";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useAppSelector } from "app/hooks";
import {
  getDistricts,
  getProvinces,
  getWards,
  SelectDistricts,
  SelectProvinces,
  SelectWards,
} from "features/register/registerSlices";
import { formatYYYYMMDD } from "utils/function";
import { useDispatch } from "react-redux";
import EmployeesService from "services/Employee/EmployeesService";
import InputDragFile from "./components/InputDragFile";

interface initData {
  business_id: string;
  joining_date: null | Date;
  main_store: string;
  business_location_base: string;
  role_id: string;
  role: string;
  create_employee_cash_book: false;
  first_name: string;
  middle_name: string;
  last_name: string;
  mobile_number: string;
  email_address: string;
  city: string;
  district: string;
  ward: string;
  address_line: string;
  DOB: null | Date;
  employee_NID: string;
  date_of_issue: null | Date;
  expiry_date: null | Date;
  place_of_issue: string;
  resign_this_employee: boolean;
  resigned_date: null | Date;
  resignation_path: string;
  release_request_date: null | Date;
  request_approval_date: null | Date;
  resign_file: any;
  employee_id: string;
  vendor_location_id: string;
}

const initData = (): initData => {
  return {
    business_id: "",
    joining_date: null,
    main_store: "",
    business_location_base: "",
    role_id: "",
    role: "",
    create_employee_cash_book: false,
    first_name: "",
    middle_name: "",
    last_name: "",
    mobile_number: "",
    email_address: "",
    city: "",
    district: "",
    ward: "",
    address_line: "",
    DOB: null,
    employee_NID: "",
    date_of_issue: null,
    expiry_date: null,
    place_of_issue: "",
    resign_this_employee: false,
    resigned_date: null,
    resignation_path: "",
    release_request_date: null,
    request_approval_date: null,
    resign_file: null,
    employee_id: "",
    vendor_location_id: "",
  };
};

const EditEmployeeScreen = () => {
  const { t } = useTranslation();
  const [data, setData] = React.useState(initData());
  const listProvinces = useAppSelector(SelectProvinces);
  const vendor_id = localStorage.getItem("vendor_id");
  const listDistricts = useAppSelector(SelectDistricts);
  const listWards = useAppSelector(SelectWards);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: employee_id } = useParams();
  const [locations, setLocations] = React.useState<any>();
  const [erros, setErros] = React.useState<any>();
  console.log("erros",erros)
  const [resigned, setResigned] = React.useState(false);
  const [roleList, setRoleList] = React.useState([]);

  const handleDataChange = (field: string, value: any) => {
    console.log(field, value)
    setData((prev) => ({ ...prev, [field]: value }));
  };
  const { state } = useLocation();



  const handleChangeAddress = React.useCallback(
    (field: string, value: any) => {
      if (field === "city") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch(getDistricts(value));
        handleDataChange("city", value);
        handleDataChange("district", "");
        handleDataChange("ward", "");
      } else if (field === "district") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch(getWards(value));
        handleDataChange("district", value);
      } else {
        handleDataChange("ward", value);
      }
    },
    [data.city, data.district, data.ward, handleDataChange]
  );
  const getLocation = () => {
    EmployeesService.getLocationList().then((res) => {
      setLocations(res.data.data);
    });
  };
  React.useEffect(() => {
    getLocation();
  }, []);

  const handleSave = () => {
    // console.log(data.employee_NID);
    // return;
    const postData = {
      employee_id: employee_id,
      user_id: localStorage.getItem("user_id"),
      vendor_id: localStorage.getItem("vendor_id"),
      vendor_location_id: data.vendor_location_id,
      mobile_phone: data.mobile_number,
      first_name: data.first_name,
      last_name: data.last_name,
      middle_name: data.middle_name,
      email: data.email_address,
      date_of_birth: formatYYYYMMDD(data.DOB),
      city_id: data.city,
      district_id: data.district,
      ward_id: data.ward,
      address: data.address_line,
      nid: data.employee_NID,
      date_of_issue: formatYYYYMMDD(data.date_of_issue),
      expiry_date: formatYYYYMMDD(data.expiry_date),
      place_of_issue: data.place_of_issue,
      join_date: formatYYYYMMDD(data.joining_date),
      role_id: data.role_id,
      add_cash_book: 1,
      resigned: data.resign_this_employee ? 1 : 0,
      release_request_date: formatYYYYMMDD(data.resigned_date),
    };

    console.log(postData);

    EmployeesService.edit(postData)
      .then((res) => {
        console.log(res);
        if (!res.data.code) {
          navigate("/employee_management/employees", { state: { beforeTab: state?.beforeTab } });
        } else {
          console.log("!23")
          setErros(res.data.messageObject);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    employee_id &&
      EmployeesService.getById(employee_id)
        .then((res) => {
          const resdata = res.data.data;
          setData((pre) => ({
            ...pre,
            mobile_number: resdata.mobile_phone,
            first_name: resdata.first_name,
            last_name: resdata.last_name,
            middle_name: resdata.middle_name,
            role_id: resdata.role_id,
            vendor_location_id: resdata.vendor_location_id,
            joining_date: resdata?.join_date && new Date(resdata.join_date),
            expiry_date: resdata?.expiry_date && new Date(resdata.expiry_date),
            date_of_issue:
              resdata?.date_of_issue && new Date(resdata.date_of_issue),
            DOB: resdata?.date_of_birth && new Date(resdata.date_of_birth),
            city: resdata.city_id,
            district: resdata.district_id,
            ward: resdata.ward_id,
            place_of_issue: resdata.place_of_issue,
            employee_NID: resdata.id_number,
            address_line: resdata.address,
            email_address: resdata.email,
            employee_id: resdata.employee_number,
            create_employee_cash_book: resdata.cashbook,
            resign_this_employee: !!resdata?.resigned_date,
            resigned_date:
              resdata?.resigned_date && new Date(resdata.resigned_date),
            release_request_date:
              resdata.release_request_date &&
              new Date(resdata.release_request_date),
          }));
          setResigned(!!resdata?.resigned_date);

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          dispatch(getProvinces());
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          resdata.city_id && dispatch(getDistricts(resdata.city_id));
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          resdata.district_id && dispatch(getWards(resdata.district_id));
        })
        .catch((err) => {
          console.log(err);
        });
    EmployeesService.getLocationList().then((res) => {
      console.log(res.data);
    })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  
  useEffect(() => {
    EmployeesService.getRole(vendor_id).then(res => setRoleList(res.data.data));
  },[]);


  return (
    <CContainer fluid>
      <CBreadcrumb>
        <CBreadcrumbItem>
          <Link
            className="breadcrumb-link text-secondary"
            to="/employee_management/employees"
          >
            {t("employee.employees")}
          </Link>
        </CBreadcrumbItem>
        <CBreadcrumbItem className="text-primary" active>
          {t("employee.edit_employee")}
        </CBreadcrumbItem>
      </CBreadcrumb>
      <h3>{t("employee.edit_employee")}</h3>
      <CRow>
        <CCol className="mb-2" sm={12} md={3}>
          <h5 className="fs-5">{t("employee.business_information")}</h5>
        </CCol>
        <CCol className="mb-2" sm={12} md={9}>
          <CRow>
            <CCol className="mb-2" sm={12} md={4} xl={4}>
              <CFormInput
                type="text"
                placeholder="EC125"
                name="employee_id"
                value={data.employee_id}
                onChange={(e) =>
                  handleDataChange(e.target.name, e.target.value)
                }
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol className="mb-2" sm={12} md={4} xl={4}>
              <CFormLabel
                className="mb-1 pt-2"
                style={{ fontWeight: 600 }}
                htmlFor="joining_date"
              >
                {t("employee.joining_date")} *
              </CFormLabel>
              <DatePicker
                dateFormat={"dd/MM/yy"}
                id="joining_date"
                className="form-control"
                name="joining_date"
                selected={data.joining_date}
                onChange={(date: Date) =>
                  handleDataChange("joining_date", date)
                }
                placeholderText={t("employee.joining_date")}
              />
              {erros?.join_date && (
                <div className="text-danger">{erros.join_date}</div>
              )}
            </CCol>
          </CRow>
          <CRow>
            <CCol className="mb-2" sm={12} md={4} xl={4}>
              <CFormLabel
                className="mb-1 pt-2"
                style={{ fontWeight: 600 }}
                htmlFor="business_location_base"
              >
                {t("employee.business_location_base")} *
              </CFormLabel>
              <CFormSelect
                id="business_location_base"
                name="vendor_location_id"
                aria-describedby={t("employee.business_location_base")}
                value={data.vendor_location_id}
                placeholder={t("employee.business_location_base")}
                onChange={(e) =>
                  handleDataChange(e.target.name, e.target.value)
                }
              >
                <option value="">{t("employee.business_location_base")}</option>
                {locations?.map((item: any) => (
                  <option
                    key={item.vendor_location_id}
                    value={item.vendor_location_id}
                  >
                    {item.vendor_location_name}
                  </option>
                ))}
              </CFormSelect>
              {erros?.vendor_location_id && (
                <div className="text-danger">{erros.vendor_location_id}</div>
              )}
            </CCol>
          </CRow>
          <CRow>
            <CCol className="mb-2" sm={12} md={4} xl={4}>
              <CFormLabel
                className="mb-1 pt-2"
                style={{ fontWeight: 600 }}
                htmlFor="select_role"
              >
                {t("employee.role")} *
              </CFormLabel>
              <CFormSelect
                type="text"
                id="select_role"
                name="role_id"
                value={data.role_id || ""}
                onChange={(e) =>
                  handleDataChange(e.target.name, e.target.value)
                }
                placeholder={t("employee.select_role")}
                aria-describedby={t("employee.select_role")}
              >
               <option value="">{t("employee.select_role")}</option>

              {roleList?.map((item: any, idx) => (
                  <option
                      key={idx}
                      value={item.id}
                  >
                      {item.role_name}
                  </option>
              ))}
              </CFormSelect>
            </CCol>
          </CRow>
          {/* <Link className="text-primary text-decoration-none" to={"/"}>
            {t("employee.add_new_role")}
          </Link> */}
          <br />
          {/* <CCol className="d-flex py-2">
            <CFormCheck
              id="create_employee_cash_book"
              type="checkbox"
              name="create_employee_cash_book"
              checked={data.create_employee_cash_book}
              onChange={(e) =>
                handleDataChange(e.target.name, e.target.checked)
              }
            />{" "}
            &nbsp;&nbsp;
            <CFormLabel
              className="mb-0"
              style={{ fontWeight: 600 }}
              htmlFor="create_employee_cash_book"
            >
              {t("employee.create_employee_cash_book")}
            </CFormLabel>
          </CCol> */}
        </CCol>
      </CRow>
      <hr />
      <CRow>
        <CCol className="mb-2" sm={12} md={3}>
          <h5 className="fs-5">
            {t("employee.employee_personal_information")}
          </h5>
        </CCol>
        <CCol className="mb-2" sm={12} md={9}>
          <CRow>
            <CCol className="mb-2" sm={12} md={4} xl={4}>
              <CFormLabel
                className="mb-1 pt-2"
                style={{ fontWeight: 600 }}
                htmlFor="first_name"
              >
                {t("employee.first_name")} *
              </CFormLabel>
              <CFormInput
                type="text"
                id="first_name"
                aria-describedby={t("employee.first_name")}
                value={data.first_name}
                name="first_name"
                onChange={(e) =>
                  handleDataChange(e.target.name, e.target.value)
                }
              />
              {erros?.first_name && (
                <div className="text-danger">{erros.first_name}</div>
              )}
            </CCol>
            <CCol className="mb-2" sm={12} md={4} xl={4}>
              <CFormLabel
                className="mb-1 pt-2"
                style={{ fontWeight: 600 }}
                htmlFor="middle_name"
              >
                {t("employee.middle_name")}
              </CFormLabel>
              <CFormInput
                type="text"
                id="middle_name"
                aria-describedby={t("employee.middle_name")}
                value={data.middle_name}
                name="middle_name"
                onChange={(e) =>
                  handleDataChange(e.target.name, e.target.value)
                }
              />
              {erros?.middle_name && (
                <div className="text-danger">{erros.middle_name}</div>
              )}
            </CCol>
          </CRow>
          <CCol className="mb-2" sm={12} md={4} xl={4}>
            <CFormLabel
              className="mb-1 pt-2"
              style={{ fontWeight: 600 }}
              htmlFor="last_name"
            >
              {t("employee.last_name")} *
            </CFormLabel>
            <CFormInput
              type="text"
              id="last_name"
              aria-describedby={t("employee.last_name")}
              value={data.last_name}
              name="last_name"
              onChange={(e) =>
                handleDataChange(e.target.name, e.target.value)
              }
            />
            {erros?.last_name && (
              <div className="text-danger">{erros.last_name}</div>
            )}
          </CCol>

          <CRow>
            <CCol className="mb-2" sm={12} md={4} xl={4}>
              <CFormLabel
                className="mb-1 pt-2"
                style={{ fontWeight: 600 }}
                htmlFor="mobile_number"
              >
                {t("employee.mobile_number")} *
              </CFormLabel>
              <CFormInput
                disabled
                type="number"
                id="mobile_number"
                name="mobile_number"
                aria-describedby={t("employee.mobile_number")}
                value={data.mobile_number}
                onChange={(e) =>
                  handleDataChange(e.target.name, e.target.value)
                }
              />
              {erros?.middle_name && (
                <div className="text-danger">{erros.mobile_phone}</div>
              )}
            </CCol>
          </CRow>
          <CRow>
            <CCol className="mb-2" sm={12} md={4} xl={4}>
              <CFormLabel
                className="mb-1 pt-2"
                style={{ fontWeight: 600 }}
                htmlFor="email_address"
              >
                {t("employee.email_address")}
              </CFormLabel>
              <CFormInput
                type="text"
                id="email_address"
                aria-describedby={t("employee.email_address")}
                name="email_address"
                value={data.email_address}
                onChange={(e) =>
                  handleDataChange(e.target.name, e.target.value)
                }
              />
              {erros?.email && <div className="text-danger">{erros.email}</div>}
            </CCol>
          </CRow>

          <CRow>
            <CCol className="mb-2" sm={12} md={4} xl={4}>
              <CFormLabel
                className="mb-1 pt-2"
                style={{ fontWeight: 600 }}
                // value={data.address_line}
                htmlFor="address"
              >
                {t("employee.address")}{" "}
              </CFormLabel>
            </CCol>
          </CRow>
          <CRow className="pb-sm-0 pb-md-2">
            <CCol className="mb-2" sm={12} md={4} xl={4}>
              <CFormSelect
                id="city"
                name="city"
                value={data.city || ""}
                placeholder={t("employee.city")}
                aria-describedby={t("employee.city")}
                onChange={(e) =>
                  handleChangeAddress(e.target.name, e.target.value)
                }
              >
                <option value="">{t("employee.city")}</option>
                {listProvinces?.map((item: any) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </CFormSelect>
              {erros?.city_id && <div className="text-danger">{erros.city_id}</div>}
            </CCol>
            <CCol className="mb-2" sm={12} md={4} xl={4}>
              <CFormSelect
                id="district"
                name="district"
                value={data.district || ""}
                placeholder={t("employee.district")}
                options={
                  data.city
                    ? listDistricts?.map((item: any) => ({
                      value: item.id,
                      label: item.name,
                    }))
                    : [{ value: "", label: t("employee.district") }]
                }
                aria-describedby={t("employee.district")}
                onChange={(e) =>
                  handleChangeAddress(e.target.name, e.target.value)
                }
              />
              {erros?.district_id && <div className="text-danger">{erros.district_id}</div>}
            </CCol>
            <CCol className="mb-2" sm={12} md={4} xl={4}>
              <CFormSelect
                id="ward"
                name="ward"
                value={data.ward || ""}
                placeholder={t("employee.ward")}
                aria-describedby={t("employee.ward")}
                options={
                  data.district
                    ? listWards?.map((item: any) => ({
                      value: item.id,
                      label: item.name,
                    }))
                    : [{ value: "", label: t("employee.ward") }]
                }
                onChange={(e) =>
                  handleDataChange(e.target.name, e.target.value)
                }
              ></CFormSelect>
              {erros?.ward_id && (
                <div className="text-danger">{erros.ward_id}</div>
              )}
            </CCol>
          </CRow>
          <CRow>
            <CCol className="mb-2">
              <CFormInput
                type="text"
                id="address_line"
                name="address_line"
                placeholder={t("employee.address_line")}
                aria-describedby={t("employee.address_line")}
                value={data.address_line}
                onChange={(e) =>
                  handleDataChange(e.target.name, e.target.value)
                }
              />
              {erros?.address && (
                <div className="text-danger">{erros.address}</div>
              )}
            </CCol>

          </CRow>
          <CCol className="mb-2" sm={12} md={4} xl={4}>
            <CFormLabel
              className="mb-1 pt-2"
              style={{ fontWeight: 600 }}
              htmlFor="DOB"
            >
              {t("employee.DOB")}{" "}
            </CFormLabel>
            <DatePicker
              id="DOB"
              name="DOB"
              className="form-control"
              selected={data?.DOB || null}
              dateFormat={"dd/MM/yy"}
              placeholderText={t("employee.DOB")}
              onChange={(date: Date) => {
                handleDataChange("DOB", date);
              }}
            />
            {erros?.date_of_birth && (
              <div className="text-danger">{erros.date_of_birth}</div>
            )}
          </CCol>
          <CCol className="mb-2" sm={12} md={4} xl={4}>
            <CFormLabel
              className="mb-1 pt-2"
              style={{ fontWeight: 600 }}
              htmlFor="employee_NID"
            >
              {t("employee.employee_NID")}
            </CFormLabel>
          </CCol>
          <CRow className="pb-sm-0 pb-md-2">
            <CCol className="mb-2" sm={12} md={4} xl={4}>
              <CFormInput
                type="text"
                id="employee_NID"
                value={data.employee_NID}
                name="employee_NID"
                placeholder={t("employee.id_number")}
                aria-describedby={t("employee.id_number")}
                onChange={(e) =>
                  handleDataChange(e.target.name, e.target.value)
                }
              />
              {erros?.nid && <div className="text-danger">{erros.nid}</div>}
            </CCol>
            <CCol className="mb-2" sm={12} md={4} xl={4}>
              <DatePicker
                dateFormat={"dd/MM/yy"}
                id="date_of_issue"
                name="date_of_issue"
                className="form-control"
                selected={data?.date_of_issue || null}
                placeholderText={t("employee.date_of_issue")}
                onChange={(date: Date) => {
                  handleDataChange("date_of_issue", date);
                }}
              />
              {erros?.date_of_issue && (
                <div className="text-danger">{erros.date_of_issue}</div>
              )}
            </CCol>

            <CCol className="mb-2" sm={12} md={4} xl={4}>
              <DatePicker
                dateFormat={"dd/MM/yy"}
                id="expiry_date"
                name="expiry_date"
                className="form-control"
                selected={data?.expiry_date || null}
                placeholderText={t("employee.expiry_date")}
                onChange={(date: Date) => {
                  handleDataChange("expiry_date", date);
                }}
              />
              {erros?.expiry_date && (
                <div className="text-danger">{erros.expiry_date}</div>
              )}
            </CCol>
          </CRow>
          <CRow>
            <CCol className="mb-2">
              <CFormInput
                type="text"
                id="place_of_issue"
                name="place_of_issue"
                value={data?.place_of_issue || ""}
                placeholder={t("employee.place_of_issue")}
                aria-describedby={t("employee.address_line")}
                onChange={(e) =>
                  handleDataChange(e.target.name, e.target.value)
                }
              />
            </CCol>
            {erros?.place_of_issue && (
              <div className="text-danger">{erros.place_of_issue}</div>
            )}
          </CRow>
        </CCol>
      </CRow>
      <hr className="mb-2" />
      <CRow>
        <CCol className="mb-2" sm={12} md={3}>
          <div>{t("employee.date_of_resignation")}</div>
        </CCol>
        <CCol className="mb-2" sm={12} md={9}>
          <CRow>
            <CCol className="d-flex py-2">
              <CFormCheck
                id="resign_this_employee"
                type="checkbox"
                name="resign_this_employee"
                disabled={resigned}
                checked={data.resign_this_employee}
                onChange={(e) =>
                  handleDataChange(e.target.name, e.target.checked)
                }
              />{" "}
              &nbsp;&nbsp;
              <CFormLabel
                className="mb-0"
                style={{ fontWeight: 600 }}
                htmlFor="resign_this_employee"
              >
                {t("employee.resign_this_employee")}
              </CFormLabel>

            </CCol>
          </CRow>
          <CRow>
            <CCol className="d-flex py-2">
              <InputDragFile
                file={data.resign_file}
                handleDataChange={handleDataChange}
              />
            </CCol>
            {erros?.resign_file && (
              <div className="text-danger">{erros.resign_file}</div>
            )}
          </CRow>
          <CRow>
            <CCol className="mb-2" sm={12} md={4} xl={4}>
              <CFormLabel
                className="mb-1 pt-2"
                style={{ fontWeight: 600 }}
                htmlFor="requested_release_date"
              >
                {t("employee.requested_release_date")}
              </CFormLabel>

              <DatePicker
                dateFormat={"dd/MM/yy"}
                id="release_request_date"
                name="release_request_date"
                className="form-control"
                selected={data.release_request_date}
                onChange={(date: Date) => {
                  handleDataChange("release_request_date", date);
                }}
                placeholderText={"dd/MM/yy"}
              />
            </CCol>
            {erros?.release_request_date && (
              <div className="text-danger">{erros.release_request_date}</div>
            )}
          </CRow>
        </CCol>
      </CRow>
      <hr className="mb-5" />
      <CButton onClick={handleSave}>{t("common.save")}</CButton>
      <Link
        to={`/employee_management/employees`}
        className="btn btn-secondary ms-2"
        state={{ beforeTab: state?.beforeTab }}
      >
        {t("common.cancel")}
      </Link>
      {erros?.error_message && <div className="text-danger">{erros.error_message}</div>}

      <div style={{ height: 200 }}></div>

    </CContainer>
  );
};

export default EditEmployeeScreen;
