import { CForm, CFormInput } from '@coreui/react';
import { StringMap } from 'i18next';
import React from 'react';


interface TextInput {
    name?: string | undefined,
    className?: string,
    type?: string,
    placeholder?: string
    style?: any;
    iconRight?: string | undefined,
    iconLeft?: string,
    acctionRight?: () => void;
    actionChangeText?:any,
    value?:string|undefined;
    validation?: string;
}

const TextInput = (props: TextInput) => {
    const { type, placeholder, acctionRight, iconRight, iconLeft,actionChangeText,value, name,validation } = props;
    return (
        <>
            <div className="d-flex align-items-center my-2 w-100" style={{ border:validation? '1px solid red':'1px solid #AEBBC1', borderRadius: 8, height: 44, padding: '0 16.67px 0 10px' }}>
                {iconLeft ? (<img className="img-fluid" style={{ width: 15, height: 16.67 }} src={iconLeft} alt="" />) : (null)}
                <CFormInput value={value} onChange={(e)=> actionChangeText(e.target.name, e.target.value)} name={name} className="border-0" type={type} placeholder={placeholder} 
                    style={{boxShadow:"0 0 0px 1000px white inset", width: 0, flex: 1}} />
                {iconRight ? (<img onClick={acctionRight} className="img-fluid" style={{ width: 14.67, height: 12 }} src={iconRight} />) : (null)}
            </div>
            <span  style={{color:"red"}}>{validation}</span>
        </>
    );
};

export default TextInput;