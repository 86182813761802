import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import ProtectedLayout from "./layout/ProtectedLayout";
import ScreenRegister from "./screens/Register/ScreenRegister";
import VerificationScreen from "./screens/VerificationScreen/VerificationScreen";
import BusinessProfileScreen from "./screens/Register/BusinessProfileScreen";
import BusinessLocationList from "./screens/Register/ListBusinessLocationScreen";
import SignInScreen from "./screens/SignIn/SignInScreen";
import ChangePassScreen from "./screens/SignIn/ChangePassScreen";
import CustomerScreen from "./screens/Customers/CustomersScreen";
import ForgotPassword from "./screens/SignIn/ForgotPassWord";
import AddCustomerScreen from "./screens/Customers/AddCustomerScreen";
import CustomerDetail from "./screens/Customers/CustomerDetailScreen";
import EmployeeScreen from "./screens/ManageEmployees/Employees/EmployeesScreen/EmployeeScreen";
import AddEmployeeScreen from "./screens/ManageEmployees/Employees/EmployeesScreen/AddEmployeeScreen";
import EditCustomer from "./screens/Customers/EditCustomerScreen";
import ProfileList from "./screens/SignIn/ProfileList/ProfileSelectScreen";
import EditEmployeeScreen from "./screens/ManageEmployees/Employees/EmployeesScreen/EditEmployeeScreen";
import DetailEmployeeScreen from "./screens/ManageEmployees/Employees/EmployeesScreen/DetailEmployeeScreen";
import PaymentConsolidationScreen from "./screens/Book/Payment/PaymentConsolidationScreen";
import ResignedDetailSreen from "screens/ManageEmployees/Employees/EmployeesScreen/ResignedDetailSreen";
import socket from "utils/socket";
import SalesOrdersScreen from "screens/SalesOrders/SalesOrdersScreen";
import AddOrdersScreen from "screens/SalesOrders/AddOrdersScreen";
import OnBoardingProcessScreen from "screens/OnBoardingProcess/OnBoardingProcessScreen";
import ScreenLoginQR from "screens/SignIn/ScreenLoginQR";

const App = () => {
  React.useEffect(() => {
    socket.connect();
    socket.on('connect', () => {
      console.log('connected to sokect server');
    });
    socket.on('connect_error', (err: any) => {
      console.log(`${err}`);
    });
    return () => {
      socket.off(), 'connect';
    };
  }, []);

  return (
    <Routes>
      <Route element={<TokenCheckRoute />}>

        <Route path="/" element={<ProtectedLayout />}>
          <Route path="employee_management/employees">
            <Route index element={<EmployeeScreen />} />
            <Route path="add-new" element={<AddEmployeeScreen />} />
            <Route path="edit/:id" element={<EditEmployeeScreen />} />
            <Route path="detail/:id" element={<DetailEmployeeScreen />} />
            <Route path="resigned-detail/:id" element={<ResignedDetailSreen />} />
          </Route>
          <Route path="cashbook/payment">
            <Route index element={<PaymentConsolidationScreen />} />
          </Route>
          <Route path="customers">
            <Route index element={<CustomerScreen />} />
            <Route path="add-customer" element={<AddCustomerScreen />} />
            <Route path="detail-customer" element={<CustomerDetail />} />
            <Route path="update-customer" element={<EditCustomer />} />
          </Route>
          <Route path="sale_orders">
            <Route index element={<SalesOrdersScreen />} />
            <Route path="add-order" element={<AddOrdersScreen />} />
          </Route>
          <Route path="onboarding" element={<OnBoardingProcessScreen />} />
        </Route>
        <Route path="profile-list" element={<ProfileList />} />
      </Route>

      <Route element={<PublicRoute />} >
        <Route path="register">
          <Route index element={<ScreenRegister />} />
          
          <Route path="verification" element={<VerificationScreen />} />

          <Route element={<TokenCheckRoute />}>
            <Route
              path="register-business-profile"
              element={<BusinessProfileScreen />}
            />
          </Route>
          <Route element={<CheckVendorIdRoute />}>
            <Route
              path="register-business-list"
              element={<BusinessLocationList />}
            />
          </Route>
        </Route>
        <Route path="login-qr" element={<ScreenLoginQR />} />
        <Route path="sign-in">
          <Route index element={<SignInScreen />} />
          <Route path="verification" element={<VerificationScreen />} />
          <Route path="change-password" element={<ChangePassScreen />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;

const checkAuth = () => {
  const result = !!localStorage.getItem('user_id') &&
    !!localStorage.getItem('token') &&
    !!localStorage.getItem('mobile_phone') &&
    !!localStorage.getItem('vendor_id') &&
    !!localStorage.getItem('vendor_location_id') &&
    !!localStorage.getItem('vendor_location_name');
  return result;
}

const PublicRoute = () => {
  if (checkAuth()) {
    return <Navigate to={'/'} />
  }
  return <Outlet />
}

const TokenCheckRoute = () => {
  if (localStorage.getItem('token')) {
    return <Outlet />;
  }
  return <Navigate to={'/login-qr'} />
};

const CheckVendorIdRoute = () => {
  if (localStorage.getItem('vendor_id')) {
    return <Outlet />;
  }
  return <Navigate to={'/register/register-business-profile'} />
}