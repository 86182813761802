import http from "utils/http";

class SaleOrdersService {
  getListOrders(keyword: string, vendor_id: string, page: string) {
    return http.get(`portal/sale-order/list?vendor_id=${vendor_id}&page=${page}&keyword=${keyword}`);
  }

  getListEmployeeOrders(vendor_id: string) {
    return http.get(`portal/sale-order/list-employee?vendor_id=${vendor_id}`);
  }
  getListCustomerOrders(vendor_id: string, keyword: string) {
    return http.get(`portal/sale-order/list-customer?vendor_id=${vendor_id}&keyword=${keyword}`);
  }
  updateAssignEmployee(data: { order_ids: string[]; employee_id: number }) {
    return http.post(`portal/sale-order/assign-employee`, data);
  }
  downloadSampleFile(headings: string, vendor_id: number) {
    return http.get(`/portal/sale-order/upload-sale-order-list-sample?headings=${headings}&vendor_id=${vendor_id}`);
  }
  addOrders(data: any) {
    return http.post(`/portal/sale-order/create-sale-order`, data);
  }
  uploadFileOrders(data: any) {
    return http.post(`/portal/sale-order/upload-sale-order-list`, data);
  }
  downloadFileErr(data: any) {
    return http.post(`/portal/sale-order/download-sale-order-list-error`, data);
  }
}
export default new SaleOrdersService();
